import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { SEO } from '@templates'
import useWindowWidth from '@hooks/useWindowWidth'
import { getSeoProps } from '@data/seo'
import {
  prismicGatsbyImageSharp,
  prismicBlogArticleFields,
  prismicCaseStudyCardNoTitle,
  partnerCta,
} from '@graphql/fragments'
import PartnerUI from './PartnerUI'

export const query = graphql`
  query PrismicPartnerPageByDocumentID($uid: String!) {
    prismic {
      partner_page(uid: $uid, lang: "en-gb") {
        text: intro_text
        title
        landscape_image
        portrait_image
        seodescription
        seotitle
        largeDeviceImage: landscape_imageSharp {
          childImageSharp {
            ...prismicGatsbyImageSharp
          }
        }
        smallDeviceImage: portrait_imageSharp {
          childImageSharp {
            ...prismicGatsbyImageSharp
          }
        }
        body {
          ... on PRISMIC_Partner_pageBodyImage_quotes {
            type
            primary {
              title
            }
            fields {
              author {
                ... on PRISMIC_Person {
                  name
                  job_title
                }
              }
              text
              image
              imageSharp {
                childImageSharp {
                  fixed(width: 1200, quality: 100) {
                    ...GatsbyImageSharpFixed_withWebp_noBase64
                  }
                }
              }
            }
          }
          ... on PRISMIC_Partner_pageBodyText {
            type
            primary {
              text
            }
          }
          ... on PRISMIC_Partner_pageBodyFull_width_image {
            type
            primary {
              caption
              full_width_image
              full_width_imageSharp {
                childImageSharp {
                  ...prismicGatsbyImageSharp
                }
              }
            }
          }
          ... on PRISMIC_Partner_pageBodyFigure_block {
            type
            fields {
              figure
              label
            }
          }
          ...partnerCta
          ... on PRISMIC_Partner_pageBodyArticle_slider_block {
            type
            label
            primary {
              title_of_section
              article_1 {
                ...prismicBlogArticleFields
              }
              article_2 {
                ...prismicBlogArticleFields
              }
              article_3 {
                ...prismicBlogArticleFields
              }
              article_4 {
                ...prismicBlogArticleFields
              }
              article_5 {
                ...prismicBlogArticleFields
              }
            }
          }
        }
        relatedCaseStudies: related_case_studies {
          case_study {
            ... on PRISMIC_Case_study {
              ...prismicCaseStudyCardNoTitle
            }
          }
        }
      }
    }
  }
`

const CaseStudy = ({ data }) => {
  const nestedData = get(data, 'prismic.partner_page')

  if (!nestedData) {
    return null
  }

  const seoProps = getSeoProps(nestedData)

  const { largeDeviceImage, smallDeviceImage, relatedCaseStudies, title } = nestedData

  const windowWidth = useWindowWidth()

  const sharpImage = windowWidth >= 767 ? largeDeviceImage : smallDeviceImage
  const singlePortraitImage = get(data, 'prismic.partner_page.portrait_image')

  const titleString = get(title, '[0].text')

  const caseStudyList = relatedCaseStudies && relatedCaseStudies;

  return (
    <Fragment>
      <SEO {...seoProps} />
      <PartnerUI
        {...nestedData}
        sharpImage={sharpImage}
        title={titleString}
        image={singlePortraitImage}
        caseStudyList={caseStudyList}
      />
    </Fragment>
  )
}
CaseStudy.fragments = [partnerCta, prismicBlogArticleFields, prismicCaseStudyCardNoTitle]

export default CaseStudy

CaseStudy.query = query
