import React from 'react'
import css from './PolicyText.module.css'

const PolicyText = ({ ariaLabel, children }) => (
  <article className={css.policy} role="contentinfo" aria-label={ariaLabel}>
    {children}
  </article>
)

export default PolicyText
