import React from 'react'
import { OneBlogArticleBlock } from '@components'
import CardListContainer from '../CardListContainer/CardListContainer'

export default ({ blogArticleList, title, className }) => {

  if (!blogArticleList || blogArticleList.length === 0) {
    return null
  }

  return (
    <CardListContainer title={title} className={`${className}`}>
      {blogArticleList.map((blogArticle, index) => {
        return <OneBlogArticleBlock key={`blogArticle${index}`} {...blogArticle.blog} delay={index * 0.3} groupSize={blogArticleList.length} />
      })}
    </CardListContainer>
  )
}
