import React from 'react'
import Card from '../Card/Card'

export default props => {

  const url = props.link ? props.link.uid : null;

  const { summary_text } = props;

  const text = summary_text && summary_text.length > 0 ? [summary_text[0]] : [];

  return (
    <Card
      {...props}
      url={`/blog/articles/${url}`}
      images={props}
      text={text}
    />
  )
}
