import React from 'react'
import ReactHashtag from 'react-hashtag'
import css from './Caption.module.css'

const Caption = ({ icon, text }) => {
  const iconClass = icon ? css[`icon${icon}`] : ''

  if (!text) {
    return null
  }

  return (
    <div className={`${css.caption} ${iconClass}`}>
      <ReactHashtag
        renderHashtag={hashtagValue => (
          <a
            key={hashtagValue}
            className={css.hashtag}
            target="_blank"
            rel="noopener noreferrer"
            href={`https://www.instagram.com/explore/tags/${hashtagValue.replace('#', '')}`}
          >
            {hashtagValue}
          </a>
        )}
      >
        {text}
      </ReactHashtag>
    </div>
  )
}

export const InstagramCaption = ({ text }) => <Caption icon="Instagram" text={text} />

export const GreatStateCaption = ({ text }) => <Caption icon="Great State" text={text} />
