/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react'
import { Link } from 'gatsby'
import { PolicyText, IntroCopy, Underline } from '@components'
import { SEO } from '@templates'

const CookiesPolicy = () => (
  <>
    <SEO
      title="Cookies Policy"
      description="Take a look at our agency’s award-wining digital marketing case studies."
    />
    <PolicyText ariaLabel="Great State Cookie Policy">
      <IntroCopy>
        <header>
          <h1>Cookies Policy</h1>
        </header>

        <section>
          <h2>1 Overview</h2>
          <p>
            <strong>
              Our website uses cookies to distinguish you from other users of our website{' '}
              <a href="https://www.greatstate.co">
                <Underline text="https://www.greatstate.co" />
              </a>{' '}
              (“Site”). This helps us to make your experience on this website easy, useful and
              reliable. We gather information about site usage to assist in the improvement and
              development of our services. By continuing to browse the site, you are agreeing to our
              use of cookies.
            </strong>
          </p>
          <p>
            <strong>
              To do this we sometimes place small amounts of information on your computer, mobile
              phone or device. These small files are known as ‘cookies’ and are used to collect
              information about how you browse this website. They are widely used in order to make
              websites work, or work more efficiently, as well as to provide information to the
              owners of the site.
            </strong>
          </p>

          <h3>
            <span>1.1</span> What are cookies?
          </h3>
          <p>
            Cookies are small files of letters and numbers that we store on your browser or the hard
            drive of your computer if you agree. Cookies contain information that is transferred to
            your computer's hard drive.
          </p>
          <p>
            Some cookies (referred to as session cookies) are automatically deleted after you finish
            using a website, but other cookies (referred to as persistent cookies) can be stored on
            your computer for different lengths of time depending on what function they perform.
            Session cookies are temporary and are deleted when you leave a website or close your web
            browser, whereas persistent cookies last for a fixed period of time, defined within the
            cookie, and allow the website to recognise your device and track repeat visits.
          </p>
          <p>
            We have placed cookies on your device to help make this website better, and to protect
            our systems from malicious users. Some of the cookies we use are essential for the site
            to work and others are non-essential cookies to collect information for making reports
            and to help us improve the site. The cookies collect information in an anonymous form.
          </p>
        </section>

        <section>
          <h2>2 How to control cookies on this Site</h2>
          <p>
            You can block cookies by changing the settings on your browser. This allows you to block
            all or some cookies or receive a warning before a cookie is stored.
          </p>
          <p>
            However, if you use your browser settings to block all cookies (including essential
            cookies) you may not be able to access all or parts of our Site.
          </p>
          <p>
            Most web browsers allow some control of most cookies through the browser settings. To
            find out more about cookies, including how to see what cookies have been set and how to
            manage and delete them, visit{' '}
            <a href="www.aboutcookies.org">
              <Underline text="www.aboutcookies.org" />
            </a>{' '}
            or{' '}
            <a href="www.allaboutcookies.org">
              <Underline text="www.allaboutcookies.org" />
            </a>
            .
          </p>
          <p>
            To opt out of being tracked by Google Analytics across all websites you can visit{' '}
            <a href="http://tools.google.com/dlpage/gaoptout">
              <Underline text="http://tools.google.com/dlpage/gaoptout" />
            </a>
            .
          </p>
          <p>
            We do not attempt to monitor the browsing behaviour of individual users through any
            hidden means.
          </p>
        </section>

        <section>
          <h2>3 How does the Great State Site use cookies?</h2>
          <p>
            We use cookies to improve your experience of our website. Here’s what they help us do:
          </p>
          <ul>
            <li>
              Recognise your device so you don't have to repeatedly provide us with the same
              information, for example filling out forms across the site
            </li>
            <li>
              Measure how people are using different parts of the website, so we can update and make
              improvements based on your needs
            </li>
            <li>
              Measure website performance so we can show value for money in the delivery of
              information and services
            </li>
            <li>
              Analyse anonymised data so that we can better understand how people interact with
              government services, and make improvements
            </li>
            <li>Support users’ sharing of information on the website via social media</li>
            <li>Measure the effectiveness of advertising campaigns that we have endorsed</li>
            <li>
              Tracking your use of this website so that we can tailor advertising to be more
              relevant to you on social media and other websites (using third-party networks){' '}
            </li>
            <li>
              Statistical information collected using Google Analytics - No information obtained can
              be used to identify individual users. You can find more details about how this affects
              your privacy at the Google Analytics Privacy Centre. To stop sending information to
              Google Analytics, an Opt-out Browser Add-on is available. Also you can configure your
              browser to enable you to choose which, if any, cookies are allowed to be created.
            </li>
            <li>
              Information provided by users through online forms is held in our UK based secure data
              centres and is retained in line with the General Data Protection Regulation and our
              Privacy Policy, which is available{' '}
              <Link to="/privacy">
                <Underline text="here" />
              </Link>
              .
            </li>
            <li>
              When using our online forms we record your IP address and also details of which
              browser you are using. We may use this information in conjunction with any information
              entered in the form{' '}
            </li>
          </ul>
        </section>

        <section>
          <h2>4 What do the cookies on this Site do?</h2>
          <p>The cookies used on this website can be grouped into the following categories:</p>
          <ul>
            <li>
              <strong>Strictly necessary cookies</strong> – These are cookies that are required for
              the operation of our website. They include, for example, cookies that enable you to
              log into secure areas of a website, use a shopping cart or make use of e-billing
              services.
            </li>
            <li>
              <strong>Analytical/Performance cookies</strong> – These allow us to recognise and
              count the number of visitors to our Site and to see how visitors move around our Site
              when they are using it. This helps us to improve the way our website works, for
              example, by ensuring that users are finding what they are looking for easily. This
              helps improve performance and creates a better user experience.
            </li>
            <li>
              <strong>Functionality cookies</strong> – These are used to recognise you when you
              return to our website. This enables us to personalise our content for you, greet you
              by name and remember your preferences (for example, your choice of language or
              region).
            </li>
            <li>
              <strong>Targeting cookies</strong> – These cookies record your visit to our website,
              the pages you have visited and the links you have followed. We will use this
              information to make our website and the advertising displayed on it more relevant to
              your interests. We may also share this information with third parties for this
              purpose. These enable us to enhance our website with services provided by
              third-parties.
            </li>
          </ul>
          <p>
            Some cookies are set directly by Great State. Others are set by third-parties when you
            visit this website. Third-party cookies include those set by advertising networks, the
            partners we use for web analytics (e.g. Google Analytics), and external websites
            delivering embedded content and videos (e.g. YouTube).
          </p>
          <p>
            The cookies used by this website contain unique identifiers, however this is information
            is anonymised and cannot be used to identify you personally or find out who you are.
            Great State does not sell any information collected by cookies.
          </p>

          <h3>
            <span>4.1</span> Strictly necessary cookies
          </h3>
          <p>The Great State website does not use strictly necessary cookies.</p>

          <hgroup>
            <h3>
              <span>4.2</span> Analytical / Performance cookies
            </h3>
            <h4>
              <span>4.2.1</span> Google Analytics, Google Tag Manager
            </h4>
          </hgroup>
          <p>
            These cookies help us accurately estimate the number of visitors to the website and
            provide us with a better understanding of how our customers use our website.
          </p>
          <p>Google Analytics sets the following cookies:</p>
          <table>
            <thead>
              <tr>
                <th>Cookie name</th>
                <th>Expiration time</th>
                <th>Purpose</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>_ga</td>
                <td>2 years</td>
                <td>
                  This cookie is used to distinguish users, which helps us to count how many people
                  visit our website
                </td>
              </tr>
              <tr>
                <td>_gat</td>
                <td>10 minutes</td>
                <td>Used to manage the rate at which page view requests are made</td>
              </tr>
              <tr>
                <td>_gid</td>
                <td>When you close your browser</td>
                <td>This helps us count how many people visit our website</td>
              </tr>
              <tr>
                <td>_gac_UA_27818596-1</td>
                <td>90 days</td>
                <td>Contains campaign related information for the user</td>
              </tr>
            </tbody>
          </table>

          <h4>
            <span>4.2.2</span> Hotjar
          </h4>
          <p>
            Hotjar is used to record and display user performance of our Site. We collect standard
            internet log information and details of visitor behaviour patterns. We do this to find
            out things such as the number of visitors to the various parts of the site. We collect
            this information in a way which does not personally identify anyone.
          </p>
          <table>
            <thead>
              <tr>
                <th>Cookie name</th>
                <th>Expiration time</th>
                <th>Purpose</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>_hjincludedInSample</td>
                <td>2 years</td>
                <td>
                  This cookie is used to distinguish users, which helps us to count how many people
                  visit our website
                </td>
              </tr>
              <tr>
                <td>_gat</td>
                <td>365 days</td>
                <td>
                  This session cookie is set to let Hotjar know whether that visitor is included in
                  the sample which is used to generate funnels (which show the proportion of users
                  that progress through a sequence of webpages.
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            On occasion we may implement surveys / polls and other services provided by Hotjar on
            this website, for full details of cookies included please see the following link:{' '}
            <a href="https://www.hotjar.com/legal/policies/cookie-information">
              <Underline text="https://www.hotjar.com/legal/policies/cookie-information" />
            </a>
            .
          </p>

          <h3>
            <span>4.3</span> Functionality Cookies
          </h3>
          <p>
            Functionality cookies are used to allow our site to work properly. They may be used to
            remember choices you make on forms and allow us to provide enhanced and more personal
            features.
          </p>
          <table>
            <thead>
              <tr>
                <th>Cookie name</th>
                <th>Expiration time</th>
                <th>Purpose</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>_hjincludedInSample</td>
                <td>2 years</td>
                <td>
                  This cookie is used to distinguish users, which helps us to count how many people
                  visit our website
                </td>
              </tr>
              <tr>
                <td>gaShowSiteOverlay</td>
                <td />
                <td>
                  This session cookie is added if the user has come from e3.co.uk or lsu.is (it
                  controls the ‘we have merged’ overlay).
                </td>
              </tr>
            </tbody>
          </table>

          <hgroup>
            <h3>
              <span>4.4</span> Marketing Cookies
            </h3>
            <h4>
              <span>4.4.1</span> Marketing and Advertising Cookies
            </h4>
          </hgroup>
          <p>
          These cookies are used to deliver adverts that are more relevant to you and your interests. They are also used to limit the number of times you see an advertisement as well as help measure the effectiveness of an advertising campaign. They are only placed on our site with our permission. They remember that you have visited our site and this information is shared with other organisations such as advertisers. These cookies are often linked to website functionality provided by the advertisers.
          </p>

          <p>
            Our website uses Google Adwords conversion tracking and Google Signals, which are services provided by Google. Google Adwords conversion tracking use

            Google Ads conversion tracking use cookies and similar technologies in order to measure the performance of advertisements placed (so-called Ads campaigns) and to show you individualised advertising messages on websites that collaborate with Google. Google Signals compiles for us multi-platform data reports on Google users that have enabled personalised advertising in their Google accounts.

            If you have enabled personalised advertising in your Google account, Google will be able to prepare data models and reports on website habits, which show for instance on which device you first clicked on an advertisement and on which device any eventual purchase took place. These data models and reports are based on random samples and are pseudonymised to ensure anonymity, which means that we are unable to draw any conclusions as to the identities of the individual Google users.
          </p>
          <p>For more information in this regard, refer to the Google <a href="https://support.google.com/analytics/answer/6004245?hl=en">Privacy Policy</a></p>

          <table>
            <thead>
              <tr>
                <th>Cookie name</th>
                <th>Expiration time</th>
                <th>Purpose</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  _DSID
                  <br />
                </td>
                <td>14 days</td>
                <td>
                  1 year These cookies are written by DoubleClick / Google for the purpose of
                  showing advertisements based on what’s relevant to a user.
                </td>
              </tr>
              <tr>
                <td>lidc</td>
                <td>1 day</td>
                <td>.Linkedin.com cookie used for routing</td>
              </tr>
              <tr>
                <td>bcookie</td>
                <td>1 year</td>
                <td>.Linkedin.com cookie browser ID cookie</td>
              </tr>
              <tr>
                <td>bscookie</td>
                <td>1 year</td>
                <td>www.Linkedin.com secure browser ID cookie</td>
              </tr>
              <tr>
                <td>L1c</td>
                <td>Current Session</td>
                <td>www.Linkedin.com browser ID cookie</td>
              </tr>
              <tr>
                <td>BizoID</td>
                <td>6 months</td>
                <td>ads.linkedin.com LinkedIn Ad analytics</td>
              </tr>
              <tr>
                <td>BizoData</td>
                <td>6 months</td>
                <td>ads.linkedin.com LinkedIn Ad analytics</td>
              </tr>
              <tr>
                <td>BizoUserMatchHistory</td>
                <td>6 months</td>
                <td>ads.linkedin.com LinkedIn Ad analytics</td>
              </tr>
              <tr>
                <td>BizoNetworkPartnerIndex</td>
                <td>6 months</td>
                <td>ads.linkedin.com LinkedIn Ad analytics</td>
              </tr>
              <tr>
                <td>
                  OptanonConsent <br />
                  OptanonAlertBoxClosed
                </td>
                <td />
                <td>
                  OneTrust Cookie Consent These cookies are set by the OneTrust Cookie Consent &
                  Website Scanning solution.
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            Linkedin cookies are subject to change, please see the most recent list here:{' '}
            <a href="https://www.linkedin.com/legal/cookie-table">
              <Underline text="https://www.linkedin.com/legal/cookie-table" />
            </a>
            .
          </p>

          <h3>
            <span>4.5</span> Session Cookies
          </h3>
          <p>
            Our website makes use of session cookies to enable us to identify requests from the same
            browser during a limited time window, and provides a way to persist variable values for
            the duration of that session. Session cookies are essential for the website to operate
            and are set upon your arrival to the site. These cookies are deleted when you close your
            browser.
          </p>

          <h3>
            <span>4.6</span> Third Party Cookies
          </h3>
          <p>
            If you go onto a website that contains embedded content such as banner advertising or
            you click a link through to a third-party website from our Site, then you may be sent
            cookies from these websites. We don’t control the setting of these cookies and are not
            responsible for them. You should check the third-party website for information about how
            their cookies work and how to manage them.
          </p>

        </section>

        <section>
          <h2>5 Data protection and privacy</h2>
          <p>
            We take your privacy seriously. For more information about how and why we collect and
            use personal data, please visit our{' '}
            <Link to="/privacy">
              <Underline text="privacy page" />
            </Link>
            .
          </p>
        </section>
      </IntroCopy>
    </PolicyText>
  </>
)
export default CookiesPolicy
