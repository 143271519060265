import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { fileToBase64, isFileExtensionSupported, isFileTooLarge, getErrorMessage } from '@utils/helpers'
import Input from './Input'
import CheckboxList from './CheckboxList'
import { Underline } from '@components'
import css from './Form.module.css'

const FormBuilder = (props) => {
  const { id, fields } = props
  let resume = {}
  let coverLetter = {}

  if (!fields.length) return null

  const acceptedFileTypes = ['doc', 'docx', 'pdf', 'rtf', 'txt']

  const [formStatus, updateFormStatus] = useState('init')

  const { register, handleSubmit, errors, formState, reset } = useForm()

  const onSubmit = async data => {

    const fileFields = fields.filter(field => field.type === 'input_file')

    const fileChecks = fileFields.map(field => {
      const fileData = data[field.name]

      // empty unrequired file
      if(!fileData.length && !field.required){
        return true
      }

      // empty required file
      if(!fileData.length && field.required){
        updateFormStatus(`no-${field.name}`)
        return false
      }

      if(fileData.length){
        if (!isFileExtensionSupported(acceptedFileTypes, fileData[0].name)) {
          updateFormStatus('invalid-format')
          return false
        }

        if (isFileTooLarge(fileData)){
          updateFormStatus('file-too-large')
          return false
        }
      }

      // All checks passed
      return true
    })
    if (!fileChecks.every(Boolean)) return false

    resume = {
      resume_content_filename: data.resume[0].name,
      resume_content: await fileToBase64(data.resume[0])
    }

    if (data.cover_letter.length) {
      coverLetter = {
        cover_letter_content_filename: data.cover_letter[0].name,
        cover_letter_content: await fileToBase64(data.cover_letter[0])
      }
    }

    const url = `${process.env.GATSBY_API_URL}${id}`
    const formDataObj = { ...data, ...resume, ...coverLetter, url }

    fetch(`/.netlify/functions/greenhouse-job-api-proxy`, {
      method: 'POST',
      body: JSON.stringify({
        formData: JSON.stringify(formDataObj),
        url,
      }),
    })
      .then(function(response) {
        if (response.status === 200) {
          updateFormStatus('submission-success')
          reset()
        }
      })
      .catch(function(error) {
        console.error(error)
        updateFormStatus('submission-error')
      })

      return true
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={css.gsForm}>
      <div>
        {fields.map(field => {
        return (
          <FormField
            error={errors[field.name]}
            key={field.name}
            formRef={register}
            {...field}
            {...{acceptedFileTypes}}
          />
        )
        })}
        
        {getErrorMessage(formStatus) && (
          <div className={css.formError__container} data-code={formStatus}>
            <h4>{getErrorMessage(formStatus).heading}</h4>
            <p>{getErrorMessage(formStatus).body}</p>
          </div>
        )}

        <div className={css.gsFormApplyBtnWrap}>
          <button className={css.gsFormApplyBtn} type="submit">
            <Underline color="transparent-to-pink" text="Apply now" />
          </button>
        </div>
      </div>
    </form>
  )
}

export default FormBuilder

const FormField = props => {
  const { type, required, formRef, error, name } = props

  const fieldRef = formRef({ required })

  switch (type) {
    case 'input_text': {
      return <Input {...props} formRef={fieldRef} type="text" />
    }
    case 'input_file': {
      return <Input {...props} formRef={fieldRef} type="file" />
    }
    case 'multi_value_multi_select': {
      return <CheckboxList {...props} formRef={fieldRef} />
    }
    default:
      return null
  }
}
