import React from 'react'
import FormBuilder from './FormBuilder'

export default ({ greenhouseJobId, fields }) => {
  if (!fields) {
    return null
  }

  return <FormBuilder id={greenhouseJobId} {...{ fields }} />
}
