import React from 'react'
import { RichText } from 'prismic-reactjs'
import linkResolver from '@utils/linkResolver'
import css from './FormSuccess.module.css'

const FormSuccess = ({ text, handleReset }) => {
  return (
    <div>
      <RichText render={text} linkResolver={linkResolver} />
      <button type="button" className={css.mailingListSignup__reset} onClick={handleReset}>
        Signup with another email
      </button>
    </div>
  )
}

export default FormSuccess
