import React, { useState, useEffect, useRef } from 'react'
import Verge from 'verge'
import css from './ContentSection.module.css'

const ContentSection = ({ children, noMarginTop = false, noMarginBottom = false }) => {
  const sectionRef = useRef(null)
  const visible = useVisible(false, sectionRef)
  return (
    <section
      className={`${css.section} ${noMarginTop ? css.noMarginTop : ''} ${
        noMarginBottom ? css.noMarginBottom : ''
      }`}
      data-visible={visible}
      ref={sectionRef}
    >
      {children}
    </section>
  )
}

export default ContentSection

function useVisible(initialValue, sectionRef) {
  const [visible, setVisible] = useState(initialValue)

  useEffect(() => {
    const handleScrollAndResize = () =>
      requestAnimationFrame(() => showHideSection(sectionRef, setVisible))
    showHideSection(sectionRef, visible, setVisible)
    window.addEventListener('scroll', handleScrollAndResize, { passive: true })
    window.addEventListener('resize', handleScrollAndResize, { passive: true })
    return () => {
      window.removeEventListener('scroll', handleScrollAndResize)
      window.removeEventListener('resize', handleScrollAndResize)
    }
  }, [visible])
  return visible
}

function showHideSection(sectionRef, visible, setVisible) {
  // !visible check ensures animation only occurs once
  // i.e. stops the section from fading in and out, as the user scrolls up and down
  if (!visible) setVisible(Verge.inViewport(sectionRef.current, -150))
}
