import React from 'react'
import get from 'lodash/get'
import { Card } from '@components'

export default props => {
  const { link, textHeight, intro_text: introText } = props
  const url = link ? link.uid : get(props, '_meta.uid')
  const defaultTextHeight = '18rem'

  return (
    <Card
      {...props}
      url={`/work/casestudy/${url}`}
      images={props}
      text={introText}
      articleType="work"
      textHeight={textHeight || defaultTextHeight}
    />
  )
}
