import React, { Fragment } from 'react'
import { globalHistory } from "@reach/router"
import SocialSharelink from './SocialShareLink'

export default () => {
  const platformList = ['twitter', 'facebook', 'linkedin']

  return (
    <Fragment>
      {platformList.map((platform, index) => (
        <SocialSharelink key={`socialSharelink${index}`} platform={platform} url={globalHistory.location.href}/>
      ))}
    </Fragment>
  )
}
