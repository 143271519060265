import React from 'react'
import Headroom from 'react-headroom'
import { ScreenReaderText, Container, UpTransitionLink, RightTransitionLink } from '@components'
import css from './Header.module.css'
import classnames from 'classnames'

const Header = ({ navOpen, setNavOpen, siteTitle, location }) => {
  const activePage = location.pathname.slice(1) === '' ? 'home' : location.pathname.split('/')[1]
  const isActive = page => (activePage === page.split('/')[0] ? 'page' : '')
  const menuClick = e => {
    if (e.target.getAttribute('aria-current') !== 'page') {
      window.setTimeout(() => {
        setNavOpen(false)
      }, 700)
    } else {
      setNavOpen(false)
    }
  }

  const menuItems = [
    {
      to: '/expertise/higher-education',
      text: 'Expertise',
      title: 'Expertise',
    },
    {
      to: '/services',
      text: 'Services',
      title: 'Services',
    },
    {
      to: '/work',
      text: 'Work',
      title: 'Read Great State case studies',
    },
    {
      to: '/blog',
      text: 'Blog',
      title: 'Latest news and opinion articles',
    },
    {
      to: '/careers',
      text: 'Careers',
      title: 'Browse job vacancies at Great State',
    },
    {
      to: '/contact',
      text: 'Contact',
      title: 'Contact Great State',
    },
  ]
  return (
    <div className={navOpen ? 'is-nav-open' : ''}>
      <Headroom disableInlineStyles>
        <header className={css.header}>
          <Container>
            <div className={css.content}>
              <UpTransitionLink
                onClick={menuClick}
                to="/"
                className={classnames(css.logo, {[css.logoNavOpen]: navOpen})}
              >
                {siteTitle}
              </UpTransitionLink>

              <nav className={css.nav}>
                <ScreenReaderText tag="h2" content="Main Menu" />
                <ul
                  role="menu"
                  id="mainmenu"
                  className={css.menuList}
                  aria-labelledby="mainmenumobbutton"
                  data-status={!navOpen}
                >
                  {menuItems.map((item, i) => 
                    <li className={css.menuItem} key={i}>
                      <RightTransitionLink
                        to={item.to}
                        role="menuitem"
                        title={item.text}
                        className={css.link}
                        aria-current={isActive(`${item.to.replace('/', '')}`)}
                        onClick={menuClick}
                      >
                        {item.text}
                      </RightTransitionLink>
                    </li>
                  )}
                </ul>
                <button
                  type="button"
                  id="mainmenumobbutton"
                  aria-haspopup="true"
                  aria-controls="mainmenu"
                  className={`${css.menuBtn} ${navOpen ? css.menuBtnOpen : ''}`}
                  onClick={() => setNavOpen(!navOpen)}
                >
                  Menu
                </button>
              </nav>
            </div>
          </Container>
        </header>
      </Headroom>
    </div>
  )
}

export default Header
