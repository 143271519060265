import React from 'react'
import get from 'lodash/get'
import { Image, GreatStateCaption, FullWidthContent } from '@components'
import css from './FullWidthImage.module.css'

export default ({ caption, full_width_image, full_width_imageSharp, hasMargin }) => {
  const sharpImage = get(full_width_imageSharp, 'childImageSharp.fluid')

  const marginClass = hasMargin ? css.hasMargin : ''

  return (
    <div className={marginClass}>
      <FullWidthContent>
        <Image className={css.fullWidthImage} sharpImage={sharpImage} image={full_width_image} />
        <GreatStateCaption text={caption} />
      </FullWidthContent>
    </div>
  )
}
