import React from 'react'
import classnames from 'classnames'
import css from './FigureListBlock.module.css'

export default ({ figureList, hasMargin }) => {
  const hasMarginClass = hasMargin ? css.hasMargin : ''

  return (
    <div className={classnames(css.figureList, hasMarginClass)}>
      {figureList.map((figureData, index) => (
        <FigureUI key={`figure${index}`} {...figureData} />
      ))}
    </div>
  )
}

const FigureUI = ({ figure, label }) => (
  <div className={css.figure}>
    <strong className={css.figure__value}>{figure}</strong>
    <p className={css.figure__label}>{label}</p>
  </div>
)
