import React, { useEffect, useState } from 'react'
import { useSwipeable } from 'react-swipeable'
import get from 'lodash/get'
import { Card, Controls, Heading } from '@components'
import site from '@utils/site'
import { addLeadingZero } from '@utils/helpers'
import useWindowWidth from '@hooks/useWindowWidth'
import css from './ArticleSlider.module.css'

const colours = site.getPrimaryColorsArray()
/**
 * @param {String}  props.title                 Title text that appears above the slider
 * @param {String}  props.theme                 Sets component styles (background colour, etc.)
 * @param {Array}   props.articles              Array of articles; used to populate slider
 * @param {String}  props.articles[].category   Article category
 * @param {String}  props.articles[].image      Url for the article image
 * @param {String}  props.articles[].title      Title text for the article item
 * @param {String}  props.articles[].introText  Text displayed beneath the article title
 * @param {String}  props.articles[].slug       Url to the dedicated article page
 */

const ArticleSlider = ({
  title,
  articles,
  config = { size: 3, baseUrl: '/blog/articles', useLogo: false, numbered: false },
}) => {
  const [isClient, setIsClient] = useState(false)
  const [position, setPosition] = useState(0)

  // Identify client/server render to resolve Gatsby hydration issue
  useEffect(() => {
    setIsClient(true)
  }, [])

  const articlesWithContent = articles.filter(article => article != null)

  const limit =
    useWindowWidth() >= site.maxDeviceWidths.SMALL
      ? Math.floor(articlesWithContent.length / config.size)
      : articlesWithContent.length

  const articlesPerPage = useWindowWidth() >= site.maxDeviceWidths.SMALL ? config.size : 1

  if (position > limit) {
    setPosition(0)
  }

  const prev = () => {
    if (position === 0) return
    setPosition(position - 1)
  }
  const next = () => {
    if (position === limit) return
    setPosition(position + 1)
  }
  const handlers = useSwipeable({
    onSwipedLeft: () => next(),
    onSwipedRight: () => prev(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  })

  return (
    <section
      className={`${css.section__wrapper} relative`}
      data-component="ArticleSlider"
      key={isClient ? 'csr' : 'ssr'}
    >
      <Heading text={title} />

      {articles.length > articlesPerPage && (
        <Controls position={position} limit={limit} prev={prev} next={next} showPages />
      )}
      <div
        {...handlers}
        className={css.articleslider__wrapper}
        style={{ transform: `translateX(-${position * 100}%)` }}
      >
        {articlesWithContent.map((article, index) => {
          // graphql typename fragment is giving a string on services page
          if (typeof article === 'object') {
            // eslint-disable-next-line no-underscore-dangle
            const url = article._meta.uid
            const color = colours[index % 3]
            const width = `${100 / articlesPerPage}%`
            const useSvg = false // config.useLogo; //todo: pending svg support on Card
            const svgImage = null // article.logo; //todo: pending svg support on Card
            const singleImage = get(article, 'logo_image') // Needed for prismic preview
            const sharpImage = get(article, 'logoImage.childImageSharp.fluid', singleImage)

            return (
              <Card
                key={index + url}
                article_type={article.article_type}
                images={article}
                text={article.intro_text}
                title={article.title}
                url={`${config.baseUrl}/${url}`}
                delay={index * 0.3}
                color={color}
                articleType="blog"
                flexBasis={width}
                current={index === position}
                groupSize={config.size}
                useSvg={useSvg}
                svgImage={svgImage}
                sharpImageOverride={sharpImage}
                hasNumbering={config.numbered}
                cardNumber={addLeadingZero(index + 1)}
              />
            )
          }
          return null
        })}
      </div>
    </section>
  )
}

export default ArticleSlider
