import React from 'react'
import get from 'lodash/get'
import { Underline, RightTransitionLink } from '@components'
import kebabCase from 'lodash.kebabcase'
import css from './JobList.module.css'

const JobList = ({ intro, jobs, image }) => {
  return (
    <div className={css.joblist}>
      <div className={css.joblist__intro}>
        <p>{intro}</p>
      </div>
      <div className={css.joblist__jobs}>
        {!jobs.length && (
          <p>
            Sorry there are currently no roles available, but please check
            back soon for any new opportunities.
          </p>
        )}
        {jobs.map((job, i) => {
          const { department, title, summary } = job
          return (
            <div className={css.joblist__job} key={i}>
              <p className={css.joblist__department}>{department}</p>
              <h4>
                <RightTransitionLink to={`/careers/jobs/${kebabCase(title)}`}>
                  <Underline text={title} color="" />
                </RightTransitionLink>
              </h4>

              <div className={css.joblist__summary}>{summary}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default JobList
