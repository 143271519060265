import React from 'react'
import VideoEmbed from '../../VideoEmbed/VideoEmbed'
import css from './AnimatedText.module.css'

const getDelayedValue = (lastDelay, offsetDelay) => {
  const delayNum = lastDelay >= 100 ? 1 : +`0.${`${lastDelay}`.padStart(2, '0')}`

  return delayNum + (offsetDelay || 0)
}

const SplitWord = ({ styles, content, reverse, delay }) => {
  const len = content.length
  return (
    <div className={styles}>
      {content.split('').map((char, i) => {
        // TODO: always runs from 0s->1s
        const percentDiff = reverse
          ? 100 - Math.round((i / len) * 100)
          : Math.round((i / len) * 100)
        return (
          <span
            key={`split-${char}-${i}`}
            style={{
              animationDelay: `${getDelayedValue(percentDiff, delay)}s`,
            }}
          >
            {char === ' ' ? String.fromCharCode(160) : char}
          </span>
        )
      })}
    </div>
  )
}

const AnimatedText = ({ onClick }) => {
  return (
    <>
      <div className={css.animatedText}>
        <div className={css.row}>
          <span className={css.textShaping}>Shaping</span>
          <SplitWord delay={0} styles={css.textNextGeneration} content="next generation" reverse />
        </div>
        <div className={css.row}>
          <SplitWord delay={0} styles={css.textCustomer} content="customer" />
          <span className={css.textExperiences}>experiences</span>
        </div>
      </div>
      <div className={css.cta}>
        <div className={css.cta_l}>
          <span>New website on its way. </span>
          <span>
            See what&rsquo;s been keeping us busy.
          </span>
        </div>
        <div className={css.cta_r}>
          <a onClick={onClick} href="/work" className={css.button}>
            Showreel
          </a>
        </div>
      </div>
    </>
  )
}

export default AnimatedText
