import React from 'react'
import dayjs from 'dayjs'
import { Avatar } from '@components'
import get from 'lodash/get'
import css from './ArticleInfo.module.css'

export default ({ author, date, readTime, className, groupSize }) => {
  const authorImage = get(author, 'profile_picture.url')

  const authorProps = author
    ? {
        name: author && author.name,
        title: author.job_title,
        date,
        authorImage,
      }
    : null

  const groupSizeClass = () => {
    if (groupSize === 2) return css.groupSize2
    if (groupSize === 3) return css.groupSize3
    return null
  }

  return (
    <div className={`${css.articleInfo} ${className} ${groupSizeClass()}`}>
      <AuthorImage image={authorImage} />
      <span className={css.articleInfo__text}>
        {authorProps && <Author {...authorProps} />}
        <span className={css.dateAndLength}>
          {date && <PublishDate date={date} />}
          {readTime && <ReadLength time={readTime} />}
        </span>
      </span>
    </div>
  )
}

const AuthorImage = ({ image }) => {
  if (!image) {
    return null
  }
  return (
    <span className={css.articleInfo__avatar}>
      <Avatar src={image} />
    </span>
  )
}

const Author = ({ name, title }) => (
  <span className={css.author}>
    By {title ? `${title}, ` : ''}
    <strong>{name}</strong>
  </span>
)

const PublishDate = ({ date }) => (
  <span>
    <span className={css.publishDate__bullet}>•</span>
    {dayjs(date).format('DD MMM YYYY')}
  </span>
)

const ReadLength = ({ time }) => {
  const unit = time === 1 ? 'min' : 'mins'

  return (
    <span>
      <span className={css.articleInfo__bullet}>•</span>
      {time} {unit} read
    </span>
  )
}
