import React, { Fragment, useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { get, filter, each } from 'lodash'
import { getSeoProps } from '@data/seo'
import { prismicGettingHereFields } from '@graphql/fragments'
import { SEO } from '@templates'
import { 
    ArticleSlider,
    BackLink, 
    Banner, 
    Intro, 
    MailingListSignup, 
    SliceGenerator, 
    TravelMethods
} from '@components'

export const query = graphql`
query PrismicGettingHereByUID($uid: String!) {
    prismic {
      getting_here(uid: $uid, lang: "en-gb") {
        ...prismicGettingHereFields
      }
    }
  }
`

const GettingHere = ({ data: { prismic } }) => {

  const [isClient, setIsClient] = useState(false)
  const [position, setPosition] = useState(0)

  // Identify client/server render to resolve Gatsby hydration issue
  useEffect(() => {
    setIsClient(true)
  }, [])

  const data = get(prismic, 'getting_here', {})

  if (!data) {
    return null
  }

  const body = get(data, 'body')
  
  const mailingListProps = get(data, 'mailinglist')
  const pageName = get(data, 'name[0].text', '')

  const introTitle1 = get(data, 'heading')
  const introText1 = get(data, 'intro')

  const seoProps = getSeoProps(data)

  const methods = get(data, 'travel_methods')
  const articleList = data.slider_articles.map(x => x.article)

  return (
    <Fragment>
      <SEO {...seoProps} />

      <BackLink to="/contact-us" className="back" />

      <Intro 
        label={['getting here']} 
        title={introTitle1} 
        text={introText1} 
        key={isClient ? 'intro1-csr' : 'intro1-ssr'}
        customClasses={['gettingHere']}
      />

      <TravelMethods key={isClient ? 'travelMethods-csr' : 'travelMethods-ssr'}  {...{methods}} />

      <Banner
        title="Want to lead your market?"
        text="Get in touch to find out how we can help your brand"
        ctaText="hello@greatstate.co"
        ctaUrl="mailto:hello@greatstate.co"
        colorWay="slate"
        key={isClient ? 'banner-csr' : 'banner-ssr'}
      />

    </Fragment>
  )
}


const Slices = ({ sliceTypes, body, key }) => {
  const filterSlicesBySliceType = types => {
    return filter(body, b => {
      return types.indexOf(b.type) !== -1
    })
  }

  const addConfiguredSlicestoFilteredSlices = (types, filteredSlices) => {
    const configSlices = filter(types, s => {
      return s.type !== undefined
    })
    each(body, b => {
      const block = b
      each(configSlices, cs => {
        if (block.type === cs.type) {
          const data = { ...block, config: cs.config}
          filteredSlices.push(data)
        }
      })
    })
  }

  const filteredSlices = filterSlicesBySliceType(sliceTypes)
  addConfiguredSlicestoFilteredSlices(sliceTypes, filteredSlices)

  return <SliceGenerator {...key} slices={filteredSlices} />
}

GettingHere.fragments = [prismicGettingHereFields]

export default GettingHere

GettingHere.query = query