import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useInView } from 'react-intersection-observer'
import classnames from 'classnames'
import css from './BlockReveal.module.css'

/**
 * @summary Block revealer
 * @property {node} children Child element/elements
 * @property {threshold} number Point at which the reveal triggers (fraction of the element visible)
 * @property {direction} string Direction (up, down, left, right) Default right
 * @property {inline} boolean Display inline-block
 */

const BlockReveal = ({
  children,
  threshold = 0,
  color,
  delay,
  duration,
  direction,
  inline,
  previousChildren,
}) => {
  const [ref, inView] = useInView({
    threshold,
    rootMargin: '250px',
    triggerOnce: true,
  })

  return (
    <div
      ref={ref}
      className={classnames(css.blockRevealAnimation, css[direction], {
        [`${css.inline}`]: inline,
        [`${css.isVisible}`]: inView,
        [`${css.slideOverChildren}`]: previousChildren,
      })}
    >
      {previousChildren && inView && (
        <div
          className={css.blockRevealAnimation__previouselement}
          style={{
            animationDelay: `${delay + duration / 2}s`,
          }}
        >
          {previousChildren}
        </div>
      )}
      {inView && (
        <div
          className={css.blockRevealAnimation__element}
          style={{
            animationDelay: `${delay + duration / 2}s`,
          }}
        >
          {children}
        </div>
      )}
      <span
        className={css.blockRevealAnimation__block}
        style={{
          animationDelay: `${delay}s`,
          animationDuration: `${duration}s`,
          background: '#F7F7F7',
        }}
      />
    </div>
  )
}

export default BlockReveal

BlockReveal.propTypes = {
  children: PropTypes.node.isRequired,
  delay: PropTypes.number,
  duration: PropTypes.number,
  threshold: PropTypes.number,
  direction: PropTypes.oneOf(['left', 'right', 'up', 'down']),
  inline: PropTypes.bool,
}

BlockReveal.defaultProps = {
  threshold: 0.6,
  delay: 0,
  duration: 0.8,
  direction: 'right',
  inline: false,
}
