import React from 'react'
import css from './LayoutAware.module.css'
import ReactDOM from 'react-dom'

// Deferes certain layout characteristics to any ancestor LayoutAware component
class LayoutAware extends React.Component {

    componentDidMount = () => {
        this.defer = this.deferLayoutToAncestor()
    };

    findAncestor = () => {
        let el = ReactDOM.findDOMNode(this);
        while (el && el.parentNode) {
            el = el.parentNode;
            if (el && el.attributes && el.getAttribute('islayoutaware') === 'true') {
                return el;
            }
        }
        return null;
    }

    deferLayoutToAncestor = () => {
        if (this.findAncestor()) {
            return true; // ancestor found so let that element control the layout
        } else {
            return false; // no ancestors found
        }
    };

    render = () => {
        const { children } = this.props;
        return (
            <div className={`${this.defer ? '' : css.negativeMargin} ${css.flexLayout}`} islayoutaware="true">
                {children}
            </div>
        )
    }
};

export default LayoutAware
