import { graphql } from 'gatsby'

/**
 * ✏️ Blog Article
 */
export const prismicBlogArticleFields = graphql`
  fragment prismicBlogArticleFields on PRISMIC_Blog_article {
    article_type
    title
    summary_text
    seodescription
    seotitle
    author {
      ... on PRISMIC_Person {
        name
        job_title
        profile_picture
      }
    }
    published
    image
    programming_language
    singleAspectRatioImage: imageSharp {
      childImageSharp {
        ...prismicGatsbyImageSharp
      }
    }
    link: _meta {
      uid
    }
    related_blogs {
      blog {
        ... on PRISMIC_Blog_article {
          title
          summary_text
          author {
            ... on PRISMIC_Person {
              name
              job_title
            }
          }
          thumbnail
          article_type
          published
          link: _meta {
            uid
          }
          image
          _meta {
            uid
          }
        }
      }
    }
    body {
      ... on PRISMIC_Blog_articleBodyText {
        type
        primary {
          text
        }
      }
      ... on PRISMIC_Blog_articleBodyContent_width_image {
        type
        primary {
          caption
          content_width_image
          content_width_imageSharp {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1280) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
                presentationWidth
              }
            }
          }
        }
      }
      ... on PRISMIC_Blog_articleBodyVideo_embed {
        type
        primary {
          vimeo_id
        }
      }
    }
    _meta {
      uid
    }
  }
`

/**
 * ✏️ Partner fields for Article slider
 */
export const prismicPartnerPageFields = graphql`
  fragment prismicPartnerPageFields on PRISMIC_Partner_page {
    text: intro_text
    title
    landscape_image
    portrait_image
    article_type
    logo_image
    largeDeviceImage: landscape_imageSharp {
      childImageSharp {
        ...prismicGatsbyImageSharp
      }
    }
    smallDeviceImage: portrait_imageSharp {
      childImageSharp {
        ...prismicGatsbyImageSharp
      }
    }
    logoImage: logo_imageSharp {
      childImageSharp {
        ...prismicGatsbyImageSharp
      }
    }
    _meta {
      uid
    }
  }
`

/**
 * 🖼️  Image
 */
export const prismicGatsbyImageSharp = graphql`
  fragment prismicGatsbyImageSharp on ImageSharp {
    fluid(quality: 100) {
      ...GatsbyImageSharpFluid_withWebp_noBase64
      presentationWidth
    }
  }
`

/**
 * 💼 Case Study Card
 */
export const prismicCaseStudyCard = graphql`
  fragment prismicCaseStudyCard on PRISMIC_Case_study {
    title
    intro_text
    portrait_image
    landscape_image
    link: _meta {
      uid
    }
    largeDeviceImage: landscape_imageSharp {
      childImageSharp {
        ...prismicGatsbyImageSharp
      }
    }
    smallDeviceImage: portrait_imageSharp {
      childImageSharp {
        ...prismicGatsbyImageSharp
      }
    }
  }
`
// Ideally we would mutate the above fragment
// Unfortunately variables in fragments is not yet supported
export const prismicCaseStudyCardNoTitle = graphql`
  fragment prismicCaseStudyCardNoTitle on PRISMIC_Case_study {
    intro_text
    portrait_image
    landscape_image
    link: _meta {
      uid
    }
    largeDeviceImage: landscape_imageSharp {
      childImageSharp {
        ...prismicGatsbyImageSharp
      }
    }
    smallDeviceImage: portrait_imageSharp {
      childImageSharp {
        ...prismicGatsbyImageSharp
      }
    }
  }
`

export const prismicSectorCard = graphql`
fragment prismicSectorCard on PRISMIC_Sector {
  name
  image: landscape_image
  _meta {
    uid
  }
}
`

export const prismicPartnerPageCardNoTitle = graphql`
  fragment prismicPartnerPageCardNoTitle on PRISMIC_Partner_page {
    intro_text
    portrait_image
    landscape_image
    link: _meta {
      uid
    }
    largeDeviceImage: landscape_imageSharp {
      childImageSharp {
        ...prismicGatsbyImageSharp
      }
    }
    smallDeviceImage: portrait_imageSharp {
      childImageSharp {
        ...prismicGatsbyImageSharp
      }
    }
  }
`

/**
 * 💼 Portait Case Study Card
 */
export const prismicPortraitCaseStudyCard = graphql`
  fragment prismicPortraitCaseStudyCard on PRISMIC_Case_study {
    title
    intro_text
    portrait_image
    landscape_image
    link: _meta {
      uid
    }
    largeDeviceImage: portrait_imageSharp {
      childImageSharp {
        ...prismicGatsbyImageSharp
      }
    }
    smallDeviceImage: portrait_imageSharp {
      childImageSharp {
        ...prismicGatsbyImageSharp
      }
    }
  }
`

/**
 * 📆 Event
 */
export const prismicEventFields = graphql`
  fragment prismicEventFields on PRISMIC_Event {
    event_title
    event_date
    location
    date_time
    published
    author {
      ... on PRISMIC_Person {
        name
        job_title
        profile_picture
      }
    }
    event_link {
      ... on PRISMIC__ExternalLink {
        _linkType
        url
      }
    }
    text
    image
    singleAspectRatioImage: imageSharp {
      childImageSharp {
        ...prismicGatsbyImageSharp
      }
    }
    _meta {
      uid
    }
  }
`

/**
 * 🕴️Job Listing
 */
export const prismicJobListingFields = graphql`
  fragment prismicJobListingFields on PRISMIC_Job_listing {
    seodescription
    seotitle
    job_title
    job_description
    summary
    responsibilities
    requirements
    location
    ref
    department {
      ... on PRISMIC_Department {
        title
      }
    }
    salary
    date_added
    advertiser {
      ... on PRISMIC_Person {
        name
        job_title
        profile_picture
        profile_pictureSharp {
          childImageSharp {
            fixed(width: 200) {
              src
            }
          }
        }
      }
    }
    _meta {
      uid
    }
  }
`

/**
 * 🕴️Service
 */
export const prismicServiceFields = graphql`
  fragment prismicServiceFields on PRISMIC_Service {
    name
    heading
    intro
    seodescription
    seotitle
    clientQuoteTabs: client_image_and_quote_tabs {
      author {
        ... on PRISMIC_Person {
          name
          job_title
        }
      }
      quote
      image
      client
    }
    order_steps
    steps_heading
    steps_intro
    steps {
      text
      title
    }
    body {
      ... on PRISMIC_ServiceBodyListing__case_study {
        type
        label
        primary {
          listing_title
        }
        fields {
          case_study {
            _linkType
            ... on PRISMIC_Case_study {
              title
              portrait_image
              landscape_image
              link: _meta {
                uid
              }
              largeDeviceImage: landscape_imageSharp {
                childImageSharp {
                  ...prismicGatsbyImageSharp
                }
              }
              smallDeviceImage: portrait_imageSharp {
                childImageSharp {
                  ...prismicGatsbyImageSharp
                }
              }
            }
          }
        }
      }
      ...prismicServiceImageQuoteFields
      ... on PRISMIC_ServiceBodyList_of_articles {
        type
        label
        primary {
          title_of_section
          article_1 {
            ...prismicBlogArticleFields
          }
          article_2 {
            ...prismicBlogArticleFields
          }
          article_3 {
            ...prismicBlogArticleFields
          }
          article_4 {
            ...prismicBlogArticleFields
          }
          article_5 {
            ...prismicBlogArticleFields
          }
          article_6 {
            ...prismicBlogArticleFields
          }
          article_7 {
            ...prismicBlogArticleFields
          }
          article_8 {
            ...prismicBlogArticleFields
          }
          article_9 {
            ...prismicBlogArticleFields
          }
          article_10 {
            ...prismicBlogArticleFields
          }
        }
      }
      ... on PRISMIC_ServiceBodyArticle_slider_block {
        type
        label
        primary {
          title_of_section
          article_1 {
            ...prismicPartnerPageFields
          }
          article_2 {
            ...prismicPartnerPageFields
          }
          article_3 {
            ...prismicPartnerPageFields
          }
          article_4 {
            ...prismicPartnerPageFields
          }
          article_5 {
            ...prismicPartnerPageFields
          }
          article_6 {
            ...prismicPartnerPageFields
          }
          article_7 {
            ...prismicPartnerPageFields
          }
          article_8 {
            ...prismicPartnerPageFields
          }
          article_9 {
            ...prismicPartnerPageFields
          }
          article_10 {
            ...prismicPartnerPageFields
          }
        }
      }
      ... on PRISMIC_ServiceBodyArticle_slider_block {
        type
        label
        primary {
          title_of_section
          article_1 {
            ...prismicBlogArticleFields
          }
          article_2 {
            ...prismicBlogArticleFields
          }
          article_3 {
            ...prismicBlogArticleFields
          }
          article_4 {
            ...prismicBlogArticleFields
          }
          article_5 {
            ...prismicBlogArticleFields
          }
          article_6 {
            ...prismicBlogArticleFields
          }
          article_7 {
            ...prismicBlogArticleFields
          }
          article_8 {
            ...prismicBlogArticleFields
          }
          article_9 {
            ...prismicBlogArticleFields
          }
          article_10 {
            ...prismicBlogArticleFields
          }
        }
      }
      ...serviceCta
    }
    meta: _meta {
      uid
    }
  }
`




/**
 * Sector
 */
 export const prismicSectorFields = graphql`
 fragment prismicSectorFields on PRISMIC_Sector {
   name
   heading
   intro
   heading_1
   intro_1
   seodescription
   seotitle
   clientQuoteTabs: client_image_and_quote_tabs {
     author {
       ... on PRISMIC_Person {
         name
         job_title
       }
     }
     quote
     image
     client
     link {
      _linkType
      ... on PRISMIC_Case_study {
        meta: _meta {
          uid
        }
      }
     }
   }
    report_title
    report_copy
    report_file {
    _linkType
    ... on PRISMIC__FileLink {
      _linkType
      url
    }
    }
   slider_heading
   slider_articles {
     article {
       ...prismicBlogArticleFields
     }
   }
   content_listing {
    heading: content_heading
    content
   }
   body {
      ... on PRISMIC_SectorBodyListing__case_study {
        type
        label
        primary {
          listing_title
        }
        fields {
          case_study {
            _linkType
            ... on PRISMIC_Case_study {
              title
              portrait_image
              landscape_image
              link: _meta {
                uid
              }
            }
          }
        }
      }
     ...prismicSectorImageQuoteFields
     ... on PRISMIC_SectorBodyList_of_articles {
       type
       label
       primary {
         title_of_section
         article_1 {
           ...prismicBlogArticleFields
         }
         article_2 {
           ...prismicBlogArticleFields
         }
         article_3 {
           ...prismicBlogArticleFields
         }
         article_4 {
           ...prismicBlogArticleFields
         }
         article_5 {
           ...prismicBlogArticleFields
         }
         article_6 {
           ...prismicBlogArticleFields
         }
         article_7 {
           ...prismicBlogArticleFields
         }
         article_8 {
           ...prismicBlogArticleFields
         }
         article_9 {
           ...prismicBlogArticleFields
         }
         article_10 {
           ...prismicBlogArticleFields
         }
       }
     }
     ... on PRISMIC_SectorBodyArticle_slider_block {
       type
       label
       primary {
         title_of_section
         article_1 {
           ...prismicPartnerPageFields
         }
         article_2 {
           ...prismicPartnerPageFields
         }
         article_3 {
           ...prismicPartnerPageFields
         }
         article_4 {
           ...prismicPartnerPageFields
         }
         article_5 {
           ...prismicPartnerPageFields
         }
         article_6 {
           ...prismicPartnerPageFields
         }
         article_7 {
           ...prismicPartnerPageFields
         }
         article_8 {
           ...prismicPartnerPageFields
         }
         article_9 {
           ...prismicPartnerPageFields
         }
         article_10 {
           ...prismicPartnerPageFields
         }
       }
     }
     ... on PRISMIC_SectorBodyArticle_slider_block {
       type
       label
       primary {
         title_of_section
         article_1 {
           ...prismicBlogArticleFields
         }
         article_2 {
           ...prismicBlogArticleFields
         }
         article_3 {
           ...prismicBlogArticleFields
         }
         article_4 {
           ...prismicBlogArticleFields
         }
         article_5 {
           ...prismicBlogArticleFields
         }
         article_6 {
           ...prismicBlogArticleFields
         }
         article_7 {
           ...prismicBlogArticleFields
         }
         article_8 {
           ...prismicBlogArticleFields
         }
         article_9 {
           ...prismicBlogArticleFields
         }
         article_10 {
           ...prismicBlogArticleFields
         }
       }
     }
     ...sectorCta
     ... on PRISMIC_SectorBodyImage_text_block {
        type
        label
        primary {
          text
          image
        }
      }
   }
   meta: _meta {
     uid
   }
 }
`



/**
 * 🏢 Office Details
 */
export const prismicOfficeDetailsFields = graphql`
  fragment prismicOfficeDetailsFields on PRISMIC_Office_details {
    office_name
    description: office_description
    caption: office_caption
    address
    telephone_number
    telephone_number_display
    email
    google_map_link {
      ... on PRISMIC__ExternalLink {
        url
      }
    }
    directions_link {
      ... on PRISMIC_Getting_here {
        meta: _meta {
          uid
        }
      }
    }
    images {
      image
      imageSharp {
        childImageSharp {
          fluid(maxWidth: 700, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            presentationWidth
          }
        }
      }
    }
  }
`

/**
 * ◻️ Logo Board
 */
export const prismicLogoBoardFields = graphql`
  fragment prismicLogoBoardFields on PRISMIC_Logo_board {
    _meta {
      id
    }
    title
    heading
    logos {
      logo
    }
  }
`

/**
 *  Text Image
 */
export const prismicTextImageHeroFields = graphql`
  fragment prismicTextImageHeroFields on PRISMIC_Text_image {
    _meta {
      id
    }
    text
    image
    imageSharp {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

/**
 * 🏅 Values
 */
export const prismicValuesFields = graphql`
  fragment prismicValuesFields on PRISMIC_Values {
    heading
    values {
      ... on PRISMIC_ValuesValues {
        title
        text
        image
        imageSharp {
          childImageSharp {
            fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image_caption
      }
    }
  }
`

/**
 * 🎁️ Benefits
 */
export const prismicBenefitsFields = graphql`
  fragment prismicBenefitsFields on PRISMIC_Benefits {
    heading
    benefits {
      ... on PRISMIC_BenefitsBenefits {
        title
        text
        image
        imageSharp {
          childImageSharp {
            fixed(width: 1280, quality: 100) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

/**
 *  👩‍💻 Our People
 */
export const prismicOurPeopleFields = graphql`
  fragment prismicOurPeopleFields on PRISMIC_Our_people {
    heading
    quotes {
      ... on PRISMIC_Our_peopleQuotes {
        text
        image
        imageSharp {
          childImageSharp {
            fixed(width: 1182, height: 472, quality: 96) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
        author {
          ... on PRISMIC_Person {
            name
            job_title
          }
        }
      }
    }
  }
`

/**
 * 💬 Services double paragraph
 */
export const servicesDoubleParagraph = graphql`
  fragment servicesDoubleParagraph on PRISMIC_Services_pageBodyTitled_double_paragraph {
    type
    label
    content: primary {
      paragraph_1
      paragraph_2
      title
    }
  }
`

export const servicesCta = graphql`
  fragment servicesCta on PRISMIC_Services_pageBodyBanner {
    type
    content: primary {
      link_text
      paragraph_1
      title
    }
  }
`

export const serviceCta = graphql`
  fragment serviceCta on PRISMIC_ServiceBodyBanner {
    type
    content: primary {
      link_text
      paragraph_1
      title
    }
  }
`

export const sectorCta = graphql`
  fragment sectorCta on PRISMIC_SectorBodyBanner {
    type
    content: primary {
      link_text
      paragraph_1
      title
    }
  }
`

export const servicesPartnerBlock = graphql`
  fragment servicesPartnerBlock on PRISMIC_Services_pageBodyPartner_slider_block {
    type
    label
    primary {
      title_of_section
      article_1 {
        ...prismicPartnerPageFields
      }
      article_2 {
        ...prismicPartnerPageFields
      }
      article_3 {
        ...prismicPartnerPageFields
      }
      article_4 {
        ...prismicPartnerPageFields
      }
      article_5 {
        ...prismicPartnerPageFields
      }
      article_6 {
        ...prismicPartnerPageFields
      }
      article_7 {
        ...prismicPartnerPageFields
      }
      article_8 {
        ...prismicPartnerPageFields
      }
      article_9 {
        ...prismicPartnerPageFields
      }
      article_10 {
        ...prismicPartnerPageFields
      }
    }
  }
`

export const partnerCta = graphql`
  fragment partnerCta on PRISMIC_Partner_pageBodyBanner {
    type
    content: primary {
      link_text
      paragraph_1
      title
    }
  }
`

/**
 * 💬 Image Quote
 */
export const prismicImageQuoteFields = graphql`
  fragment prismicImageQuoteFields on PRISMIC_Services_pageBodyImage_quotes {
    fields {
      text
      image
      imageSharp {
        childImageSharp {
          fixed(width: 1280, quality: 100) {
            src
          }
        }
      }
      author {
        ... on PRISMIC_Person {
          name
          job_title
        }
      }
    }
    primary {
      title
    }
    type
  }
`

export const prismicServiceImageQuoteFields = graphql`
  fragment prismicServiceImageQuoteFields on PRISMIC_ServiceBodyImage_quotes {
    fields {
      text
      image
      imageSharp {
        childImageSharp {
          fixed(width: 1280, quality: 100) {
            src
          }
        }
      }
      author {
        ... on PRISMIC_Person {
          name
          job_title
        }
      }
    }
    primary {
      title
    }
    type
  }
` 

export const prismicSectorImageQuoteFields = graphql`
  fragment prismicSectorImageQuoteFields on PRISMIC_SectorBodyImage_quotes {
    fields {
      text
      image
      imageSharp {
        childImageSharp {
          fixed(width: 1280, quality: 100) {
            src
          }
        }
      }
      author {
        ... on PRISMIC_Person {
          name
          job_title
        }
      }
    }
    primary {
      title
    }
    type
  }
` 
 
export const prismicMailingListFields = graphql`
  fragment prismicMailingListFields on PRISMIC_Mailing_list {
    title
    intro
    image
    success
    theme
  }
`
export const prismicBannerFields = graphql`
  fragment prismicBannerFields on PRISMIC_Banner {
    title
    text
    link_text
    link {
      ... on PRISMIC__ExternalLink {
        url
      }
    }
  }
`


export const prismicGettingHereFields = graphql`
  fragment prismicGettingHereFields on PRISMIC_Getting_here {
    name
    heading
    intro
    travel_methods {
      title
      content
      icon
    }
   slider_heading
   slider_articles {
     article {
       ...prismicBlogArticleFields
     }
   }
  }
`