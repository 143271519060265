module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TG68QQQ","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"dataLayerName":"dataLayer"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.greatstate.co"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"greatstate","accessToken":"MC5YSklxQ0JBQUFDeUhWMWw2.77-977-9VO-_ve-_vSUV77-9U--_ve-_ve-_vXzvv73vv73vv70W77-977-977-9J--_vVPvv70uYhXvv73vv73vv71FKA","path":"/preview","previews":"false","refetchInterval":60,"pages":[{"type":"Blog_article","match":"/blog/articles/:uid","path":"/blog/articles","component":"/opt/build/repo/src/templates/BlogArticle/BlogArticle.jsx"},{"type":"Case_study","match":"/work/casestudy/:uid","path":"/work/casestudy","component":"/opt/build/repo/src/templates/CaseStudy/CaseStudy.jsx"},{"type":"Getting_here","match":"/getting-here/:uid","path":"/getting-here","component":"/opt/build/repo/src/templates/GettingHere/GettingHere.jsx"},{"type":"Job_listing","match":"/careers/temp/:uid","path":"/careers/temp","component":"/opt/build/repo/src/templates/JobListing/JobListing.jsx"},{"type":"Sector","match":"/expertise/:uid","path":"/expertise","component":"/opt/build/repo/src/templates/Sector/Sector.jsx"},{"type":"Service","match":"/services/service/:uid","path":"/services/service","component":"/opt/build/repo/src/templates/Service/Service.jsx"},{"type":"Partner_page","match":"/partners/:uid","path":"/partners","component":"/opt/build/repo/src/templates/Partner/Partner.jsx"}],"omitPrismicScript":"true"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[],"layout":"/opt/build/repo/src/templates/Layout/Layout.jsx"},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
