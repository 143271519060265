import React from 'react'
import { RichText } from '@components'
import css from './Report.module.css'

/**
 * @param {object} props Object containg props passed down to component
 * @param {string} props.title - title of Banner
 * @param {string} props.text - text for report
 * @param {string} props.ctaText - text for report link
 * @param {string} props.fileUrl - url for report link
 *
 */

const Report = ({ title, text, ctaText, fileUrl }) => {
  if (!title || !text) return false

  const theTitle = title[0].text
  const fileURL = fileUrl.url

  return (
    <div className={css.report} data-component="Report">
      <div className={css.report__cols}>
        <div>
          <h2 className={css.report__title}>{theTitle}</h2>
          <RichText render={text} />
        </div>
        <div className={`${css.report__colRight}`}>
        {fileURL && ctaText && (
          <a href={fileURL} className={css.report__cta} target="_blank" rel="noopener noreferrer">
          {ctaText}
          </a>
        )}
        </div>
      </div>
    </div>
  )
}

export default Report