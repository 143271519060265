import React, { Fragment } from 'react'
import classnames from 'classnames'
import { RightTransitionLink, RichText } from '@components'
import css from './Intro.module.css'

const HomeIntro = ({ title, serviceLinks }) => {
  return (
    <div className={classnames(css.intro, css.wideText)}>
      <div className={css.intro__inr}>
        <div className={css.intro__intro}>
          <div className={css.intro__text}>
            <RichText render={title} />
            <ServiceLinkList linkList={serviceLinks} />
          </div>
        </div>
      </div>
    </div>
  )
}

const ServiceLinkList = ({ linkList }) => {
  if (!linkList) {
    return null
  }

  return (
      <p className={classnames(css.largeText, 'richtext')}>
        {`A digital product and service design agency. We specialise in `}
        {linkList.map((link, i) => {
          if (i !== linkList.length - 1) {
            return (
              <Fragment key={`serviceLink${i}`}>
                <ServiceLink {...{ link }} />
                {`, `}
              </Fragment>
            )
          }
          return (
            <Fragment key={`serviceLink${i}`}>
              {`and `}
              <ServiceLink {...{ link }} />
            </Fragment>
          )
        })}
      </p>
  )
}

const ServiceLink = ({ link }) => (
  <strong>
    <RightTransitionLink className={css.intro__link} to={link.url}>
      {link.text}
    </RightTransitionLink>
  </strong>
)

export default HomeIntro
