import React from 'react'
import css from './VideoEmbed.module.css'

const VideoEmbed = ({
  videoId,
  urlParameters = null,
  overrideStyle = null,
  htmlAttributes = null,
  height = '360',
  width = '480',
}) => {
  const isObject = obj => !!obj && typeof obj === 'object'

  const getParams = params =>
    isObject(params)
      ? `?${Object.entries(params)
          .map(([a, b]) => `${a}=${b}`)
          .join('&')}`
      : ''

  if (videoId != null) {
    return (
      <div className={overrideStyle || css.embed}>
        <iframe
          title={`vimeo${videoId}`}
          src={`https://player.vimeo.com/video/${videoId}${getParams(urlParameters)}`}
          width={width}
          height={height}
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
          {...htmlAttributes}
        />
      </div>
    )
  }
  return null
}

export default VideoEmbed
