import { useState, useEffect } from 'react'

export default () => {
  if (typeof window === 'undefined') return 800

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize, { passive: true })
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  return windowWidth
}
