import React, { useState, useRef } from 'react'
import ReactPlayer from 'react-player'
import classnames from 'classnames'
import useInterval from '@hooks/useInterval'
import css from './Video.module.css'

const Video = ({ url, overlayTextList }) => {
  if (!url) {
    return null
  }

  const [isVideoReady, setVideoIsReady] = useState(false)
  const [videoDuration, setVideoDuration] = useState(undefined)
  const videoRef = useRef(null)

  const handleDuration = duration => {
    setVideoDuration(duration)
  }

  const containsText = textContent => textContent.overlayText[0].text > 0
  const textOverlayContainsText = overlayTextList.every(containsText)

  return (
    <div
      className={classnames({
        [`${css.playerWrapper}`]: true,
        [`${css.reactPlayerReady}`]: isVideoReady,
      })}
    >
      <LoadingLayer isHidden={isVideoReady} text={overlayTextList[0].overlayText[0].text} />
      <ReactPlayer
        ref={videoRef}
        className={css.reactPlayer}
        url={url}
        width="100%"
        height="100%"
        muted
        playing
        loop
        controls={false}
        onDuration={duration => {
          handleDuration(duration)
        }}
        playsinline
        onReady={() => {
          setVideoIsReady(true)
        }}
      />
      {textOverlayContainsText && (
        <OverlayText textList={overlayTextList} videoDuration={videoDuration} />
      )}
    </div>
  )
}

const OverlayText = ({ textList, videoDuration }) => {
  const [textIndex, setTextIndex] = useState(0)
  const numberOfTexts = textList.length
  const textUpdateInterval = !videoDuration ? 1000000000 : (videoDuration * 1000) / numberOfTexts

  useInterval(() => {
    if (textIndex - 1 >= 0) {
      return setTextIndex(textIndex - 1) % numberOfTexts
    }
    return setTextIndex(textIndex + numberOfTexts - 1) % numberOfTexts
  }, textUpdateInterval)

  return (
    <div
      className={classnames({
        [`${css.overlayText}`]: true,
      })}
    >
      {textList[textIndex].overlayText[0].text}
    </div>
  )
}

//   step.currentStep - 1 >= 0
//  ? (step.currentStep - 1) % stories.length
//  : (step.currentStep + stories.length - 1) % stories.length,

const LoadingLayer = ({ text, isHidden }) => (
  <div
    className={classnames({
      [`${css.loadingLayer}`]: true,
      [`${css.loadingLayerHidden}`]: isHidden,
    })}
  >
    <div className={css.overlayText}>{text}</div>
  </div>
)

export default Video
