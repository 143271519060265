import React, { Fragment } from 'react'
import site from '@utils/site'
import helpers from '@utils/helpers'
import { ContentSection, LogoBoard, TextImage } from '@components'

const ContentLoader = ({ content }) => {
  return (
    <Fragment>
      {content.map((item, i) => {
        const component = item.content_item

        let ContentComponent = ''

        if (helpers.snakeToKebabCase(component.__typename) === site.componentDocTypes.LOGO_BOARD) {
          ContentComponent = LogoBoard
        }

        if (helpers.snakeToKebabCase(component.__typename) === site.componentDocTypes.TEXT_IMAGE) {
          ContentComponent = TextImage
        }

        return (
          <ContentSection key={component._meta.id + i}>
            <ContentComponent data={component} />
          </ContentSection>
        )
      })}
    </Fragment>
  )
}

export default ContentLoader
