import React, { useState, Fragment, useEffect } from 'react'
import { useInterval } from 'use-interval'
import PropTypes from 'prop-types'
import { InstagramCaption } from '@components'
import css from './Cycle.module.css'

/**
 * @summary Cycle component
 * @property {array | node} children - Passed down child or children
 * @property {number} duration - Duration in ms
 * @property {boolean} hasBar - Show count down bar
 * @property {boolean} hasCaptions - has captions, taken from caption property on child.
 *
 */

const Cycle = ({ children, duration, hasBar, hasCaptions, delay }) => {
  const childrenArray = React.Children.toArray(children)

  if (childrenArray.length > 1) {
    const [position, setPosition] = useState(0)
    const [active, setActive] = useState(true)

    const isCurrent = (pos, index) => {
      return pos === index
    }

    const isPrevious = (pos, index) => {
      const newPos = pos - 1 >= 0 ? pos - 1 : childrenArray.length - 1
      return newPos === index
    }

    const initialDuration = delay ? duration * 1.5 : duration

    const [cycleDuration, setDuration] = useState(initialDuration)

    useEffect(() => {
      const timeout = setTimeout(() => {
        if (delay) {
          setDuration(duration)
        }
      }, initialDuration)
      return () => {
        clearTimeout(timeout)
      }
    }, [])

    if (childrenArray.length > 1) {
      useInterval(() => {
        setActive(false)
        window.setTimeout(() => {
          const newPos = position === childrenArray.length - 1 ? 0 : position + 1
          setPosition(newPos)
          setActive(true)
        }, 50)
      }, cycleDuration)
    }
    return (
      <Fragment>
        <div className={css.cycle}>
          <div className={css.sizer}>{childrenArray[0]}</div>
          <div className={css.cycle__items}>
            {children.map((child, i) => (
              <div
                key={i}
                className={`class-${i} ${css.cycle__item} ${
                  isCurrent(position, i) ? css.isCurrent : ''
                } ${isPrevious(position, i) ? css.isPrevious : ''}`}
              >
                {child}
              </div>
            ))}
          </div>
          {hasBar && (
            <div
              className={`${css.bar} ${active ? css.fill : ''}`}
              style={{ animationDuration: `${cycleDuration - 100}ms` }}
            />
          )}
        </div>
        {hasCaptions && (
          <div className={css.captions}>
            <div className={css.caption__items}>
              {children.map((child, i) => (
                <div
                  key={i}
                  className={`${css.caption__item} ${isCurrent(position, i) ? css.isCurrent : ''} ${
                    isPrevious(position, i) ? css.isPrevious : ''
                  }`}
                  style={{ transform: `translateX(-${i * 100}%` }}
                >
                  <InstagramCaption text={child.props.caption} />
                </div>
              ))}
            </div>
          </div>
        )}
      </Fragment>
    )
  }
  return <Fragment>{children}</Fragment>
}

export default Cycle

Cycle.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  duration: PropTypes.number,
  hasBar: PropTypes.bool,
  hasCaptions: PropTypes.bool,
}

Cycle.defaultProps = {
  duration: 10000,
  hasBar: true,
  hasCaptions: false,
}
