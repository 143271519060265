import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { SEO } from '@templates'
import { removeLeadingSlash } from '@utils/helpers'
import useWindowWidth from '@hooks/useWindowWidth'
import { getSeoProps } from '@data/seo'
import { prismicGatsbyImageSharp, prismicCaseStudyCardNoTitle } from '@graphql/fragments'
import CaseStudyUI from './CaseStudyUI'

export const query = graphql`
  query PrismicCaseStudyByDocumentID($uid: String!) {
    prismic {
      case_study(uid: $uid, lang: "en-gb") {
        _meta {
          lastPublicationDate
        }
        text: intro_text
        title
        landscape_image
        portrait_image
        seodescription
        seotitle
        largeDeviceImage: landscape_imageSharp {
          childImageSharp {
            ...prismicGatsbyImageSharp
          }
        }
        smallDeviceImage: portrait_imageSharp {
          childImageSharp {
            ...prismicGatsbyImageSharp
          }
        }
        body {
          ... on PRISMIC_Case_studyBodyQuote {
            type
            primary {
              text
              author {
                ... on PRISMIC_Person {
                  name
                  job_title
                  profile_picture
                }
              }
            }
          }
          ... on PRISMIC_Case_studyBodyText {
            type
            primary {
              text
            }
          }
          ... on PRISMIC_Case_studyBodyVideo_embed {
              type
              primary {
                vimeo_id
              }
            }
          ... on PRISMIC_Case_studyBodyFull_width_image {
            type
            primary {
              caption
              full_width_image
              full_width_imageSharp {
                childImageSharp {
                  ...prismicGatsbyImageSharp
                }
              }
            }
          }
          ... on PRISMIC_Case_studyBodyFigure_block {
            type
            fields {
              figure
              label
            }
          }
        }
        relatedCaseStudies: related_case_studies {
          case_study {
            ... on PRISMIC_Case_study {
              ...prismicCaseStudyCardNoTitle
            }
          }
        }
      }
    }
  }
`

const CaseStudy = ({ data }) => {
  const nestedData = get(data, 'prismic.case_study')
  const authorImage = get(data, 'prismic.case_study.landscape_image.url')
  const lastPublicationDate = get(data, 'prismic.case_study._meta.lastPublicationDate')

  if (!nestedData) {
    return null
  }

  const { largeDeviceImage, smallDeviceImage, relatedCaseStudies, title } = nestedData
  const singleImage = get(data, 'prismic.case_study.landscape_image')

  const windowWidth = useWindowWidth()

  const sharpImage = windowWidth >= 767 ? largeDeviceImage : smallDeviceImage

  const sharedImage = sharpImage
    ? removeLeadingSlash(sharpImage.childImageSharp.fluid.src)
    : authorImage

  const titleString = get(title, '[0].text')

  const caseStudyList = relatedCaseStudies && relatedCaseStudies;

  let seoProps = getSeoProps(nestedData)

  seoProps = {
    title: seoProps.title || get(nestedData, 'title[0].text'),
    description: seoProps.description || get(nestedData, 'summary_text[0].text'),
    imageSrc: sharedImage,
    published: lastPublicationDate,
  }

  return (
    <Fragment>
      <SEO {...seoProps} />
      <CaseStudyUI
        {...nestedData}
        sharpImage={sharpImage}
        image={singleImage}
        title={titleString}
        caseStudyList={caseStudyList}
      />
    </Fragment>
  )
}
CaseStudy.fragments = [prismicCaseStudyCardNoTitle]
export default CaseStudy

CaseStudy.query = query
