import React from 'react'
import Img from 'gatsby-image'
import css from './LazyImage.module.css'

const LazyImage = ({ src, aspectRatio = '16by9' }) => {
  const Image =
    typeof src === 'object' ? (
      <Img fluid={src} className={css.lazyImage} />
    ) : (
      <div style={{ backgroundImage: `url('${src}')` }} className={css.lazyImage} />
    )

  return (
    <div className={css.imageContainer} data-aspect={aspectRatio}>
      {Image}
    </div>
  )
}

export default LazyImage
