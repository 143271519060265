import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSwipeable } from 'react-swipeable'
import Img from 'gatsby-image'
import get from 'lodash/get'
import { RichText } from 'prismic-reactjs'
import { Controls, BlockReveal, InstagramCaption } from '@components'
import site from '@utils/site'
import linkResolver from '@utils/linkResolver'
import css from './Values.module.css'

/**
 * @summary Values component
 * @property {string} title
 * @property {object} values
 * @property {string} values.title -
 * @property {string} values.image-
 * @property {string} values.image.alt-
 * @property {string} values.image.copyright
 * @property {string} values.image.dimensions
 * @property {string} values.image.dimensions.height
 * @property {string} values.image.dimensions.width
 * @property {string} values.image.url
 * @property {string} values.image_caption
 * @property {string} values.image.url
 * @property {string} values.image.text[0].text
 * @property {string} values.image.text[0].type
 *
 */

const Values = ({ values, title }) => {
  const [position, setPosition] = useState(0)
  const limit = values.length
  const colours = site.getPrimaryColorsArray()
  const prev = () => {
    if (position === 0) return
    setPosition(position - 1)
  }
  const next = () => {
    if (position === limit) return
    setPosition(position + 1)
  }
  const handlers = useSwipeable({
    onSwipedLeft: () => next(),
    onSwipedRight: () => prev(),
  })
  const formatNumber = number => {
    return number.toString().length === 1 ? `0${number}` : number
  }
  return (
    <section className={`keyline ${css.values}`}>
      <h2>{title}</h2>
      <Controls position={position} limit={limit} prev={prev} next={next} mobileOnly />
      <div
        {...handlers}
        className={css.values__wrapper}
        style={{ transform: `translateX(-${position * 100}%)` }}
      >
        {values.map((value, i) => {
          const sharpImage = get(value, 'imageSharp.childImageSharp.fluid')
          const color = colours[i % 3]
          const direction = i % 2 === 0 ? 'right' : 'left'
          return (
            <div className={css.value} key={i} data-current={i === position}>
              <div className={css.value__text}>
                <div className={css.value__index}>{`${formatNumber(i + 1)}/`}</div>
                <h3 className={css.value__title}>{value.title}</h3>
                <RichText
                  render={value.text}
                  linkResolver={linkResolver}
                  className={css.value__text}
                />
              </div>
              <div className={css.value__image}>
                <BlockReveal direction={direction} color={color}>
                  {sharpImage ? (
                    <Img fluid={sharpImage} alt={value.image_caption} />
                  ) : (
                    <img src={value.image.url} alt={value.image_caption} />
                  )}
                </BlockReveal>
                <InstagramCaption text={value.image_caption} />
              </div>
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default Values

const value = PropTypes.shape({
  title: PropTypes.string,
  text: PropTypes.array,
  image: PropTypes.shape({
    url: PropTypes.string,
  }),
  caption: PropTypes.string,
})

Values.propTypes = {
  values: PropTypes.arrayOf(value).isRequired,
  title: PropTypes.string.isRequired,
}
