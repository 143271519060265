import React from 'react'
import PropTypes from 'prop-types'
import { RichText } from '@components'
import classnames from 'classnames'
import { useInView } from 'react-intersection-observer'
import linkResolver from '@utils/linkResolver'
import css from './Steps.module.css'

/**
 * @summary Steps component
 * @property {string} title - Title
 * @property {string} intro - Intro text
 * @property {array} steps - Array of steps
 * @property {object} steps.step
 * @property {string} steps.step.title - Step title
 * @property {string} steps.step.text - Step text
 */

const Steps = ({ steps, title, intro, order }) => {
  return (
    <section
      className={classnames({
        [`keyline`]: true,
        [`${css.steps}`]: true,
        [`${css.isNumbered}`]: order,
        [`${css.notNumbered}`]: !order,
        [`${css.moreThanFive}`]: steps && steps.length > 5,
      })}
    >
      <div className={css.steps__intro}>
        <h2>{title}</h2>
        {intro && <p>{intro}</p>}
      </div>
      <div className={css.steps__steps}>
        {steps.map((step, i) => (
          <div className={css.step} key={i}>
            {order && <span className={css.step__index}>{i + 1}</span>}
            <div className={css.step__text}>
              <h3>{step.title}</h3>
              {step.text && <RichText render={step.text} linkResolver={linkResolver} />}
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default Steps

const step = PropTypes.shape({
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
})

Steps.propTypes = {
  steps: PropTypes.arrayOf(step).isRequired,
  title: PropTypes.string.isRequired,
  intro: PropTypes.string,
}

Steps.defaultProps = {
  intro: null,
}
