import React from 'react'
import { RightTransitionLink } from '@components'
import css from './Banner.module.css'

/**
 * @param {object} props Object containg props passed down to component
 * @param {string} props.title - title of Banner
 * @param {string} props.text - text for banner
 * @param {string} props.ctaText - text for banner link
 * @param {string} props.ctaUrl - url for banner link
 * @param {string} props.colorWay - colorway for the component, defaults to slate
 *
 */

const Banner = ({ title, text, ctaText, ctaUrl, children, colorWay = 'slate' }) => {
  return (
    <div className={css.banner} data-colorway={colorWay} data-component="Banner">
      <h2 className={css.banner__title}>{title}</h2>
      {children && (
        <div className={css.banner__cols}>
          {children.map((child, i) => {
            return <div className={css.banner__col} key={i}>{child}</div>
          })}
        </div>
      )}
      {!children && (
        <div className={css.banner__cols}>
          <div className={`${css.banner__col} ${css.banner__colWide}`}>
            {text}
          </div>
          <div className={`${css.banner__col} ${css.banner__colRight}`}>
            {ctaUrl && ctaText && (
              <RightTransitionLink to={ctaUrl} className={css.banner__cta}>
                {ctaText}
              </RightTransitionLink>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default Banner
