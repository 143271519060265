import React from 'react'
import { SubMenu, WrapperWithMargin } from '@components'
import css from './BlogFilter.module.css'

const BlogFilter = () => {
  const filterOptions = [
    { name: 'Everything', link: '/blog' },
    { name: 'News', link: '/blog/news' },
    { name: 'Opinion', link: '/blog/opinion' },
    { name: 'Reports', link: '/blog/reports' },
    { name: 'Tech', link: '/blog/tech' }
  ]

  return <div className={css.BlogFilter}><SubMenu items={filterOptions} urlPrefix="" /></div>
}

export default BlogFilter
