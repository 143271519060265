import React from 'react'
import css from './SocialLinks.module.css'

const SocialLinks = () => {
  return (
    <ul className={css.socialLinks}>
      <li>
        <a href='https://www.linkedin.com/company/greatstate/' data-platform='linkedin'>
          Linkedin
        </a>
      </li>
      <li>
        <a href='https://www.instagram.com/wearegreatstate/' data-platform='instagram'>
          Instagram
        </a>
      </li>
      <li>
        <a href='http://twitter.com/@WeAreGreatState' data-platform='twitter'>
          Twitter
        </a>
      </li>
    </ul>
  )
}

export default SocialLinks