import React, { Fragment, useState } from 'react'
import { StaticQuery, Link, graphql } from 'gatsby'
import { prismicHTML } from '@utils/helpers'
import { ScreenReaderText, SocialIconLinkList, Underline, BackToTop } from '@components'
import useCopyTracking from '@hooks/useCopyTracking'
import css from './Footer.module.css'

const Footer = ({ location }) => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        prismic {
          allOffice_detailss {
            edges {
              node {
                ...prismicOfficeDetailsFields
              }
            }
          }
          allOptionss {
            edges {
              node {
                links {
                  link {
                    _linkType
                    ... on PRISMIC__ExternalLink {
                      target
                      _linkType
                      url
                    }
                    ... on PRISMIC__FileLink {
                      _linkType
                      url
                    }
                  }
                  link_title
                  text
                }
              }
            }
          }
        }
      }
    `}
    render={({ prismic }) => {
      const offices = prismic.allOffice_detailss.edges
      const options = prismic.allOptionss.edges[0].node
      return <FooterContent {...{ location, offices, options }} />
    }}
  />
)

function FooterContent({ location, offices, options }) {
  const [showDogPolicyLink, setShowDogPolicyLink] = useState(false)
  const currentYear = new Date().getFullYear()

  const officeEls = offices.map((edge, i) => {
    const office = edge.node
    const [emailRef] = useCopyTracking()
    return (
      <div key={i} className={css.office}>
        <h3 className={css.officeName}>{office.office_name[0].text}</h3>

        <div
          className={css.officeAddress}
          dangerouslySetInnerHTML={{ __html: prismicHTML(office.address) }}
        />

        <a className={css.officeTelephone} href={`tel:${office.telephone_number[0].text}`}>
          {office.telephone_number_display[0].text}
        </a>

        <div className={css.officeLink}>
          <a
            href={`mailto:${office.email[0].text}`}
            title={`Contact Great State ${office.office_name[0].text} Office`}
            ref={emailRef}
          >
            <Underline text={office.email[0].text} color="pink" />
          </a>
        </div>

        <div className={css.officeLink}>
          <a
            href={office.google_map_link.url}
            target="_blank"
            rel="noopener noreferrer"
            title={`View Great State ${office.office_name[0].text} Office on Google Maps`}
          >
            <Underline text="Google map" color="pink" />
          </a>
        </div>

        {office.directions_link && 
          <div className={css.officeLink}>
            <a
              href={`/getting-here/${office.directions_link.meta.uid}`}
              rel="noopener noreferrer"
              title={`Getting to Great State ${office.office_name[0].text} Office`}
            >
              <Underline text="Getting here" color="pink" />
            </a>
          </div>
        }
      </div>
    )
  })

  let footerLinks
  if(options){
    footerLinks = options.links.map((link, i) => {
      const { link: theLink, link_title: linkTitle, text } = link
      if(!theLink.url) return null

      if(Object.prototype.hasOwnProperty.call(theLink, 'target')){
        return (
          <Link 
            key={`footerLink-${i}`}
            to={theLink.url} 
            title={linkTitle[0].text}
          >
            <Underline text={text[0].text} color="pink" />
          </Link>
        )
      }

      return (
        <a
          key={`footerLink-${i}`}
          href={theLink.url} 
          title={linkTitle[0].text}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Underline text={text[0].text} color="pink" />
        </a>
      )
    })
  }

  return (
    <footer className={css.footer}>

      {location.pathname.replace(/\//g, '') !== 'contact' && (
        <section className={css.offices}>
          <ScreenReaderText tag="h2" content="Contact Information" />
          {officeEls}
        </section>
      )}

      <section className={css.bottomBar}>
        <div className={css.legal}>
          <ScreenReaderText tag="h2" content="GreatState Legal Disclaimers" />
          <p className={css.copyright}>&copy; {currentYear} Great State</p>
          {footerLinks}
        </div>

        <div className={css.socialLinks}>
          <SocialIconLinkList />
        </div>
      </section>
      {location.pathname.replace(/\//g, '') !== 'contact' && (
        <div className={css.backToTop}>
          <BackToTop />
        </div>
      )}
    </footer>
  )
}

export default Footer
