import React, { Fragment } from 'react'
import {
  PageContainer,
  LazyImage,
  SliceGenerator,
  Intro,
  Breadcrumb,
  ArticleInfo,
  BlogArticleListBlock,
  WrapperWithMargin,
} from '@components'
import linkResolver from '@utils/linkResolver'

import css from './BlogArticle.module.css'

export default ({
  article_type,
  body,
  related_blogs,
  blogImages,
  content,
  date,
  title,
  author,
  readTime,
  programmingLanguage
}) => (
  <Fragment>
    <PageContainer linkText="Back to blog" linkUrl="/blog">
      <Breadcrumb path={article_type} />
      <Intro title={title} linkResolver={linkResolver} className={css.blogIntro} />
      <WrapperWithMargin marginValue="-2.5rem 0 0 0">
        <ArticleInfo author={author} date={date} readTime={readTime} />
      </WrapperWithMargin>
      <div className={css.blogImage}>
        <LazyImage src={blogImages} className={css.blogImage} />
      </div>
      <div className={css.sliced}>{body && <SliceGenerator slices={body} programmingLanguage={programmingLanguage} />}</div>
    </PageContainer>
    {related_blogs && related_blogs[0] && related_blogs[0].blog && (
      <BlogArticleListBlock blogArticleList={related_blogs} title="Related articles" />
    )}
  </Fragment>
)
