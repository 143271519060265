import React, { useState, useEffect, Fragment } from 'react'
import scrollToElement from 'scroll-to-element'
import { useInView, InView } from 'react-intersection-observer'
import { SEO } from '@templates'
import get from 'lodash/get'
import { Values, JobList, Banner, Quote, Intro, Benefits, Quotes, MailingListSignup } from '@components'
import { getSeoProps } from '@data/seo'
import css from './Careers.module.css'

const getIntroData = prismicData => ({
  title: get(prismicData, 'allCareers_pages.edges[0].node.title', []),
  text: get(prismicData, 'allCareers_pages.edges[0].node.text', []),
})

const getBannerData = prismicData => ({
  title: get(prismicData, 'allCareers_pages.edges[0].node.bannerTitle[0].text'),
  text: get(prismicData, 'allCareers_pages.edges[0].node.bannerText[0].text'),
})

const getQuoteData = prismicData => ({
  text: get(prismicData, 'allCareers_pages.edges[0].node.quoteText[0].text'),
  author: get(prismicData, 'allCareers_pages.edges[0].node.person', {}),
})

const mapOfficeData = (location, jobListings, officeData) => {
  const filteredJobs = jobListings.filter(job => job.location.name.includes(location))
  const mappedJobs = filteredJobs.map(job => {
    const summaryData = job.metadata.find(data => data.name === 'Summary')
    return {
      ...job,
      department: get(job, 'departments[0].name', ''),
      summary: summaryData && summaryData.value,
    }
  })

  return {
    tabTitle: location,
    heading: location,
    jobs: mappedJobs,
    image: officeData.images[0],
    caption: get(officeData, 'caption[0].text', ''),
    intro: get(officeData, 'description[0].text', ''),
  }
}

const Careers = ({ data: { prismic, allGreenhouseJob } }) => {
  const nestedData = get(prismic, 'allCareers_pages.edges[0].node')

  if (!nestedData) {
    return null
  }

  const seoProps = getSeoProps(nestedData)

  const introData = getIntroData(prismic)

  const quoteData = getQuoteData(prismic)

  const mailingListProps = get(nestedData, 'mailinglist')

  const bannerData = getBannerData(prismic)

  const jobListings = get(allGreenhouseJob, 'nodes', [])

  const bristolOfficeData = get(prismic, 'allContact_pages.edges[0].node.office_one')

  const bristolOfficeProps = mapOfficeData('Bristol', jobListings, bristolOfficeData)

  const values = get(prismic, 'allValuess.edges[0].node.values', [])
  const valuesTitle = get(prismic, 'allValuess.edges[0].node.heading[0].text', 'Our values')

  const benefits = get(prismic, 'allBenefitss.edges[0].node.benefits', [])
  const benefitsTitle = get(prismic, 'allBenefitss.edges[0].node.heading[0].text', 'Our benefits')

  const ourPeopleQuotes = get(prismic, 'allOur_peoples.edges[0].node.quotes', [])
  const ourPeopleTitle = get(prismic, 'allOur_peoples.edges[0].node.heading[0].text', 'Our people')
  const [isHidden, setIsHidden] = useState(false)

  const checkJobs = event => {
    const jobInView = document.querySelector('.jobs-in-view')
    const jobsEl = document.querySelector('#jobs')
    if (jobInView !== null || window.scrollY > jobsEl.offsetTop + jobsEl.offsetHeight) {
      setIsHidden(true)
    } else {
      setIsHidden(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', checkJobs)
    return () => {
      window.removeEventListener('scroll', checkJobs)
    }
  }, [isHidden])

  const cta = {
    text: 'View job roles',
    direction: 'down',
    action: e => {
      e.preventDefault()

      scrollToElement('#jobs', {
        duration: 800,
        offset: -Math.abs(document.querySelector('header').offsetHeight),
      })
    },
    isHidden,
  }
  const [ref, inView] = useInView({
    threshold: 1,
    triggerOnce: true,
  })

  const mutQuoteCta = {
    ...cta,
    direction: 'up'
  }

  return (
    <Fragment>
      <SEO {...seoProps} />

      <Intro {...introData} />

      <InView
        onChange={(isinView, elem) =>
          isinView
            ? elem.target.classList.add('jobs-in-view')
            : elem.target.classList.remove('jobs-in-view')
        }
      >
        <div className={`keyline ${css.careers__section}`} id="jobs">
          <h2>Available job roles</h2>
          <JobList {...bristolOfficeProps} />
        </div>
      </InView>

      <Quotes title={ourPeopleTitle} quotes={ourPeopleQuotes} />

      <Values title={valuesTitle} values={values} />

      <Quote {...quoteData} cta={mutQuoteCta} />
      <div ref={ref}>{inView && <Benefits title={benefitsTitle} benefits={benefits} />}</div>


      <Banner
        {...bannerData}
        ctaText="Get in touch"
        ctaUrl="mailto:careers@greatstate.co?subject=Freelance"
      />

      <MailingListSignup {...mailingListProps} />
    </Fragment>
  )
}

export default Careers
