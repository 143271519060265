import React from 'react'
import get from 'lodash/get'
import { Image, GreatStateCaption } from '@components'
import css from './FullWidthImage.module.css'

export default ({ caption, content_width_image, content_width_imageSharp, hasMargin }) => {
  const sharpImage = get(content_width_imageSharp, 'childImageSharp.fluid')

  const marginClass = hasMargin ? css.hasMargin : ''

  return (
    <div className={marginClass}>
      <Image className={css.fullWidthImage} sharpImage={sharpImage} image={content_width_image} />
      <GreatStateCaption text={caption} />
    </div>
  )
}
