import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import { get } from 'lodash'
import decode from 'decode-html'
import {
  Avatar,
  IntroCopy,
  SmallLabel,
  Underline,
  PageContainer,
  GreenhouseJobBoardForm,
} from '@components/'
import css from './JobPost.module.css'

export const query = graphql`
  query PrismicGetVacancyAuthor {
    prismic {
      allPersons(where: { name: "Victoria James" }) {
        edges {
          node {
            name
            job_title
            profile_picture
            _linkType
          }
        }
      }
    }
  }
`

const JobListing = ({ data, pageContext }) => {
  const {
    gh_Id: GHID,
    title,
    content,
    openDate,
    location,
    advertisedSalary,
    department,
    fields,
  } = pageContext
  const { prismic } = data
  const nestedPersonData = get(prismic, 'allPersons.edges[0].node', {})
  const avatarImage =
    get(nestedPersonData, 'profile_pictureSharp.childImageSharp.fixed.src') ||
    get(nestedPersonData, 'profile_picture.url')

  return (
    <PageContainer noMarginBottom linkText="Back to careers" linkUrl="/careers">
      <SmallLabel text={['careers', department]} />
      <h1 className={css.job__title}>{title}</h1>
      <div className={css.job__info}>
        <p className={css.job__salary}>Salary: {advertisedSalary || 'Competitive'}</p>
        <p className={css.job__location}>
          Location:&nbsp;
          <Underline cutIn color="pink">
            <strong>{location || 'London / Bristol'}</strong>
          </Underline>
        </p>
        <div className={css.job__poster}>
          <Avatar src={avatarImage} />
          <div className={css.job__posterInfo}>
            {nestedPersonData.job_title}, {nestedPersonData.name}
            {openDate && <Fragment> &bull; {openDate}</Fragment>}
          </div>
        </div>
      </div>
      <div className="keyline" />

      <IntroCopy>
        <div dangerouslySetInnerHTML={{ __html: decode(content) }} />
      </IntroCopy>

      <div className={`keyline ${css.job__section}`}>
        <h2>Apply</h2>
        <p>Please complete all fields unless stated as optional.</p>
        <GreenhouseJobBoardForm greenhouseJobId={GHID} fields={fields} />
      </div>
    </PageContainer>
  )
}

export default JobListing

JobListing.query = query
