import React, {Fragment} from 'react'
import css from './Breadcrumb.module.css'

const Breadcrumb = ({ path }) => {
  if (!path) {
    return null
  }

  const pathParts = path.split('/')

  return (
    <div className={css.breadcrumb}>
      {pathParts.map((part, i) => {
        return (
          <Fragment key={i}>
            <span className={css.breadcrumb__item}>{part}</span>
            {i < pathParts.length - 1 && <span className={css.breadcrumb__sep}> / </span>}
          </Fragment>
        )
      })}
    </div>
  )
}

export default Breadcrumb
