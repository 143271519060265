import React from 'react'
import css from './SocialShareLink.module.css'

/**
 * @param {object} props Object containg props passed down to component
 * @param {string} props.platform The social media platform you want to link to; accepted values: ['twitter', 'instagram', 'linkedin']
 */
const SocialShareLink = ({ platform, url}) => {

  const encodedURL = encodeURIComponent(url)

  const social = {
    twitter: {
      name: 'Twitter',
      url: `https://twitter.com/intent/tweet?text=${encodedURL}`,
    },
    facebook: {
      name: 'Facebook',
      url: `http://www.facebook.com/sharer/sharer.php?u=${encodedURL}`,
    },
    linkedin: {
      name: 'LinkedIn',
      url: `http://www.linkedin.com/shareArticle?mini=true&summary=&source=e3+Media&url=${encodedURL}`,
    },
  }

  const data = social[platform]
  return (
    <a href={data.url} className={css.socialShareLink} data-platform={data.name.toLowerCase()} target="_blank" rel="noopener noreferrer">
      {data.name}
    </a>
  )
}

export default SocialShareLink
