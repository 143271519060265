import React from 'react'
import css from './Heading.module.css'

const Heading = ({ text }) => {
  if (!text) {
    return null
  }
  return (
    <h2 className={css.heading}>
      <span>{text}</span>
    </h2>
  )
}

export default Heading
