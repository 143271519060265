import React from 'react'
import Anilink from 'gatsby-plugin-transition-link/AniLink'
import { colours } from '@utils/site'

const isUrlSuitableForTransition = url => {
  if (url && url.includes('mailto')) {
    return false
  }

  return true
}

export const UpTransitionLink = props => {
  const { to } = props
  if (isUrlSuitableForTransition(to)) {
    return <Anilink {...props} cover direction="up" bg={colours.slate} />
  }

  return <RegularLink {...props} />
}

export const RightTransitionLink = props => {
  const { to } = props
  if (isUrlSuitableForTransition(to)) {
    return <Anilink {...props} cover direction="right" bg={colours.slate} />
  }

  return <RegularLink {...props} />
}

export const LeftTransitionLink = props => {
  const { to } = props
  if (isUrlSuitableForTransition(to)) {
    return <Anilink {...props} cover direction="left" bg={colours.slate} />
  }

  return <RegularLink {...props} />
}

const RegularLink = ({ to, className, children }) => (
  <a href={to} className={className}>
    {children}
  </a>
)
