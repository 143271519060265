import React from 'react'
import { RichText } from '@components'
import css from './ContentList.module.css'

const ContentList = (props) => {
  const { items } = props
  if(!items.length) return null

  return (
    <section className={css.contentList} data-component="content-list">
      {items.map((item, index) => (
        <article
          key={`content__item_${index}`}
          className={css.contentListContainer__item}
        >
          <RichText render={item.heading} />
          <RichText render={item.content} />
        </article>
      ))}
    </section>
  )
}

export default ContentList