const site = require('./site')

const linkResolver = doc => {
  const slugPath = site.getGeneratePageSlugPath(doc.type)

  // No slug path then its a landing page
  if(slugPath === ''){
    return site.getGenerateLandingPages(doc.type)
  }

  return `${slugPath}/${doc.uid}`
}

module.exports = linkResolver
