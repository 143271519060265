import React from 'react'
import { FloatingLabelInput } from '@components'
import { RichText } from 'prismic-reactjs'
import linkResolver from '@utils/linkResolver'
import css from './FormSignup.module.css'
import FormError from './FormError'

const FormSignup = ({
  handleEmailChange,
  handleSubmit,
  handleTermsChange,
  title,
  intro,
  email,
  terms,
  status,
  action
}) => {
  const { field, msg, result } = status
  const hasError = fieldName => result === 'error' && field === fieldName

  return (
    <form noValidate onSubmit={handleSubmit} action={action} method={action ? 'POST' : undefined}>
      <h2 className={css.mailingListSignup__title}>{title[0].text}</h2>

      {intro && (
        <div className={css.mailingListSignup__intro}>
          <RichText render={intro} linkResolver={linkResolver} />
        </div>
      )}

      <div className={css.mailingListSignup__wrapper}>
        <div className={css.mailingListSignup__email}>
          {hasError('email') && <FormError msg={msg} />}
          <FloatingLabelInput
            type="text"
            value={email}
            label="Email Address"
            id="test1"
            autoComplete="off"
            onChange={handleEmailChange}
            name="EMAIL"
          />
        </div>

        <div className={css.mailingListSignup__field}>
          {hasError('terms') && <FormError msg={msg} />}
          <div className={css.mailingListSignup__checkbox}>
            <input
              type="checkbox"
              name="mailingListTerms"
              id="mailingListTerms"
              checked={terms}
              onChange={handleTermsChange}
            />
            <label htmlFor="mailingListTerms">
              I have read and agree to the <a href="/privacy">Privacy Policy</a>
            </label>
          </div>
        </div>

        <button type="submit" className={css.mailingListSignup__submit}>
          Submit
        </button>
      </div>
    </form>
  )
}

export default FormSignup
