import React from 'react'
import styled from 'styled-components'
import Adidas from '@images/svg/client/Adidas.svg'
import ALD from '@images/svg/client/ALD.svg'
import Arla from '@images/svg/client/Arla.svg'
import Army from '@images/svg/client/Army.svg'
import ARUK from '@images/svg/client/ARUK.svg'
import ASOS from '@images/svg/client/ASOS.svg'
import AstonVilla from '@images/svg/client/AstonVilla.svg'
import Aviva from '@images/svg/client/Aviva.svg'
import Axa from '@images/svg/client/Axa.svg'
import Barclays from '@images/svg/client/Barclays.svg'
import BHS from '@images/svg/client/BHS.svg'
import BirminghamCityUni from '@images/svg/client/BirminghamCityUni.svg'
import BirminghamUni from '@images/svg/client/BirminghamUni.svg'
import BP from '@images/svg/client/BP.svg'
import BristolAirport from '@images/svg/client/BristolAirport.svg'
import BritishLegion from '@images/svg/client/BritishLegion.svg'
import CACI from '@images/svg/client/CACI.svg'
import Clarks from '@images/svg/client/Clarks.svg'
import DavidLloyd from '@images/svg/client/DavidLLoyd.svg'
import Deliveroo from '@images/svg/client/Deliveroo.svg'
import EnvironmentAgency from '@images/svg/client/EnvironmentAgency.svg'
import GoCompare from '@images/svg/client/GoCompare.svg'
import Grenadier from '@images/svg/client/Grenadier.svg'
import GSK from '@images/svg/client/GSK.svg'
import Honda from '@images/svg/client/Honda.svg'
import HRP from '@images/svg/client/HRP.svg'
import Ineos from '@images/svg/client/Ineos.svg'
import Jaguar from '@images/svg/client/Jaguar.svg'
import Kia from '@images/svg/client/Kia.svg'
import Landrover from '@images/svg/client/Landrover.svg'
import LFB from '@images/svg/client/LFB.svg'
import MinistryOfDefence from '@images/svg/client/MinistryOfDefence.svg'
import Mondelez from '@images/svg/client/Mondelez.svg'
import NationalTrust from '@images/svg/client/NationalTrust.svg'
import OfficeForStudents from '@images/svg/client/OfficeForStudents.svg'
import OkeyDokey from '@images/svg/client/OkeyDokey.svg'
import Orange from '@images/svg/client/Orange.svg'
import Pensions from '@images/svg/client/Pensions.svg'
import Principality from '@images/svg/client/Principality.svg'
import Prisons from '@images/svg/client/Prisons.svg'
import Prudential from '@images/svg/client/Prudential.svg'
import Pukka from '@images/svg/client/Pukka.svg'
import Puma from '@images/svg/client/Puma.svg'
import Quorn from '@images/svg/client/Quorn.svg'
import RAF from '@images/svg/client/RAF.svg'
import RNLI from '@images/svg/client/RNLI.svg'
import RoyalNavy from '@images/svg/client/RoyalNavy.svg'
import RoyalMarines from '@images/svg/client/RoyalMarines.svg'
import SABrains from '@images/svg/client/SA-Brains.svg'
import Sanofi from '@images/svg/client/Sanofi.svg'
import SpiraxSarco from '@images/svg/client/SpiraxSarco.svg'
import TVFTS from '@images/svg/client/TVFTS.svg'
import UNHCR from '@images/svg/client/UNHCR.svg'
import Unilever from '@images/svg/client/Unilever.svg'
import UOY from '@images/svg/client/UOY.svg'
import UWE from '@images/svg/client/UWE.svg'
import VersusArthritis from '@images/svg/client/VersusArthritis.svg'
import WestBrom from '@images/svg/client/WestBrom.svg'
import WMCA from '@images/svg/client/WMCA.svg'
import Yodel from '@images/svg/client/Yodel.svg'

const LogoSvg = ({ client }) => {
  switch (client) {
    case 'Adidas':
      return <Adidas />
    case 'ALD':
      return <ALD />
    case 'Arla':
      return <Arla />
    case 'Army':
      return <Army />
    case 'ARUK':
      return <ARUK />
    case 'ASOS':
      return <ASOS />
    case 'Aston Villa':
      return <AstonVilla />
    case 'Aviva':
      return <Aviva />
    case 'Axa':
      return <Axa />
    case 'Barclays':
      return <Barclays />
    case 'BHS':
      return <BHS />
    case 'BirminghamCityUni':
      return <BirminghamCityUni />
    case 'BirminghamUni':
      return <BirminghamUni />
    case 'BP':
      return <BP />
    case 'Bristol Airport':
      return <BristolAirport />
    case 'British Legion':
      return <BritishLegion />
    case 'CACI':
      return <CACI />
    case 'Clarks':
      return <Clarks />
    case 'David Lloyd':
      return <DavidLloyd />
    case 'Deliveroo':
      return <Deliveroo />
    case 'Environment Agency':
      return <EnvironmentAgency />
    case 'Go Compare':
      return <GoCompare />
    case 'Grenadier':
      return <Grenadier />
    case 'GSK':
      return <GSK />
    case 'Ineos':
      return <Ineos />
    case 'Jaguar':
      return <Jaguar />
    case 'Kia':
      return <Kia />
    case 'HRP':
      return <HRP />
    case 'Honda':
      return <Honda />
    case 'Landrover':
      return <Landrover />
    case 'LFB':
      return <LFB />
    case 'Mondelez':
      return <Mondelez />
    case 'Ministry Of Defence':
      return <MinistryOfDefence />
    case 'National Trust':
      return <NationalTrust />
    case 'Office For Students':
      return <OfficeForStudents />
    case 'Okey Dokey':
      return <OkeyDokey />
    case 'Orange':
      return <Orange />
    case 'Pensions':
      return <Pensions />
    case 'Principality':
      return <Principality />
    case 'Prisons':
      return <Prisons />
    case 'Prudential':
      return <Prudential />
    case 'Pukka':
      return <Pukka />
    case 'Puma':
      return <Puma />
    case 'Quorn':
      return <Quorn />
    case 'RAF':
      return <RAF />
    case 'RNLI':
      return <RNLI />
    case 'Royal Marines':
      return <RoyalMarines />
    case 'Royal Navy':
      return <RoyalNavy />
    case 'SA Brain':
      return <SABrains />
    case 'Sanofi':
      return <Sanofi />
    case 'Spirax Sarco':
      return <SpiraxSarco />
    case 'TVFTS':
      return <TVFTS />
    case 'UNHCR':
      return <UNHCR />
    case 'Unilever':
      return <Unilever />
    case 'UOY':
      return <UOY />
    case 'UWE':
      return <UWE />
    case 'Versus Arthritis':
      return <VersusArthritis />
    case 'West Brom':
      return <WestBrom />
    case 'WMCA':
      return <WMCA />
    case 'Yodel':
      return <Yodel />
    default:
      return null
  }
}

const StyledSpan = styled.span`
  path {
    fill: ${props => props.color};
  }
`

const ClientLogo = ({ client, color }) => (
  <StyledSpan {...{ color }}>
    <LogoSvg {...{ client }} />
  </StyledSpan>
)

export default ClientLogo
