import React from 'react'
import Anilink from 'gatsby-plugin-transition-link/AniLink'
import css from './BackLink.module.css'
import { LeftTransitionLink } from '@components'

const BackLink = ({ to, text = 'Back', className }) => (
  <LeftTransitionLink to={to} className={`${css.backlink} ${className}`}>
    {text}
  </LeftTransitionLink>
)

export default BackLink
