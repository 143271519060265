import { useState, useEffect, useRef } from 'react'

export default function useHover() {
  const [isHovered, setIsHovered] = useState(false)

  const ref = useRef(null)

  const handleMouseOver = () => setIsHovered(true)
  const handleMouseOut = () => setIsHovered(false)

  useEffect(() => {
    const node = ref.current
    if (node) {
      node.addEventListener('mouseover', handleMouseOver, { passive: true })
      node.addEventListener('mouseout', handleMouseOut, { passive: true })

      return () => {
        node.removeEventListener('mouseover', handleMouseOver)
        node.removeEventListener('mouseout', handleMouseOut)
      }
    }
  }, [ref.current])

  return [ref, isHovered]
}
