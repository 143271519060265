import React, { Fragment, useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { get, filter, each, orderBy } from 'lodash'
import { getSeoProps } from '@data/seo'
import { prismicSectorFields, prismicMailingListFields } from '@graphql/fragments'
import { SEO } from '@templates'
import { 
    ArticleSlider, 
    BackLink, 
    Banner, 
    ContentList,
    Intro, 
    MailingListSignup,
    QuoteTabs,
    Report,
    SliceGenerator, 
    SubMenu, 
} from '@components'
import css from './Sector.module.css'

export const query = graphql`
query PrismicSectorByUID($uid: String!) {
    prismic {
        allSectors {
          edges {
            node {
              name
              meta: _meta {
                uid
              }
            }
          }
        }
      sector(uid: $uid, lang: "en-gb") {
          ...prismicSectorFields
      }
    }
  }
`
const getQuoteTabsProps = data => {
  if (!data) return null

  const quoteTabsData = data.clientQuoteTabs
  if (!quoteTabsData) return null

  return {
    quotes: quoteTabsData.map(x => ({
      text: get(x, 'quote[0].text'),
      author: x.author,
      image: x.image,
      client: x.client,
      link: x.link
    })),
  }
}

const Sector = ({ data: { prismic } }) => {


  const [isClient, setIsClient] = useState(false)
  const [position, setPosition] = useState(0)

  // Identify client/server render to resolve Gatsby hydration issue
  useEffect(() => {
    setIsClient(true)
  }, [])

  const data = get(prismic, 'sector', {})

  if (!data) {
    return null
  }
  
  const serviceName = get(data, 'name[0].text', '')

  const introTitle1 = get(data, 'heading')
  const introTitle2 = get(data, 'heading_1')

  const introText1 = get(data, 'intro')
  const introText2 = get(data, 'intro_1')

  const body = get(data, 'body')
  const seoProps = getSeoProps(data)

  const allSectors = get(prismic, 'allSectors.edges', [])
  const articleList = data.slider_articles.map(x => x.article)

  const contentListing = get(data, 'content_listing')

  const quoteTabsProps = getQuoteTabsProps(data)

  const reportTitle = get(data, 'report_title', [])
  const reportCopy = get(data, 'report_copy', [])
  const reportFile = get(data, 'report_file', {})

  const sectorLinks = allSectors.map(s => {
    return { link: `/expertise/${s.node.meta.uid}`, name: s.node.name[0].text }
  })
  const sortedSectorLinks = orderBy(sectorLinks, ['name'], ['desc'])



  return (
    <Fragment>
      <SEO {...seoProps} />

      <div id="SubMenuWrapper" className={css.SectorMenu} key={isClient ? 'submenu-csr' : 'submenu-ssr'}>
        <SubMenu items={sortedSectorLinks} />
      </div>

      <Intro label={['expertise', serviceName]} title={introTitle1} text={introText1} key={isClient ? 'intro1-csr' : 'intro1-ssr'} />

      <QuoteTabs key={isClient ? 'quoteTabs-csr' : 'quoteTabs-ssr'} {...quoteTabsProps} />

      <Report
        title={reportTitle}
        text={reportCopy}
        ctaText="Download report"
        fileUrl={reportFile}
        key={isClient ? 'report-csr' : 'report-ssr'}
      />

      <Intro title={introTitle2} text={introText2} key={isClient ? 'intro2-csr' : 'intro2-ssr'} className="keyline" />

      <ContentList
        key={isClient ? 'contentList-csr' : 'contentList-ssr'} 
        items={contentListing}
      />

      <Slices
        body={body}
        sliceTypes={[
          'listing__case_study',
          'image_text_block'
        ]}
        renderMode={isClient ? 'csr' : 'ssr'}
      />

      <Banner
        title="Interested in working with us?"
        text="We help organisations grow by using data, insight and technology in new ways. Get in touch to find out how we can help your business."
        ctaText="hello@greatstate.co"
        ctaUrl="mailto:hello@greatstate.co"
        colorWay="grey"
        key={isClient ? 'banner-csr' : 'banner-ssr'}
      />

      <ArticleSlider key={isClient ? 'articleSlider-csr' : 'articleSlider-ssr'} title="Great Thinking" articles={articleList} />

    </Fragment>
  )
}

const Slices = ({ sliceTypes, body, key, renderMode }) => {
    const filterSlicesBySliceType = types => {
      return filter(body, b => {
        return types.indexOf(b.type) !== -1
      })
    }
  
    const addConfiguredSlicestoFilteredSlices = (types, filteredSlices) => {
      const configSlices = filter(types, s => {
        return s.type !== undefined
      })
      each(body, b => {
        const block = b
        each(configSlices, cs => {
          if (block.type === cs.type) {
            const data = { ...block, config: cs.config}
            filteredSlices.push(data)
          }
        })
      })
    }
  
    const filteredSlices = filterSlicesBySliceType(sliceTypes)
    addConfiguredSlicestoFilteredSlices(sliceTypes, filteredSlices)
  
    return <SliceGenerator {...key} {...{renderMode}} slices={filteredSlices} page='expertise'/>
  }

Sector.fragments = [prismicSectorFields]

export default Sector

Sector.query = query