import React, { Fragment } from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { Underline } from '@components'
import site from '@utils/site'
import { addLeadingZero } from '@utils/helpers'
import classnames from 'classnames'
import css from './ServiceList.module.css'

const ServiceList = ({ 
  services, 
  numberedList = true, 
  heading, 
  includeLinks = true, 
  defaultClasses = ['keyline', css.serviceListContainer] 
}) => {
  if(!services) return null

  return (
    <section className={classnames(defaultClasses)} data-component="ServiceList">
      {heading && <h2 className={css.serviceTitle}>{heading}</h2>}
      <div className={css.serviceList}>
        {services.map((service, i) => {
          const { _meta, name, summary } = service.service
          const linkContent = (
            <Fragment>
              {numberedList && <span className={css.service__index}>{`${addLeadingZero(i + 1)} /`}</span>}
              <h3>
                {includeLinks && <Underline text={name[0].text} cutIn />}
                {!includeLinks && name[0].text}
              </h3>
              <p>{summary[0].text}</p>
            </Fragment>
          )
          return (
            <div key={i} className={css.service}>
              {!includeLinks ? 
                linkContent
              :
                <AniLink
                  cover
                  duration={1}
                  bg={site.colours.slate}
                  direction="right"
                  to={`/services/service/${_meta.uid}`}
                >
                  {linkContent}
                </AniLink>
              }
            </div>
          )
        })}
      </div>
    </section>
  )
}


export default ServiceList
