import React, { Fragment } from 'react'
import useWindowWidth from '@hooks/useWindowWidth'
import {
  CaseStudyListBlock,
  Intro,
  SliceGenerator,
  PageContainer,
  FullWidthImage,
  BlockReveal,
} from '@components'
import css from './CaseStudy.module.css'

export default ({ image, sharpImage, caseStudyList, title, text, body }) => (
  <Fragment>
    <PageContainer linkText="Back to work" linkUrl="/work">
      <Intro largeText fullWidth label={['work', title]} text={text} title={`*${title}*`} customClasses={['customIntroCaseStudy']} className={css.caseStudyIntro}/>
      <ImageWrapper>
        <div className={css.FullWidthImage}>
          <FullWidthImage hasMargin full_width_image={image} full_width_imageSharp={sharpImage} />
        </div>
      </ImageWrapper>
      {body ? <SliceGenerator slices={body} /> : null}
    </PageContainer>
    <CaseStudyListBlock caseStudyList={caseStudyList} title="Related work" />
  </Fragment>
)

const ImageWrapper = ({ children }) => {
  const windowWidth = useWindowWidth()

  if (windowWidth >= 767) {
    return <Fragment>{children}</Fragment>
  }

  return <BlockReveal>{children}</BlockReveal>
}
