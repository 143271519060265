import React from 'react'
import styled from 'styled-components'
import site from '@utils/site'
import OneCaseStudyBlock from '../OneCaseStudyBlock/OneCaseStudyBlock'

export default ({
  top_left_case_study: topLeft,
  bottom_left_case_study: bottomLeft,
  top_right_case_study: topRight,
  bottom_right_case_study: bottomRight,
}) => {

  const colours = site.getPrimaryColorsArray()

  return (
    <SplitSection>
      <VerticalContainer>
        <OneCaseStudyBlock textHeight="18rem" {...topLeft} />
        <OneCaseStudyBlock textHeight="20rem" {...bottomLeft} />
      </VerticalContainer>
      <VerticalContainer>
        <OneCaseStudyBlock textHeight="18rem" {...topRight}/>
        <OneCaseStudyBlock textHeight="20rem" {...bottomRight} />
      </VerticalContainer>
    </SplitSection>
  )
}

const VerticalContainer = styled.div`
  flex: 1;
  flex-direction: column;
  display: flex;
  @media (max-width: 767px) {
    flex: 0 0 100%;
  }
  @media (min-width: 768px) {
    justify-content: space-between;
  }
  * {
    flex: initial;
  }

`
const SplitSection = styled.div`
  @media (min-width: 768px) {
    display: flex;
  }
`
