import React, { Fragment } from 'react'
import filter from 'lodash/filter'
import {
  CaseStudyListBlock,
  Intro,
  SliceGenerator,
  FullWidthImage,
  StickyLink,
  ContentWrapper,
} from '@components'
import css from './Partner.module.css'

export default ({ image, sharpImage, caseStudyList, title, text, body }) => (
  <Fragment>
    <div className={css.container}>
      <StickyLink text={'Back to services'} link={'/services'} />
      <ContentWrapper>
        <Intro largeText fullWidth text={title} title={text} />
        <FullWidthImage hasMargin full_width_image={image} full_width_imageSharp={sharpImage} />
        <Slices {...{ body }} sliceTypes={['text']} index={0} />
      </ContentWrapper>
    </div>
    <Slices {...{ body }} sliceTypes={['image_quotes']} />
    <div className={css.container}>
      <div className={css.stickyDummy}>
        {/* As the sticky link is intrgral to the layout we're forced to put this dummy here - the alternative
              being a duplication of its layout to get consistancy across the site */}
        <StickyLink text={'Back to services'} link={'/services'} />
      </div>
      <ContentWrapper>
        <Slices sliceTypes={['text']} index={1} />
      </ContentWrapper>
    </div>
    <CaseStudyListBlock caseStudyList={caseStudyList} title="Related work" />
    <Slices {...{ body }} sliceTypes={['article_slider_block', 'banner']} />
  </Fragment>
)

const Slices = ({ body, sliceTypes, index }) => {
  const filteredSlices = filter(body, b => {
    return sliceTypes.indexOf(b.type) !== -1
  })

  if (index !== undefined) {
    return filteredSlices[index] ? <SliceGenerator slices={[filteredSlices[index]]} /> : null
  }
  return <SliceGenerator slices={filteredSlices} />
}
