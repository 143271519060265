import React from 'react'
import OneCaseStudyBlock from '../OneCaseStudyBlock/OneCaseStudyBlock'
import CardListContainer from '../CardListContainer/CardListContainer'
import FourCaseStudyBlock from '../FourCaseStudyBlock/FourCaseStudyBlock'

export default ({ caseStudyList, title, page}) => {
  let csObj
  if(caseStudyList.length === 4 && page==='work'){
    csObj = {
      top_left_case_study: {...caseStudyList[0]},
      bottom_left_case_study: caseStudyList[2],
      top_right_case_study: caseStudyList[1],
      bottom_right_case_study: {...caseStudyList[3]}
    }
  }

  if (!caseStudyList || caseStudyList.length === 0) return false

  const showControls = (!!(page === 'expertise' && caseStudyList.length > 3))

  return (
    <CardListContainer title={title} hasControls={showControls} page={page}>
      {caseStudyList.length !== 4 && caseStudyList.map((caseStudy, index) => {
        if(!caseStudy) return false;
        const study = page === 'work' ? caseStudy : caseStudy.case_study;
        if (!study) return false;
        return <OneCaseStudyBlock key={`${index}_cs_block`} delay={index * 0.3} gridItem={index+1} {...study} {...{page}} />
      })}
      {caseStudyList.length === 4 && page === 'work' && <FourCaseStudyBlock key="fourcasestudyblock" {...csObj} />}
    </CardListContainer>
  )
}