import React, { Fragment } from 'react'
import Error from './Error'

export default ({ name, label, values, error, formRef, description }) => {
  if (!values || values.length === 0) {
    return null
  }

  const associateID = `checkbox-${name}`

  return (
    <fieldset>
      <legend>{label}</legend>
      {/* having the description set as HTML is a consequence of Greenhouse,
      a string will still work
    */}
      {description && <span dangerouslySetInnerHTML={{ __html: description }} />}
      {values.map((checkbox, index) => (
        <Fragment key={checkbox.value}>
          <input
            ref={formRef}
            type="checkbox"
            value={checkbox.value}
            id={associateID}
            name={`${name}[${index}]`}
          />
          <label htmlFor={associateID}>
            {checkbox.label}
          </label>
          <Error error={error} />
        </Fragment>
      ))}
    </fieldset>
  )
}
