import React from 'react'
import GatsbyImage from 'gatsby-image'
import css from './Image.module.css'

export default ({ sharpImage, fixedsharpImage, image }) => {
  //todo: ref ticket 19449 - ideally we'd prefer the GatsbyImage but issues with quality are forcing this temp preference of the original image.
  if (image) {
    return <img className={css.image} src={image.url} alt={image.alt} loading="lazy" />
  }

  if (sharpImage) {
    return <GatsbyImage className={css.image} fluid={sharpImage} />
  }

  if (fixedsharpImage) {
    return <GatsbyImage className={css.image} fixed={fixedsharpImage} />
  }

  return null
}
