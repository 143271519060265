import React from 'react'
import { RichText } from 'prismic-reactjs'
import linkResolver from '@utils/linkResolver'
import css from './Two-col.module.css'

const TwoCol = ({ leftcol, rightcol }) => {
  return (
    <div className={`${css.row} ${css.inner}`}>
      <div className={`${css.column} ${css.gap}`}>
        <RichText render={leftcol} className={css.column} linkResolver={linkResolver} />
      </div>
      <div className={css.column}>
        <RichText render={rightcol} className={css.column} linkResolver={linkResolver} />
      </div>
    </div>
  )
}

export default TwoCol
