import React from 'react'

import css from './PageContainer.module.css'
import { StickyLink, ContentWrapper } from '..'

export default ({ linkText, linkUrl, noMarginBottom, children }) => {
  return (
    <div className={css.pageContainer}>
      <StickyLink text={linkText} link={linkUrl} />
      <ContentWrapper {...{ noMarginBottom }}>{children}</ContentWrapper>
    </div>
  )
}
