import React, { Fragment } from 'react'
import { graphql, Link } from 'gatsby'
import get from 'lodash/get'
import { RichText } from 'prismic-reactjs'
import css from './JobListing.module.css'
import { removeLeadingSlash } from '@utils/helpers'
import { prismicJobListingFields } from '../../graphql/fragments'
import { SEO } from '..'
import { Breadcrumb, Avatar, IntroCopy, Underline, PageContainer } from '@components/'
import { formatDate } from '@utils/helpers'
import linkResolver from '@utils/linkResolver'
import { getSeoProps } from '@data/seo'

// TODO - Generic Copy block?
// TODO - CTA as component?
// TODO Layout as grid system?

export const query = graphql`
  query PrismicJobListingByUID($uid: String!) {
    prismic {
      job_listing(uid: $uid, lang: "en-gb") {
        ...prismicJobListingFields
      }
    }
  }
`

const JobListing = ({ data: { prismic } }) => {
  const data = get(prismic, 'job_listing', {})
  const jobTitle = get(data, 'job_title[0].text')
  const jobRef = get(data, 'ref')
  const jobDepartment = get(data, 'department.title[0].text')
  const sharpImage = get(data, 'advertiser.profile_pictureSharp.childImageSharp.fixed.src')
  const sharingImage = get(data, 'advertiser.profile_picture.url')
  const seoProps = getSeoProps(data)
  seoProps.title = seoProps.title || `Career: ${jobTitle}`
  seoProps.imageSrc = sharingImage && removeLeadingSlash(sharingImage)

  return (
    <Fragment>
      <SEO {...seoProps} />
      <PageContainer noMarginBottom linkText="Back to careers" linkUrl="/careers">
        <Breadcrumb path={`Careers/${jobDepartment}`} />
        <h1 className={css.job__title}>{get(data, 'job_title[0].text', 'not found')}</h1>
        <div className={css.job__info}>
          <p className={css.job__salary}>Salary: {get(data, 'salary[0].text', 'Competitive')}</p>
          <p className={css.job__location}>
            Location: <strong>{get(data, 'location', 'London / Bristol')}</strong>
          </p>
          <div className={css.job__poster}>
            <Avatar src={sharpImage || get(data, 'advertiser.profile_picture.url')} />
            <div className={css.job__posterInfo}>
              {get(data, 'advertiser.job_title')}, {get(data, 'advertiser.name')} &bull;{' '}
              {formatDate(get(data, 'date_added'))}
            </div>
          </div>
        </div>

        <div className={`keyline ${css.job__section}`}>
          <h2>About the role</h2>
          <IntroCopy>
            <RichText render={get(data, 'job_description')} linkResolver={linkResolver} />
          </IntroCopy>
        </div>

        <div className={`keyline ${css.job__section}`}>
          <h2>Responsibilities</h2>
          <IntroCopy>
            <RichText render={get(data, 'responsibilities')} linkResolver={linkResolver} />
          </IntroCopy>
        </div>

        <div className={`keyline ${css.job__section}`}>
          <h2>Requirements</h2>
          <IntroCopy>
            <RichText render={get(data, 'requirements')} linkResolver={linkResolver} />
          </IntroCopy>
        </div>

        <div className={`keyline ${css.job__section}`}>
          <h2>Apply</h2>
          <IntroCopy>
            <p>
              To apply, please email{' '}
              <a href={`mailto:careers@greatstate.co?subject=${jobTitle} (ref:${jobRef})`}>
                <Underline color="pink" text="careers@greatstate.co" />
              </a>{' '}
              with your CV and a short cover letter. If you&apos;d prefer a more informal
              conversation in the first instance, please get in touch using the same email address.
            </p>
          </IntroCopy>
          <div className={css.job__apply}>
            <a
              href={`mailto:careers@greatstate.co?subject=${jobTitle} (ref:${jobRef})`}
              className={css.job__applyLink}
            >
              <Underline color="transparent-to-pink" text="Apply now" />
            </a>
          </div>
        </div>
      </PageContainer>
    </Fragment>
  )
}

export default JobListing

JobListing.fragments = [prismicJobListingFields]

JobListing.query = query
