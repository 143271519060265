import React, { Fragment } from 'react'
import SocialIconLink from './SocialIconLink'

export default () => {
  const platformList = ['twitter', 'instagram', 'linkedin']
  return (
    <Fragment>
      {platformList.map((platform, index) => (
        <SocialIconLink key={`socialIconLink${index}`} platform={platform} />
      ))}
    </Fragment>
  )
}
