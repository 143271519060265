import React from 'react'
import css from './Avatar.module.css'

const Breadcrumb = ({src}) => {
  const bgImage = { backgroundImage: `url('${src}')` }
  return (
    <div className={css.avatar} style={bgImage} />
  )
}

export default Breadcrumb
