import React from 'react'
import css from './Underline.module.css'

const Underline = ({ text, color, shadow, cutIn, isActive, children}) => {

  const activeClass = isActive ? css.isActive : "";
  const className = `${css.underline} ${activeClass}`

  return (
    <span className={className} data-is-active={isActive} data-line-color={color} data-shadow-color={shadow} data-cut-in={cutIn}>
      <span>{text || children}</span>
    </span>
  )
}

export default Underline
