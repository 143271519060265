import React from 'react'
import { PolicyText, IntroCopy } from '@components'

const DogPolicy = () => (
  <PolicyText ariaLabel="Great State Sog Policy">
    <IntroCopy>
      <h1>Dog Policy</h1>
    </IntroCopy>
  </PolicyText>
)

export default DogPolicy
