import React, { useLayoutEffect, useState } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import classnames from 'classnames'
import { Container, Header, Footer, Takeover } from '@components'
import css from './Layout.module.css'

const Layout = ({ children, location }) => {
  const [navOpen, setNavOpen] = useState(false)
  const [showTakeover, setShowTakeover] = useState(false)

  const setTakeoverViewed = () => localStorage && localStorage.setItem('takeover-refresh', '1')
  const isTakeoverActive = `${process.env.GATSBY_SHOW_TAKEOVER}` === 'true'

  useLayoutEffect(() => {
    if (isTakeoverActive && !localStorage.getItem('takeover-refresh')) {
      setShowTakeover(true)
    }

    return () => {
      if (isTakeoverActive) {
        setTakeoverViewed()
      }
    }
  }, [])

  const hasTakeoverModal = isTakeoverActive && showTakeover

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          {hasTakeoverModal && (
            <Takeover
              setViewed={() => {
                setTakeoverViewed()
              }}
              setClosed={() => {
                setShowTakeover(false)
              }}
            />
          )}
          <div
            className={classnames({
              [`${css.navOpen}`]: navOpen || hasTakeoverModal,
              [`${css.hasTakeover}`]: hasTakeoverModal,
            })}
            aria-hidden={hasTakeoverModal}
          >
            <Header
              {...{ navOpen, setNavOpen }}
              siteTitle={data.site.siteMetadata.title}
              location={location}
            />
            <Container>
              <main className={css.main}>{children}</main>
              <Footer location={location} />
            </Container>
          </div>
        </>
      )}
    />
  )
}

export default Layout
