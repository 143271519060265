import React, { Fragment } from 'react'
import get from 'lodash/get'
import scrollToElement from 'scroll-to-element'
import { Intro, Banner, Quotes, ServiceList, SliceGenerator, MailingListSignup } from '@components'
import { SEO } from '@templates'
import { getSeoProps } from '@data/seo'

const Services = ({ data: { prismic } }) => {
  const nestedData = get(prismic, 'allServices_pages.edges[0].node')

  if (!nestedData) {
    return null
  }

  const introText = nestedData.intro_text
  const introHeading = nestedData.intro_heading
  const slices = nestedData.body
  const { services } = nestedData
  const seoProps = getSeoProps(nestedData)
  const mailingListProps = get(nestedData, 'mailinglist');
  
  const cta = {
    text: 'Get in touch',
    // url: "http://www.google.com",
    direction: 'down',
    action: e => {
      e.preventDefault()

      scrollToElement('div[class^="Banner-module"]', {
        duration: 800,
        offset: -Math.abs(document.querySelector('header').offsetHeight),
      })
    },
  }

  return (
    <Fragment>
      <SEO {...seoProps} />
      <Intro noMargin title={introHeading} text={introText} cta={cta} />
      <ServiceList heading="Our Services"  services={services} />
      <SliceGenerator slices={slices} />
      <MailingListSignup {...mailingListProps} />      
    </Fragment>
  )
}

export default Services
