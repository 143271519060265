import React, { useEffect, useState } from 'react'
import { useSwipeable } from 'react-swipeable'
import site from '@utils/site'
import { Controls, Heading } from '@components'
import useWindowWidth from '@hooks/useWindowWidth'
import '../../styles/global.css'
import css from './CardListContainer.module.css';

const colours = site.getPrimaryColorsArray()
/**
 * @summary Cards container component
 * @property {object} props
 * @property {string} children - list of cards
 * @property {string} title - title of container
 *
 */

export default ({ title, children, className, page = false, hasControls = false }) => {
  let childrenWithContent

  const [position, setPosition] = useState(0)

  const componentClasses = [css.listContainer]
  if(className) componentClasses.push(className)

  const filterChildren = !children[0] ? [children[1]] : children[0]
  if(!filterChildren) return false
  if(page){
    childrenWithContent = filterChildren.filter(child => child != null)
  } else {
    childrenWithContent = children.filter(child => child != null)
  }

  const limit =
    useWindowWidth() >= site.maxDeviceWidths.SMALL
      ? Math.ceil(childrenWithContent.length / 3)
      : childrenWithContent.length

  const childrenPerPage = useWindowWidth() >= site.maxDeviceWidths.SMALL ? 3 : 1

  if (position > limit) {
    setPosition(0)
  }

  const prev = () => {
    if (position === 0) return
    setPosition(position - 1)
  }
  const next = () => {
    if (position === limit) return
    setPosition(position + 1)
  }
  const handlers = useSwipeable({
    onSwipedLeft: () => next(),
    onSwipedRight: () => prev(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  })

  return (
    <section className={componentClasses.join(' ')} data-component="CardListContainer">
      {title && <Heading text={title} />}
      {childrenWithContent.length > childrenPerPage && (
        <Controls position={position} limit={limit} prev={prev} next={next} showPages />
      )}
      <div
        {...handlers}
        className={css.container}
        style={{ transform: `translateX(-${position * 100}%)` }}
      >
        {children}
      </div>
    </section>
  )
}