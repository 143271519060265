/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react'
import { Link } from 'gatsby'
import { PolicyText, IntroCopy, Underline } from '@components'
import { SEO } from '@templates'

const PrivacyPolicy = () => (
  <>
    <SEO title="Privacy Policy" description="Read our privacy policy" />
    <PolicyText ariaLabel="Great State Privacy Policy">
      <IntroCopy>
        <header>
          <h1>Privacy Policy</h1>
        </header>

        <section>
          <h2>1 Overview</h2>
          <p>
            <strong>
              Great State respect your privacy and are committed to protecting your personal data.
            </strong>
          </p>
          <p>
            This privacy policy (together with our Cookie Policy) explains how we look after your
            personal data when you visit our website (regardless of where you visit it from) and
            tell you about your privacy rights and how the law protects you.
          </p>
          <p>
            This website is not intended for children and we do not knowingly collect data relating
            to children.
          </p>
          <p>
            It is important that you read this privacy policy together with any other privacy notice
            or fair processing notice we may provide on specific occasions when we are collecting or
            processing personal data about you so that you are fully aware of how and why we are
            using your data. This privacy policy supplements the other notices and is not intended
            to override them.
          </p>
        </section>

        <section>
          <hgroup>
            <h2>2 Data Controller</h2>
            <h3>
              <span>2.1</span>
              About us
            </h3>
          </hgroup>
          <p>
            e3 Media Limited, referred to as “we”, “us” or “our” in this privacy policy, is the data
            controller and is responsible for your personal data. e3 Media Limited (trading as Great
            State) is a brand technology agency, operating to provide digital services and products
            on behalf of our clients. Our registered company number is 3328177.
          </p>
          <p>
            To learn more about us, please see&nbsp;
            <Link to="/services" title="Great State Services">
              <Underline text='"Services"' color="teal" />
            </Link>
            .
          </p>
          <p>
            If you have any questions about this privacy policy, including any requests to exercise
            your legal rights, please contact us using the details set out below. We welcome
            questions, comments or requests concerning this privacy policy.
          </p>
          <p>
            As a company we take Data Protection and Cyber Security extremely seriously, this is not
            solely the responsibility of the IT team, it is the responsibility of the business,
            supported by the Senior Management Team. To support our commitment, we have invested in
            the following accreditations (certificates can be provided on request):
          </p>
          <ul>
            <li>ISO 270001: 2013</li>
            <li>Cyber Essentials</li>
            <li>Cyber Essentials Plus</li>
          </ul>

          <h3>
            <span>2.2</span>
            Contact details
          </h3>
          <p>Our full details are:</p>
          <p>Full name of legal entity: e3 Media Limited, trading as Great State</p>
          <p>Email address: <a href="mailto:dataprotection@greatstate.co">dataprotection@greatstate.co</a></p>
          <p>Postal address: Runway East, 1 Victoria St, Redcliffe, Bristol, BS1 6AA</p>
          <p>Telephone: 01179 021 333</p>
          <p>
            You have the right to make a complaint at any time to the Information Commissioner’s
            Office (ICO), the UK supervisory authority for data protection issues (www.ico.org.uk).
            We would, however, appreciate the chance to deal with your concerns before you approach
            the ICO so please contact us in the first instance.
          </p>

          <h3>
            <span>2.3</span>
            Changes to the privacy policy and your duty to inform us of changes
          </h3>
          <p>
            We may make changes to this privacy policy at any time by sending an email to the last
            email address you gave us with the modified terms or by posting a copy of them on this
            website. Any changes will take effect 3 days after the date of our email or the date on
            which we post the changed policy on the site, whichever is the earlier. Your continued
            use of the site after that period expires means that you agree to be bound by the
            changed policy.
          </p>
          <p>
            It is important that the personal data we hold about you is accurate and current. Please
            keep us informed if your personal data changes during your relationship with us.
          </p>

          <h3>
            <span>2.4</span>
            Third-party links
          </h3>
          <p>
            This website may include links to third-party websites, plug-ins and applications.
            Clicking on those links or enabling those connections may allow third parties to collect
            or share data about you. We do not control these third-party websites and are not
            responsible for their privacy statements. When you leave our website, we encourage you
            to read the privacy policy of every website you visit.
          </p>
        </section>

        <section>
          <hgroup>
            <h2>3 What information do we collect about you?</h2>
            <h3>
              <span>3.1</span> Personal data
            </h3>
          </hgroup>
          <p>
            <strong>Personal data</strong>, or personal information, means any information about an
            individual from which that person can be identified. It does not include data where the
            identity has been removed (anonymous data). As part of Great State’s obligations to
            fulfil client contracts and operate as a business we collect various categories of
            Personal Data.
          </p>
          <p>
            We may collect, use, store and transfer different kinds of personal data about you which
            we have grouped together as follows:
          </p>
          <ul>
            <li>
              Identity Data includes first name, maiden name, last name, username or similar
              identifier, marital status, title, date of birth and gender.
            </li>
            <li>
              Contact Data includes billing address, delivery address, email address and telephone
              numbers.
            </li>
            <li>Financial Data includes bank account and payment card details.</li>
            <li>
              Transaction Data includes details about payments to and from you and other details of
              products and services you have purchased from us.
            </li>
            <li>
              Technical Data includes internet protocol (IP) address, your login data, browser type
              and version, time zone setting and location, browser plug-in types and versions,
              operating system and platform and other technology on the devices you use to access
              this website.
            </li>
            <li>
              Profile Data includes your interests, preferences, feedback and survey responses.
            </li>
            <li>
              Usage Data includes information about how you use our website, products and services.
            </li>
            <li>
              Marketing and Communications Data includes your preferences in receiving marketing
              from us and our third parties and your communication preferences.
            </li>
          </ul>
          <p>
            We also collect, use and share <strong>Aggregated Data</strong> such as statistical or
            demographic data for any purpose. Aggregated Data may be derived from your personal data
            but is not considered personal data in law as this data does not directly or indirectly
            reveal your identity. For example, we may aggregate your Usage Data to calculate the
            percentage of users accessing a specific website feature. However, if we combine or
            connect Aggregated Data with your personal data so that it can directly or indirectly
            identify you, we would still treat the combined data as personal data which will be used
            in accordance with this privacy policy.
          </p>
          <p>
            We do not collect any <strong>Special Categories of Personal Data</strong> about you
            (this includes details about your race or ethnicity, religious or philosophical beliefs,
            sex life, sexual orientation, political opinions, trade union membership, information
            about your health and genetic and biometric data). Nor do we collect any information
            about criminal convictions and offences.
          </p>

          <h3>
            <span>3.2</span> If you fail to provide personal data
          </h3>
          <p>
            Where we need to collect personal data by law, or under the terms of a contract we have
            with you and you fail to provide that data when requested, we may not be able to perform
            the contract we have or are trying to enter into with you (for example, to provide you
            with goods or services). In this case, we may have to cancel a service we are providing
            you with, but we will notify you if this is the case at the time.
          </p>
        </section>

        <section>
          <h2>4 How is your personal data collected?</h2>
          <ul>
            <li>
              <strong>Direct interactions.</strong> You may give us your Identity, Contact and
              Financial Data by submitting information online via this website, filling in forms or
              by corresponding with us by post, phone, email or otherwise. This includes personal
              data you provide when you:
              <ul>
                <li>subscribe to one of our services or publications;</li>
                <li>request marketing to be sent to you;</li>
                <li>enter a survey; or</li>
                <li>give us some feedback.</li>
              </ul>
            </li>
            <li>
              <strong>Automated technologies or interactions.</strong> As you interact with our
              website, we may automatically collect Technical Data about your equipment, browsing
              actions and patterns. We collect this personal data by using cookies and other similar
              technologies. Please see our cookie policy for further details.
            </li>
            <li>
              <strong>Third parties or publicly available sources.</strong> We may receive personal
              data about you from various third parties and public sources as set out below:
              <ul>
                <li>
                  Identity and Contact Data from publicly availably sources such as Companies House
                  and the Electoral Register.
                </li>
              </ul>
            </li>
          </ul>
        </section>

        <section>
          <hgroup>
            <h2>5 How to we use your personal data?</h2>
            <h3>
              <span>5.1</span> Legal basis for processing
            </h3>
          </hgroup>
          <p>
            We will only use your personal data when the law allows us to. Most commonly, we will
            use your personal data in the following circumstances:
          </p>
          <ul>
            <li>
              Where we need to perform the contract we are about to enter into or have entered into
              with you.
            </li>
            <li>
              Where it is necessary for our legitimate interests (or those of a third party) and
              your interests and fundamental rights do not override those interests. For example,
              where we are responding to you about your query.
            </li>
            <li>Where we need to comply with a legal or regulatory obligation.</li>
          </ul>
          <p>
            Generally, we do not rely on consent as a legal basis for processing your personal data
            other than in relation to sending third party direct marketing communications to you via
            email or text message. You have the right to withdraw consent to marketing at any time
            by contacting us.
          </p>

          <h3>
            <span>5.2</span> Ways in which we use your personal data
          </h3>
          <p>
            We have set out below, in a table format, a description of all the ways we plan to use
            your personal data, and which of the lawful bases we rely on to do so. We have also
            identified what our legitimate interests are where appropriate.
          </p>
          <p>
            Note that we may process your personal data on more than one lawful basis depending on
            the specific purpose for which we are using your data. Please contact us if you need
            details about the specific lawful basis we are relying on to process your personal data
            where more than one basis has been set out in the table below:
          </p>
          <table>
            <thead>
              <tr>
                <th>Purpose/Activity</th>
                <th>Type of data</th>
                <th>Lawful basis for processing including basis of legitimate interest</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>To register you as a new client</td>
                <td>
                  (a) Identity <br />
                  (b) Contact
                </td>
                <td>Performance of a contract with you</td>
              </tr>
              <tr>
                <td>
                  To provide our marketing services including: <br />
                  (a) Managing payments, fees and charges <br />
                  (b) Collecting and recovering money owed to us
                </td>
                <td>
                  (a) Identity <br />
                  (b) Contact <br />
                  (c) Financial <br />
                  (d) Transaction <br />
                  (e) Marketing and Communications
                </td>
                <td>
                  (a) Performance of a contract with you <br />
                  (b) Necessary for our legitimate interests (to recover debts due to us)
                </td>
              </tr>
              <tr>
                <td>
                  To manage our relationship with you which will include: <br />
                  (a) Notifying you about changes to our terms or privacy policy <br />
                  (b) Asking you to leave a review or take a survey
                </td>
                <td>
                  (a) Identity <br />
                  (b) Contact <br />
                  (c) Profile <br />
                  (d) Marketing and Communications
                </td>
                <td>
                  (a) Performance of a contract with you <br />
                  (b) Necessary to comply with a legal obligation <br />
                  (c) Necessary for our legitimate interests (to keep our records updated and to
                  study how customers use our products/services)
                </td>
              </tr>
              <tr>
                <td>
                  To administer and protect our business and this website (including
                  troubleshooting, data analysis, testing, system maintenance, support, reporting
                  and hosting of data)
                </td>
                <td>
                  (a) Identity <br />
                  (b) Contact <br />
                  (c) Technical
                </td>
                <td>
                  (a) Necessary for our legitimate interests (for running our business, provision of
                  administration and IT services, network security, to prevent fraud and in the
                  context of a business reorganisation or group restructuring exercise) <br />
                  (b) Necessary to comply with a legal obligation
                </td>
              </tr>
              <tr>
                <td>
                  To deliver relevant website content and advertisements to you and measure or
                  understand the effectiveness of the advertising we serve to you
                </td>
                <td>
                  (a) Identity <br />
                  (b) Contact <br />
                  (c) Profile <br />
                  (d) Usage <br />
                  (e) Marketing and Communications <br />
                  (f) Technical
                </td>
                <td>
                  Necessary for our legitimate interests (to study how customers use our
                  products/services, to develop them, to grow our business and to inform our
                  marketing strategy)
                </td>
              </tr>
              <tr>
                <td>
                  To use data analytics to improve our website, products/services, marketing,
                  customer relationships and experiences
                </td>
                <td>
                  (a) Technical <br />
                  (b) Usage
                </td>
                <td>
                  Necessary for our legitimate interests (to define types of customers for our
                  products and services, to keep our website updated and relevant, to develop our
                  business and to inform our marketing strategy)
                </td>
              </tr>
              <tr>
                <td>
                  To make suggestions and recommendations to you about goods or services that may be
                  of interest to you
                </td>
                <td>
                  (a) Identity <br />
                  (b) Contact <br />
                  (c) Technical <br />
                  (d) Usage <br />
                  (e) Profile
                </td>
                <td>
                  Necessary for our legitimate interests (to develop our products/services and grow
                  our business)
                </td>
              </tr>
              <tr>
                <td>To notify our customers if their users have contacted us.</td>
                <td />
                <td>
                  Necessary for our legitimate interests and those of our customers – allowing our
                  customers to address requests regarding their users’ personal data.
                </td>
              </tr>
            </tbody>
          </table>

          <h3>
            <span>5.3</span> Promotional offers from us
          </h3>
          <p>
            We may use your Identity, Contact, Technical, Usage and Profile Data to form a view on
            what we think you may want or need, or what may be of interest to you. This is how we
            decide which products, services and offers may be relevant for you{' '}
            <strong>(“marketing”)</strong>.
          </p>
          <p>
            You will receive marketing communications from us if you have requested information from
            us or instructed us to provide services and, in each case, you have not opted out of
            receiving that marketing.
          </p>
          <p>
            We will get your express opt-in consent before we share your personal data with any
            company outside Great State for marketing purposes.
          </p>
          <p>
            You can ask us or third parties to stop sending you marketing messages at any time by
            following the opt-out links on any marketing message sent to you or by contacting us at
            any time. Where you opt out of receiving these marketing messages, this will not apply
            to personal data provided to us as a result of other transactions.
          </p>

          <h3>
            <span>5.4</span> Cookies
          </h3>
          <p>
            You can set your browser to refuse some or all browser cookies, or to alert you when
            websites set or access cookies. If you disable or refuse cookies, please note that some
            parts of this website may become inaccessible or not function properly. For more
            information about the cookies we use, please see our Cookie Policy{' '}
            <Link to="/cookies">
              <Underline text="here" />
            </Link>
            .
          </p>

          <h3>
            <span>5.5</span> Change of purpose
          </h3>
          <p>
            We will only use your personal data for the purposes for which we collected it, unless
            we reasonably consider that we need to use it for another reason and that reason is
            compatible with the original purpose. If you wish to get an explanation as to how the
            processing for the new purpose is compatible with the original purpose, please contact
            us.
          </p>
          <p>
            If we need to use your personal data for an unrelated purpose, we will notify you and we
            will explain the legal basis which allows us to do so.
          </p>
          <p>
            Please note that we may process your personal data without your knowledge or consent, in
            compliance with the above rules, where this is required or permitted by law.
          </p>

          <h3>
            <span>5.6</span> Who do we share your information with?
          </h3>
          <p>
            We may have to share your personal data with the parties set out below for the purposes
            set out in the table above.
          </p>
          <ul>
            <li>
              Specific third parties such as Synergist (a centralised project management tool) see
              privacy policy{' '}
              <a href="https://www.synergist.co.uk/privacy-policy">
                <Underline text="here" color="teal" />
              </a>
              , Sage account and pay roll (Accounting and payroll system) see privacy policy{' '}
              <a href="http://www.sage.com/company/gdpr">
                <Underline text="here" color="teal" />
              </a>
              , Azure hosting solution, privacy policy{' '}
              <a href="https://www.microsoft.com/en-us/trustcenter/privacy/gdp">
                <Underline text="here" color="teal" />
              </a>
              .
            </li>
            <li>Third parties for the purposes of fraud protection and credit risk reduction.</li>
            <li>
              Third parties to whom we may choose to sell, transfer, or merge parts of our business
              or our assets. Alternatively, we may seek to acquire other businesses or merge with
              them. If a change happens to our business, then the new owners may use your personal
              data in the same way as set out in this privacy policy.
            </li>
          </ul>
          <p>
            We require all third parties to respect the security of your personal data and to treat
            it in accordance with the law. We do not allow our third-party service providers to use
            your personal data for their own purposes and only permit them to process your personal
            data for specified purposes and in accordance with our instructions.
          </p>

          <h3>
            <span>5.7</span> Where do we transfer your information?
          </h3>
          <p>
            We do not transfer your information to, or store it in, countries located outside the
            European Economic Area (‘EEA’) which do not have as developed data protection laws.
          </p>
        </section>

        <section>
          <h2>6 How do we keep your personal data secure?</h2>
          <p>
            We have put in place appropriate security measures to prevent your personal data from
            being accidentally lost, used or accessed in an unauthorised way, altered or disclosed.
            In addition, we limit access to your personal data to those employees, agents,
            contractors and other third parties who have a business need to know. They will only
            process your personal data on our instructions and they are subject to a duty of
            confidentiality.
          </p>
          <p>
            We have put in place procedures to deal with any suspected personal data breach and will
            notify you and any applicable regulator of a breach where we are legally required to do
            so.
          </p>
          <p>
            All your information is stored on our secure servers, or on the servers of our
            suppliers.
          </p>
          <p>
            Unfortunately, the transmission of information via the Internet is not completely
            secure. Although we will apply our normal procedures and comply with legal requirements
            to protect your information, we cannot guarantee the security of your information
            transmitted to/from the site or on our behalf, and any transmission is at your own risk.
            Once we have received your information, we will use strict procedures and security
            features to try to prevent unauthorised access.
          </p>
          <p>
            Great State take a multi layered approach to protecting and securing access into our
            network and systems. This approach makes use of multiple best in class security services
            and components to protect our business systems and client data.
          </p>
          <p>
            The purpose of including multiple layers in your network security is to make sure that
            each single defence component has a backup in the case of a flaw or missing coverage.
            The individual strengths of each layer also cover any gaps that other defences may lack.
          </p>
          <p>
            Each individual layer in a multi-layered security approach focuses on a specific area
            where the malware could attack. By working in concert, these layers of security offer a
            better chance of stopping intruders from breaching company networks than using a single
            solution.
          </p>
          <p>
            The types of security layers we use in our network defence include, but are not limited
            to:
          </p>
          <ul>
            <li>Web Protection</li>
            <li>Patch Management (OS &amp; 3rd Party Applications)</li>
            <li>Email Security and Archiving</li>
            <li>Endpoint Security</li>
            <li>Data Encryption</li>
            <li>Perimeter &amp; Endpoint Firewalls</li>
            <li>Perimeter &amp; Endpoint Intrusion Protection</li>
            <li>Digital Certificates</li>
            <li>Role Based Access Control (RBAC)</li>
            <li>Data Leak Protection (DLP)</li>
            <li>Application Control &amp; Software Restrictions</li>
            <li>Device Control</li>
            <li>Advanced Threat Protection (ATP)</li>
          </ul>
          <p>
            We undertake several independent security assessments, run by National Cyber Security
            Centre (NCSC) approved CHECK/CREST testers, and hold multiple security compliance
            accreditations, which not only verifies our security approach, but also demonstrates our
            dedication and commitment to protecting our business and client's data.
          </p>
          <p>These include:</p>
          <ul>
            <li>ISO 27001</li>
            <li>Cyber Essentials Plus</li>
            <li>Regular Vulnerability Assessments</li>
            <li>Penetration Testing</li>
            <li>Firewall Configuration Reviews</li>
          </ul>
        </section>

        <section>
          <h2>7 How long will we keep your personal data for?</h2>
          <p>
            We will only retain your personal data for as long as necessary to fulfil the purposes
            we collected it for, including for the purposes of satisfying any legal, accounting, or
            reporting requirements.
          </p>
          <p>
            To determine the appropriate retention period for personal data, we consider the amount,
            nature, and sensitivity of the personal data, the potential risk of harm from
            unauthorised use or disclosure of your personal data, the purposes for which we process
            your personal data and whether we can achieve those purposes through other means, and
            the applicable legal requirements.
          </p>
          <p>
            In some circumstances you can ask us to delete your data: see “request erasure” below
            for further information.
          </p>
          <p>
            In some circumstances we may anonymise your personal data (so that it can no longer be
            associated with you) for research or statistical purposes in which case we may use this
            information indefinitely without further notice to you.
          </p>
        </section>

        <section>
          <h2>8 What are your rights?</h2>
          <p>
            Under certain circumstances, you have rights under data protection laws in relation to
            your personal data:
          </p>
          <ul>
            <li>
              Request access to your personal data (commonly known as a “data subject access
              request”). This enables you to receive a copy of the personal data we hold about you
              and to check that we are lawfully processing it.
            </li>
            <li>
              Request correction of your personal data. This enables you to have any incomplete or
              inaccurate data we hold about you corrected, though we may need to verify the accuracy
              of the new data you provide to us.
            </li>
            <li>
              Request erasure of your personal data. This enables you to ask us to delete or remove
              personal data where there is no good reason for us continuing to process it. You also
              have the right to ask us to delete or remove your personal data where you have
              successfully exercised your right to object to processing (see below), where we may
              have processed your information unlawfully or where we are required to erase your
              personal data to comply with local law. Note, however, that we may not always be able
              to comply with your request for erasure because of specific legal reasons which will
              be notified to you, if applicable, at the time of your request.
            </li>
            <li>
              Object to processing of your personal data where we are relying on a legitimate
              interest (or that of a third party) and there is something about your particular
              situation which makes you want to object to processing on this ground as you feel it
              impacts on your fundamental rights and freedoms. You also have the right to object
              where we are processing your personal data for direct marketing purposes. In some
              cases, we may demonstrate that we have compelling legitimate grounds to process your
              information which override your rights and freedoms.
            </li>
            <li>
              Request restriction on processing your personal data. This enables you to ask us to
              suspend the processing of your personal data in the following scenarios: (a) if you
              want us to establish the data’s accuracy; (b) where our use of the data is unlawful
              but you do not want us to erase it; (c) where you need us to hold the data even if we
              no longer require it as you need it to establish, exercise or defend legal claims; or
              (d) you have objected to our use of your data but we need to verify whether we have
              overriding legitimate grounds to use it.
            </li>
            <li>
              Request transfer of your personal data to you or to a third party. We will provide to
              you, or a third party you have chosen, your personal data in a structured, commonly
              used, machine-readable format. Note that this right only applies to automated
              information which you initially provided consent for us to use or where we used the
              information to perform a contract with you.
            </li>
            <li>
              Right to withdraw consent where we are relying on consent to process your personal
              data. However, this will not affect the lawfulness of any processing carried out
              before you withdraw your consent. If you withdraw your consent, we may not be able to
              provide certain products or services to you. We will advise you if this is the case at
              the time you withdraw your consent.
            </li>
          </ul>
          <p>If you wish to exercise any of the rights set out above, please contact us.</p>
          <p>
            We may need to request specific information from you to help us confirm your identity
            and ensure your right to access your personal data (or to exercise any of your other
            rights). This is a security measure to ensure that personal data is not disclosed to any
            person who has no right to receive it. We may also contact you to ask you for further
            information in relation to your request to speed up our response.
          </p>
          <p>
            We try to respond to all legitimate requests within one month. Occasionally it may take
            us longer than a month if your request is particularly complex or you have made a number
            of requests. In this case, we will notify you and keep you updated.
          </p>
          <p>
            You will ordinarily not have to pay a fee to access your personal data (or to exercise
            any of the other rights). However, we may charge a reasonable fee if your request is
            clearly unfounded, repetitive or excessive. Alternatively, we may refuse to comply with
            your request in these circumstances.
          </p>
        </section>
      </IntroCopy>
    </PolicyText>
  </>
)
export default PrivacyPolicy
