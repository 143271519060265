import React from 'react'
import { useInView } from 'react-intersection-observer'
import PropTypes from 'prop-types'
import isArray from 'lodash/isArray'
import { Underline, Boldify, SmallLabel, RichText } from '@components'
import css from './Intro.module.css'

const Intro = ({ title, text, cta, label, largeText, fullWidth, className, noMargin, breadcrumbLinks = true, customClasses = [] }) => {
  const textClass = largeText ? css.largeText : null

  // Rich Text Intro title
  const introText =
    text && isArray(text) ? 
      <RichText render={text} /> 
    : (
      text && <h2 className={textClass}>{text}</h2>
    )

  const titleJSX = headingTitle => {
    if (isArray(title)) {
      const titleType = headingTitle[0].type
      switch (titleType) {
        case 'paragraph':
          return (
            <h1 className={css.title}>
              <RichText render={title} />
            </h1>
          )
        case 'heading1':
        case 'heading2':
        case 'heading3':
          return <RichText render={title} />
        default:
          return <h1 className={css.title}>{title}</h1>
      }
    }
    return (
      <h1>
        <Boldify text={title} />
      </h1>
    )
  }

  const hasCta = cta.action != null || cta.url != null

  let ctaLink

  if (hasCta) {
    const ctaText = <Underline text={cta.text} color="transparent-to-pink" />
    ctaLink = !cta.action ? (
      <a href={cta.url} data-direction={cta.direction}>
        {ctaText}
      </a>
    ) : (
      <button type="button" onClick={cta.action} data-direction={cta.direction}>
        {ctaText}
      </button>
    )
  }

  const [ref, inView, entry] = useInView({
    threshold: 0.9,
    triggerOnce: true,
  })

  const componentClasses = [css.intro]
  if(noMargin) componentClasses.push(css.noMargin)
  if(fullWidth) componentClasses.push(css.isFullWidth)
  if(hasCta) componentClasses.push(css.hasCta)
  if(inView) componentClasses.push(css.isVisible)
  if(className) componentClasses.push(className)

  
  if(customClasses.length) customClasses.forEach((thisClass) => componentClasses.push(css[thisClass]))
  

  const isStickyonMobile = !!cta.text.includes('job roles')
  const { isHidden } = cta

  return (
    <div 
      ref={ref} 
      className={componentClasses.join(' ')} 
      data-component="Intro"
      data-keyline={componentClasses.includes('keyline')}
    >
      {label.length > 0 && <SmallLabel text={label} {...{breadcrumbLinks}} />}
      <div className={css.intro__inr}>
        <div className={css.intro__intro}>
          {titleJSX(title)}
          <div className={css.intro__copy}>{introText}</div>
        </div>
        {hasCta && (
          <div
            className={`${css.intro__cta} ${isStickyonMobile ? css.isStuck : ''} ${
              isHidden ? css.isHidden : ''
            } `}
          >
            {ctaLink}
          </div>
        )}
      </div>
    </div>
  )
}

export default Intro

Intro.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  cta: PropTypes.shape({
    text: PropTypes.string.isRequired,
    url: PropTypes.string,
    action: PropTypes.func,
  }),
  className: PropTypes.string,
  noMargin: PropTypes.bool,
}

Intro.defaultProps = {
  title: '',
  text: '',
  label: [],
  cta: {
    text: 'Go',
    direction: null,
  },
  className: null,
  noMargin: false,
}
