import React from 'react'
import { RawHtml } from '@components'

const Boldify = ({ text }) => {
  if (!text) {
    return null
  }

  const bold = text
    .split('*')
    .reduce((prev, next, id) => prev + (id % 2 ? '*' : '</strong>') + next)
    .replace(/\*/g, '<strong>')

  return <RawHtml markup={bold} tag="span" />
}

export default Boldify
