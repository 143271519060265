import React, { useEffect, useState } from 'react'
import { RichText, Elements } from 'prismic-reactjs'
import linkResolver from '@utils/linkResolver'

import TrainSVG from '@images/svg/travel-methods/train.svg'
import CarSVG from '@images/svg/travel-methods/car.svg'
import ScooterSVG from '@images/svg/travel-methods/scooter.svg'

import css from './TravelMethods.module.css'

const propsWithUniqueKey = function(props, key) {
  return Object.assign(props || {}, { key })
}
const htmlSerializer = function(type, element, content, children, key) {
  let props = {}
  switch (type) {
    // Add a class to paragraph elements - blog articles currently use paragraph
    case Elements.paragraph:
      props = { className: 'paragraph-class' }
      return React.createElement('span', propsWithUniqueKey(props, key), children)
    // Add a class to heading elements - blog articles switching over to h1
    // Both included for backwards compatability
    case Elements.heading1:
      props = { className: 'paragraph-class' }
      return React.createElement('span', propsWithUniqueKey(props, key), children)
    // Return null to stick with the default behavior
    case Elements.hyperlink:
      return <a key={`link${content.replace(' ', '-')}`} href={element.data.url} target="_blank" rel="noreferrer noopener">{content}</a>
    default:
      return null
  }
}

const TravelIcon = (props) => {
  const { icon } = props
  if(!icon) return null

  let res
  switch(icon){
    case 'Train':
      res = <TrainSVG />
    break;
    case 'Scooter':
      res = <ScooterSVG />
    break;
    default:
      res = <CarSVG />
  }

  return (
    <div className={css.travel_methods__method_icon} data-type={icon || 'Car'}>
      {res}
    </div>
  )
}

const TravelMethods = (props) => {
  const { methods } = props

  if(!methods) return null
  return (
    <section className={css.travel_methods__container}>
      {methods.map((method, index) => (
        <article key={`travelMethod-${index}`} className={css.travel_methods__method}>
          <TravelIcon {...method} />
          <div className={css.travel_methods__method_content}>
            <RichText render={method.title} linkResolver={linkResolver} htmlSerializer={htmlSerializer} />
            <RichText render={method.content} linkResolver={linkResolver} htmlSerializer={htmlSerializer} />
          </div>
        </article>
      ))}
    </section>
  )
}

export default TravelMethods