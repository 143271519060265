import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { SEO } from '@templates'
import { formatDate, calculateBlogReadingTime, removeLeadingSlash, isBrowser } from '@utils/helpers'
import { getSeoProps } from '@data/seo'
import { prismicBlogArticleFields } from '@graphql/fragments'
import BlogArticleUI from './BlogArticleUI'

export const query = graphql`
  query PrismicBlogArticleByDocumentID($uid: String!) {
    prismic {
      blog_article(uid: $uid, lang: "en-gb") {
        ...prismicBlogArticleFields
      }
    }
  }
`

const BlogArticle = ({ data: { prismic, prismicBlogArticle } }) => {
  const nestedData = get(prismic, 'blog_article')
  // we need to fall back to image url as graphql plugin strips out imageSharp to allow preview functionality

  const singleBlogImage = get(nestedData, 'image.url')
  const blogImages = get(
    nestedData,
    'singleAspectRatioImage.childImageSharp.fluid',
    singleBlogImage,
  )

  const publishDate = get(nestedData, 'published')
  const articleUrl = get(nestedData, '_meta.uid')
  // this may be null, and that's ok
  const programmingLanguage = get(nestedData, 'programming_language')

  if (!nestedData) {
    return null
  }

  const readTime = calculateBlogReadingTime(nestedData.body)

  const date = formatDate(nestedData.published)

  let seoProps = getSeoProps(nestedData)

  seoProps = {
    title: seoProps.title || get(nestedData, 'title[0].text'),
    description: seoProps.description || get(nestedData, 'summary_text[0].text'),
    imageSrc: typeof blogImages === 'object' ? removeLeadingSlash(blogImages.src) : singleBlogImage,
    published: publishDate,
    articleUrl: `blog/articles/${articleUrl}`,
  }

  return (
    <Fragment>
      <SEO {...seoProps} />
      <BlogArticleUI
        {...nestedData}
        date={date}
        blogImages={blogImages}
        readTime={readTime}
        programmingLanguage={programmingLanguage}
      />
    </Fragment>
  )
}
BlogArticle.fragments = [prismicBlogArticleFields]
export default BlogArticle

BlogArticle.query = query
