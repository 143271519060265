import React from 'react'
import site from '@utils/site'
import FormWithPost from './FormWithPost'
import css from './MailingListSignup.module.css'

const MailingListSignup = props => {
  const { title, image, theme } = props

  // TODO: what constitutes an Email Signup Form incomplete enough not to render?
  if (!title) {
    return null
  }

  return (
    <div className={css.mailingListSignup} data-theme={theme}>
      {image && (
        <div className={css.mailingListSignup__image}>
          <img src={image.url} alt="" />
        </div>
      )}

      <div className={css.mailingListSignup__content}>
        <FormWithPost {...props} />
      </div>
    </div>
  )
}

export default MailingListSignup
