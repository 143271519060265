import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import find from 'lodash/find'
import filter from 'lodash/filter'
import each from 'lodash/each'
import { getSeoProps } from '@data/seo'
import { prismicServiceFields } from '@graphql/fragments'
import { SEO } from '@templates'
import { Intro, Steps, SubMenu, BackLink, SliceGenerator, QuoteTabs } from '@components/'
import css from './Service.module.css'

export const query = graphql`
  query PrismicServiceByUID($uid: String!) {
    prismic {
      allServices {
        edges {
          node {
            name
            meta: _meta {
              uid
            }
          }
        }
      }
      service(uid: $uid, lang: "en-gb") {
        ...prismicServiceFields
      }
      allServices_pages {
        edges {
          node {
            services {
              service {
                ... on PRISMIC_Service {
                  name
                  meta: _meta {
                    uid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
const getQuoteTabsProps = data => {
  if (!data) {
    return null
  }

  const quoteTabsData = data.clientQuoteTabs

  if (!quoteTabsData) {
    return null
  }

  return {
    quotes: quoteTabsData.map(x => ({
      text: get(x, 'quote[0].text'),
      author: x.author,
      image: x.image,
      client: x.client,
    })),
  }
}

const Service = ({ data: { prismic } }) => {
  const data = get(prismic, 'service', {})
  const serviceName = get(data, 'name[0].text', '')
  const serviceTitle = get(data, 'heading', '')
  const serviceIntro = get(data, 'intro')
  const stepsTitle = get(data, 'steps_heading')
  const stepsIntro = get(data, 'steps_intro')
  const steps = get(data, 'steps', [])
  const orderSteps = get(data, 'order_steps') !== 'false'
  const body = get(data, 'body')
  const seoProps = getSeoProps(data)

  const allServices = get(prismic, 'allServices_pages.edges[0].node.services', [])

  const caseStudyBlock = find(body, { type: 'listing__case_study' })
  if (caseStudyBlock) {
    caseStudyBlock.primary.listing_title[0].text = 'Case studies'
  }

  const quoteTabsProps = getQuoteTabsProps(data)

  const serviceLinks = allServices.map(s => {
    return { link: `/services/service/${s.service.meta.uid}`, name: s.service.name[0].text }
  })

  return (
    <Fragment>
      <SEO {...seoProps} />

      <BackLink to="/services" className={css.back} />
      <div className={css.ServiceMenu}>
        <SubMenu items={serviceLinks} />
      </div>

      <Intro label={['services', serviceName]} title={serviceTitle} text={serviceIntro} />

      <QuoteTabs {...quoteTabsProps} />

      <Steps title={stepsTitle} intro={stepsIntro} steps={steps} order={orderSteps} />

      <Slices
        body={body}
        sliceTypes={[
          'listing__case_study',
          'list_of_articles',
          {
            type: 'article_slider_block',
            config: {
              size: 4,
              baseUrl: '/partners',
              useLogo: true,
            },
          },
        ]}
      />

      <Slices sliceTypes={['banner']} />
    </Fragment>
  )
}

const Slices = ({ sliceTypes, body }) => {
  const filterSlicesBySliceType = types => {
    return filter(body, b => {
      return types.indexOf(b.type) !== -1
    })
  }

  const addConfiguredSlicestoFilteredSlices = (types, filteredSlices) => {
    const configSlices = filter(types, s => {
      return s.type !== undefined
    })
    each(body, b => {
      const block = b
      each(configSlices, cs => {
        if (block.type === cs.type) {
          const data = { ...block, config: cs.config}
          filteredSlices.push(data)
        }
      })
    })
  }

  const filteredSlices = filterSlicesBySliceType(sliceTypes)
  addConfiguredSlicestoFilteredSlices(sliceTypes, filteredSlices)

  return <SliceGenerator slices={filteredSlices} />
}

Service.fragments = [prismicServiceFields]

export default Service

Service.query = query
