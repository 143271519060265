import { useRef, useEffect } from 'react'

export default function useCopyTracking() {
  const ref = useRef(null)

  useEffect(() => {
    const node = ref.current
    if (node) {
      node.addEventListener('copy', function(e) {
        const clipboardText = document.getSelection().toString()
        const clipboardLength = clipboardText.length
        window.dataLayer.push({
          event: 'textCopied',
          clipboardText,
          clipboardLength,
        })
      })
    }
  }, [ref])

  return [ref]
}
