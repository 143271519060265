import React, { useState } from 'react'
import { QuoteOverImage, BlockReveal, ClientLogo } from '@components'
import classnames from 'classnames'
import useWindowWidth from '@hooks/useWindowWidth'
import css from './QuoteTabs.module.css'

const QuoteTabs = ({ title, quotes }) => {
  const [currentTabIndex, setCurrentTab] = useState(0)
  const [previousTabIndex, setPreviousTab] = useState(undefined)

  if (!quotes || !quotes[0].image) {
    return null
  }

  const currentQuote = quotes[currentTabIndex]
  const previousQuote = quotes[previousTabIndex]

  const componentClasses = [css.QuoteTabs]
  if(title) componentClasses.push('keyline')

  return (
    <section className={componentClasses.join(' ')} data-component="QuoteTabs">
      {title && <h2>{title}</h2>}
      <div className={css.QuoteTabs__tabs}>
        {quotes.map((quote, i) => {
          return (
            <button
              key={`QuoteTab${i}`}
              type="button"
              onClick={() => {
                setPreviousTab(currentTabIndex)
                setCurrentTab(i)
              }}
              className={classnames(css.QuoteTabs__tab, {
                [`${css.Selected}`]: currentTabIndex === i,
              })}
            >
              <ClientLogo
                color={currentTabIndex === i ? 'white' : '#0e0e0e'}
                client={quote.client}
              />
            </button>
          )
        })}
      </div>
      <Quote {...{ currentQuote, previousQuote, currentTabIndex }} />
    </section>
  )
}

const Quote = ({ currentQuote, previousQuote, currentTabIndex }) => {
  const windowWidth = useWindowWidth()

  if (windowWidth >= 767 && previousQuote) { 
    return (
      <div className={css.QuoteTabs__quote}>
        {/* key added to force rerender so block reveal triggers on tab change */}
        <BlockReveal
          previousChildren={<QuoteOverImage quote={previousQuote} quotePosition="top" />}
          key={currentTabIndex}
        >
          <QuoteOverImage quote={currentQuote} quotePosition="top" />
        </BlockReveal>
      </div>
    )
  }

  return (
    <div className={css.QuoteTabs__quote}>
      <QuoteOverImage quote={currentQuote} quotePosition="top" />
    </div>
  )
}

export default QuoteTabs
