const dayjs = require('dayjs')
const PrismicDOM = require('prismic-dom')
const Cookies = require('js-cookie')

const helpers = {
  /**
   * @param {string} val The value you want to check the object contains
   * @param {object} obj The object to search for the value in
   */
  inObject(val, obj) {
    return Object.values(obj).indexOf(val) > -1
  },

  /**
   * @param {string} str The string you want to make the first letter uppercase of
   */
  ucFirst(str) {
    return `${str.charAt(0).toUpperCase()}${str.slice(1)}`
  },
  /**
   * @param {string} str The prismic typename string (PRISMIC_Logo_board) string will be converted to kebab case whilst stripping out 'PRISMIC'
   */

  snakeToKebabCase(str) {
    return str
      .toLowerCase()
      .replace(/_/g, '-')
      .replace(/prismic/g, '')
      .substring(1)
  },
  /**
   * @param {string} str The snake_case string will be ConvertedToPascalCase
   */
  snakeToPascalCase(str) {
    return str
      .split('_')
      .map(word => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
      .join()
      .replace(/,/g, '')
  },
  /**
   * @param {string} str The leading slash in str will be removed
   */

  removeLeadingSlash(str) {
    return str.replace(/^\/|\/$/g, '')
  },
  /**
   * @param {string} str The trailing slash in str will be removed
   */

  removeTrailingSlash(str) {
    if (str.substr(-1) === '/') {
      return str.substr(0, str.length - 1)
    }
    return str
  },
  /* istanbul ignore next */
  isBrowser() {
    return typeof window !== 'undefined'
  },
  /**
   * @param {array} arr The prismic text field data you want to extract the text from
   */
  prismicText(arr) {
    try {
      return arr[0].text
    } catch (error) {
      return null
    }
  },

  /**
   * @param {array} arr The prismic rich text data field you want to extract the HTML for
   */
  prismicHTML(arr) {
    return PrismicDOM.RichText.asHtml(arr)
  },

  /**
   * @param {object} staticImageDataObject Data set containing images with static URLs
   * @param {object} fluidImageDataObject Data set containing images with childImageSharp objects
   * @param {boolean} previewModeActive Preview mode boolean based on presence of preview cookie
   */
  /* istanbul ignore next */
  prismicImages(staticImageDataObject, fluidImageDataObject) {
    const previewModeActive = Cookies.get('io.prismic.preview') !== undefined
    const images = {}
    const imageProps = []
    for (const prop in fluidImageDataObject) {
      imageProps.push(prop)
    }

    if (previewModeActive) {
      // Preview mode is active;
      // return the latest image urls from the staticImageDataObject
      imageProps.map(prop => {
        images[prop] = staticImageDataObject[prop].url
        return prop
      })
    } else {
      // Request for build-time data;
      // return the childImageSharp objects from the fluidImageDataObject
      imageProps.map(prop => {
        images[prop] = fluidImageDataObject[prop].localFile.childImageSharp.fluid
        return prop
      })
    }

    return images
  },
  formatDate(date) {
    if (date == null) return ''
    return dayjs(date).format('DD MMMM YYYY')
  },
  addLeadingZero(number) {
    if (Number.isNaN(parseFloat(number))) return number
    return number.toString().length === 1 ? `0${number}` : number
  },
  calculateBlogReadingTime(body) {
    if (!body) {
      return 0
    }

    const textBodyItems = body.filter(x => x.type === 'text')

    if (!textBodyItems || textBodyItems.length === 0) {
      return 0
    }

    const nestedTextItem = textBodyItems.map(item => item.primary.text)

    const reduceTextArray = (accumulator, currentValue) => `${accumulator} ${currentValue.text}`
    const reduceText = (accumulator, currentValue) => accumulator + currentValue

    const textArray = nestedTextItem.map(item => {
      return item.reduce(reduceTextArray, '')
    })

    const text = textArray.reduce(reduceText, '')

    const wordsPerMinute = 200
    const noOfWords = text.split(/\s/g).length
    const minutes = noOfWords / wordsPerMinute
    const readTime = Math.ceil(minutes)

    return readTime
  },

  fileToBase64(file){
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.addEventListener("load", function() {
        // this strips out the data:url bit from the start so you just get the base64 goodness
        resolve(reader.result.substring(reader.result.indexOf(',') + 1))
      });

      if (file) {
        reader.readAsDataURL(file)
      }

      reader.onerror = error => reject(error)
    })
  },

  isFileExtensionSupported(supportedFiles, fileName) {
    const extension = fileName.split('.').pop().toLowerCase()
    return Boolean(supportedFiles.includes(extension))
  },

  isFileTooLarge(file) {
    const maxFileSize = 5242880 // 5mb
    return Boolean(file[0].size > maxFileSize)
  },

  getErrorMessage(errorCode){
    const acceptedFileTypes = ['.doc', '.docx', '.pdf', '.rtf', '.txt']
    const formErrors = [
      { code: 'no-resume', message: { heading: 'No Resume', body: 'Please upload a resume.' } },
      { code: 'no-cover_letter', message: { heading: 'No Cover Letter', body: 'Please upload a cover letter.' } },
      { code: 'invalid-format', message: { heading: 'Invalid file format', body: `It looks like you have attached a file type we do not support. Please resubmit your application using one of the supported file types: ${acceptedFileTypes.toString()}` } },
      { code: 'file-too-large', message: { heading: 'File was too large', body: 'The file you are trying to upload is too large. Maximum file size is 5mb.' } },
      { code: 'submission-success', message: { heading: 'Application sent!', body: 'Thank you, your application has been submitted successfully.' } }
    ]
    const matchedCode = formErrors.find(x => x.code === errorCode)
    return matchedCode ? matchedCode.message : false
  },

  getMailingList(pageUID){
    switch(pageUID){
      case 'higher-education':
        return process.env.GATSBY_MAILCHIMP_HIGHER_EDUCATION_ENDPOINT
      default:
        return process.env.GATSBY_MAILCHIMP_ENDPOINT
    }

  }
}

for (const helper in helpers) {
  module.exports[helper] = helpers[helper]
}

