import React from 'react'
import Error from './Error'
import css from './Form.module.css'

const Input = props => {
  const { formRef, required, name, error, type, label, description, acceptedFileTypes } = props
  const associateID = `txt-${name}`

  const handleChange = (e) => {
    const {target} = e;
    const {value} = e.target;
    if(value !== ''){
      target.classList.add(css.visited)
    }else{
      target.classList.remove(css.visited)
    }
  }

  return (
    <div className={`${css.gsFormField} ${error ? css.gsFormFieldError : ''}`}>
      <input 
        onChange={(e) => handleChange(e)} 
        id={associateID} 
        ref={formRef} 
        accept={type === 'file' ? acceptedFileTypes.map(file => `.${file}`).join(',') : undefined}
        {...{type, name}}
      />
      <label htmlFor={associateID}>
        <span>{`${label}${required ? `*` : ''}`}</span>
      </label>
      {description && <span dangerouslySetInnerHTML={{ __html: description }} />}
      <Error error={error} />
    </div>
  )
}

export default Input
