import React from 'react'
import get from 'lodash/get'
import { getSeoProps } from '@data/seo'
import { SEO } from '@templates'
import { ContentSection, Intro, Banner, MailingListSignup } from '@components'

const Newsletter = ({ data: { prismic }, showSuccess }) => {
  const data = prismic.allNewsletter_pages.edges[0].node
  const seoProps = getSeoProps(data)
  const mailingListProps = get(data, 'mailinglist')
  const bannerProps = get(data, 'banner')

  return (
    <>
      <ContentSection noMarginTop noMarginBottom>
        <SEO {...seoProps} />
        <Intro title={data.title} text={data.content} />
      </ContentSection>

      <MailingListSignup {...mailingListProps} showSuccess={showSuccess} />

      {!!bannerProps && (
        <Banner
          title={bannerProps.title[0].text}
          text={bannerProps.text}
          ctaText={bannerProps.link_text[0].text}
          ctaUrl={bannerProps.link.url}
        />
      )}
    </>
  )
}

export default Newsletter
