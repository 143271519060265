import React from 'react'
import css from './Form.module.css'

export default ({ error }) => {
  if (!error) {
    return null
  }

  const { type } = error

  switch (type) {
    case 'required': {
      return <span className={css.inputRequired}>This field is required.</span>
    }
    default:
      return <span className={css.inputError}>This field has an error.</span>
  }
}
