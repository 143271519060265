import React from 'react'
import PropTypes from 'prop-types'
import { addLeadingZero } from '@utils/helpers'
import css from './Controls.module.css'

/**
 * @summary Controls component
 * @property {number} position
 * @property {number} limit
 * @property {function} prev
 * @property {function} next
 *
 */

const Controls = ({ position, limit, prev, next, mobileOnly, showPages }) => {
  return (
    <div className={`${css.controls} ${mobileOnly ? css.controlsMobileOnly: ''}`}>
      <button
        className={`${css.controls__button}`}
        type="button"
        onClick={prev}
        disabled={position === 0}
      >
        Previous
      </button>
      {showPages && <div className={css.controls__pages}>{`${addLeadingZero(position + 1)} / ${addLeadingZero(limit)}`}</div>}
      <button
        className={`${css.controls__button} ${css.controls__buttonNext}`}
        type="button"
        onClick={next}
        disabled={position >= limit - 1}
      >
        Next
      </button>
    </div>
  )
}

export default Controls

Controls.defaultProps = {
  mobileOnly: false,
  showPages: false,
}

Controls.propTypes = {
  position: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  prev: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  mobileOnly: PropTypes.bool,
  showPages: PropTypes.bool,
}
