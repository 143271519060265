import React, { Fragment } from 'react'
import { Intro, SliceGenerator, MailingListSignup } from '@components'
import { SEO } from '@templates'
import css from './Work.module.css'

const Work = ({ body, heading, seo, mailinglist }) => {
  return (
    <Fragment>
      <SEO {...seo}/>
      <div>
        <Intro title={heading} />
        <PageGrid body={body} />
      </div>
      <MailingListSignup {...mailinglist} />
    </Fragment>
  )
}

const PageGrid = ({ body }) => {
  if (!body) {
    return null
  }

  return (
    <div className={css.WorkPage}>
      <SliceGenerator slices={body} page="work" />
    </div>
  )
}

export default Work
