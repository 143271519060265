import React, { useState } from 'react'

import _omit from 'lodash/omit'
import _chunk from 'lodash/chunk'
import _slice from 'lodash/slice'
import { SEO } from '@templates'
import Prismic from 'prismic-javascript'
import { CSSTransition } from 'react-transition-group'
import { Intro, BlogArticleListBlock, LoadMore, MailingListSignup } from '@components'
import Client from '@data/prismicClient'
import Filter from './BlogFilter'
import css from './Blog.module.css'

const Blog = ({ heading, initialArticles, totalArticles, filter, seo, mailinglist }) => {
  const loadMoreValue = 6

  const initialState = {
    articlesVisible: 8,
    currentPage: 1,
    articles: initialArticles.map(x => x.node),
    articlesLoaded: initialArticles.length,
    isLoading: false,
  }

  const [state, setState] = useState(initialState)

  const fetchData = async page => {
    setState(prev => ({
      ...prev,
      isLoading: true,
    }))
    const prismicFilter = filter
      ? Prismic.Predicates.at('my.blog_article.filter', filter)
      : undefined

    const response = await Client.query(
      [Prismic.Predicates.at('document.type', 'blog_article'), prismicFilter],
      {
        pageSize: 20,
        page,
        orderings: '[my.blog_article.published desc]',
        fetchLinks: ['person.name', 'person.job_title'],
      },
    )
    if (response && response.results) {
      const newArticles = []
      response.results.forEach((x, i) => {
        newArticles[i] = x.data
        newArticles[i].author = newArticles[i].author.data
        newArticles[i].link = {}
        newArticles[i].link.uid = x.uid
      })
      const updatedArticles = state.articles.concat(newArticles)

      setState(prev => ({
        ...prev,
        isLoading: false,
        articles: updatedArticles,
        articlesLoaded: updatedArticles.length,
      }))
    }
  }

  const loadNextPage = async () => {
    if (state.isLoading) {
      return
    }

    const articlesVisible = state.articlesVisible + loadMoreValue

    if (state.articlesVisible + 2 * loadMoreValue > state.articlesLoaded) {
      const currentPage = state.currentPage + 1
      fetchData(currentPage)
      setState(prev => ({
        ...prev,
        articlesVisible,
        currentPage,
      }))
    } else {
      setState(prev => ({
        ...prev,
        articlesVisible,
      }))
    }
  }

  const getChunkedArticles = articles => {
    const first2 = _chunk(_slice(articles, 0, 2), 2)
    const theRestInChuncksOf3 = _chunk(_slice(articles, 2), 3)

    const lastItem = theRestInChuncksOf3[theRestInChuncksOf3.length - 1]
    if (lastItem && lastItem.length < 3) {
      for (let i = 0; i < 3 - lastItem.length + 1; i++) {
        lastItem.push({
          node: {
            _meta: {
              uid: `empty_${i}`,
            },
          },
        })
      }
    }

    return [...first2, ...theRestInChuncksOf3]
  }

  const prepForListBlock = blogs => {
    return blogs.map(b => {
      return {
        blog: _omit(b, 'author.profile_picture'),
      }
    })
  }

  const hideLoadMore = state.isLoading || state.articlesVisible >= totalArticles

  const getArticleListBlocks = () => {
    const pagedArticles = _slice(state.articles, 0, state.articlesVisible)

    const articleChunks = getChunkedArticles(pagedArticles)
    const components = articleChunks.map((chunk, index) => {
      const blogs = prepForListBlock(chunk)
      return (
        <BlogArticleListBlock
          key={index}
          blogArticleList={[...blogs]}
          className={css.landingListBlock}
        />
      )
    })
    return components
  }

  return (
    <div>
      <SEO {...seo} />
      <Intro title={heading} />
      <Filter />
      {/* CSS Transition not currently used but this would be starting point for any animation showing the new content */}
      <CSSTransition
        // in={inProp}
        timeout={0}
        classNames={{
          appear: 'my-appear',
          appearActive: 'my-active-appear',
          appearDone: 'my-done-appear',
          enter: css.myNodeEnter,
          enterActive: css.myNodeEnterActive,
          enterDone: css.myNodeDoneEnter,
          exit: 'my-exit',
          exitActive: 'my-active-exit',
          exitDone: 'my-done-exit',
        }}
      >
        <div>{getArticleListBlocks()}</div>
      </CSSTransition>
      <div className={css.loadMore}>{!hideLoadMore && <LoadMore handleClick={loadNextPage} />}</div>
      <MailingListSignup {...mailinglist} />
    </div>
  )
}

export default Blog
