import React, { useEffect, useState } from 'react'
import {
  OneCaseStudyBlock,
  CaseStudyListBlock,
  FourCaseStudyBlock,
  Quote,
  Quotes,
  FullWidthImage,
  ContentWidthImage,
  FigureListBlock,
  ArticleSlider,
  DoubleParagraph,
  Banner,
  RichText,
  VideoEmbed,
  ImageTextBlock
} from '@components'

/**
 * @param {Object} props
 * @param {Object} props.slices - Slices
 * @param {string} props.programmingLanguage - Language for the syntax highlighter
 */
const SliceGenerator = ({ slices, programmingLanguage, key, page, renderMode }) => {
    const [isClient, updateClient] = useState(false)

    useEffect(() => {
        updateClient(true)
    }, [])


  return slices.map((slice, index) => {
    const result = (() => {
      switch (slice.type) {
        case 'heading':
          return <div key={index + slice}>{slice.primary.content_heading[0].text}</div>
        case 'quote':
          return <Quote key={index + slice.type} {...slice.primary} />
        case 'content_block':
          return <div key={index + slice.type}>{slice.fields[0].body_copy[0].text}</div>
        case 'listing__case_study':
          return (
            <CaseStudyListBlock
              key={`${index}-${slice.type}-${renderMode}`}
              caseStudyList={Object.values(slice.fields)}
              title={slice.primary.listing_title ? slice.primary.listing_title[0].text : null}
              {...{page}}
            />
          )
        case '1_case_study_block':
        case '2_case_study_block':
        case '4_case_study_block':
            return (
              <CaseStudyListBlock
                key={`${index}-${slice.type}-${renderMode}`}
                caseStudyList={Object.values(slice.primary)}
                title={slice.primary.listing_title ? slice.primary.listing_title[0].text : null}
                {...{page}}
              />
            )
        case 'image_quotes':
          return (
            <Quotes key={index + slice.type} title={slice.primary.title} quotes={slice.fields} />
          )
        case 'full_width_image':
          return <FullWidthImage hasMargin key={index + slice.type} {...slice.primary} />
        case 'content_width_image':
          return <ContentWidthImage hasMargin key={index + slice.type} {...slice.primary} />
        case 'figure_block':
          return <FigureListBlock hasMargin key={index + slice.type} figureList={slice.fields} />
        case 'text':
          return slice.primary && <RichText key={index + slice.type} render={slice.primary.text} language={programmingLanguage}/>
        case 'list_of_articles':
        case 'article_slider_block':
          return (
            <ArticleSlider
              key={index + slice.type}
              title={slice.primary.title_of_section ? slice.primary.title_of_section[0].text : null}
              articles={Object.values(slice.primary).slice(1)}
              config={slice.config}
            />
          )
        case 'partner_slider_block':
          return (
            <ArticleSlider
              key={index + slice.type}
              title={slice.primary.title_of_section ? slice.primary.title_of_section[0].text : null}
              articles={Object.values(slice.primary).slice(1)}
              config={{
                size: 4,
                baseUrl: '/partners',
                useLogo: true,
              }}
            />
          )
        case 'titled_double_paragraph':
          return <DoubleParagraph key={index + slice.type} {...slice.content} />
        case 'banner':
          return (
            <Banner
              key={index + slice.type}
              title={slice.content.title[0].text}
              text={slice.content.paragraph_1[0].text}
              ctaText={slice.content.link_text[0].text}
              ctaUrl="/contact"
            />
          )
        case 'video_embed':
            return (
              <VideoEmbed
                key={index + slice.type}
                videoId={slice.primary.vimeo_id}       
              />
            )
        case 'image_text_block':
          return (
            <ImageTextBlock
              key={index + slice.type}
              {...slice.primary}
            />
          )
        default:
          return null
      }
    })()
    return result
  })
}

export default SliceGenerator
