import React from 'react'
import { RawHtml } from '@components'
import css from './FormError.module.css'

const FormError = ({ msg }) => {
  return (
    <div className={css.error}>
      <RawHtml markup={msg} tag="span" />
    </div>
  )
}

export default FormError
