import React from 'react'
import { RichText, Elements } from 'prismic-reactjs'
import './RichText.css'
import linkResolver from '@utils/linkResolver'

import SyntaxHighlighter from 'react-syntax-highlighter'
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs'

const getHtmlSerializer = programmingLanguage => {
  const htmlSerializer = function(type, element, content, children, key) {
    switch (type) {
      case Elements.preformatted:
        return (
          <SyntaxHighlighter
            key={`preformated-${key}`}
            language={programmingLanguage}
            style={docco}
          >
            {element.text}
          </SyntaxHighlighter>
        )

      // Return null to stick with the default behavior

      default:
        return null
    }
  }
  return htmlSerializer
}

const getProgrammingLanguage = lang => {
  switch (lang) {
    case 'Javascript':
      return 'javascript'

    default:
      return 'cs'
  }
}

export default ({ render, language = '' }) => {
  if (!render) {
    return null
  }

  // default to C#
  const programmingLanguage = getProgrammingLanguage(language)
  const htmlSerializer = getHtmlSerializer(programmingLanguage)

  return (
    <div className="richtext">
      <RichText linkResolver={linkResolver} htmlSerializer={htmlSerializer} render={render} />
    </div>
  )
}
