import React, { useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import PropTypes from 'prop-types'
import { RichText } from 'prismic-reactjs'
import isArray from 'lodash/isArray'
import { Avatar, Underline } from '@components'
import site from '@utils/site'
import css from './Quote.module.css'

const Quote = ({ text, author, cta, isSmall, link }) => {
  // Deal with plain text or prismic rich text
  const quoteText = (isArray(text) ? 
    <RichText render={text} /> 
  : 
    <p data-hasLink={Boolean(link)}>
      {link ? 
      <AniLink
        cover
        duration={1}
        bg={site.colours.slate}
        direction="right" to={`/work/casestudy/${link.meta.uid}`}
      >
      <Underline isActive={false} cutIn>{text}</Underline></AniLink> 
      : text}
    </p>
  )
  const hasCta = cta.action != null || cta.url != null
  const isStickyonMobile = !!cta.text.includes('job roles')
  const { isHidden } = cta
  let ctaLink

  if (hasCta) {
    const ctaText = <Underline text={cta.text} color="transparent-to-pink" />
    ctaLink = !cta.action ? (
      <a href={cta.url} data-direction={cta.direction}>
        {ctaText}
      </a>
    ) : (
      <button type="button" onClick={cta.action} data-direction={cta.direction}>
        {ctaText}
      </button>
    )
  }

  const [ref, inView, entry] = useInView({
    threshold: 0.9,
    triggerOnce: true,
  })

  const componentClasses = [css.quote]
  if(hasCta) componentClasses.push(css.hasCta)
  if(inView) componentClasses.push(css.isVisible)
  if(isSmall) componentClasses.push(css.isSmall)

  return (
    <div
      ref={ref}
      className={componentClasses.join(' ')}
    >
      <div className={css.quote__inr}>
        <div className={css.quote__quote}>
          <div className={css.quote__text}>{quoteText}</div>
          {author && (
            <div className={css.quote__author}>
              {author.profile_picture && author.profile_picture.url && (
                <Avatar src={author.profile_picture.url} />
              )}{' '}
              <span className={css.quote__authorInfo}>
                {author.name} &bull; {author.job_title}
              </span>
            </div>
          )}
        </div>
        {hasCta && (
          <div
            className={`${css.quote__cta} ${isStickyonMobile ? css.isStuck : ''} ${
              isHidden ? css.isHidden : ''
            } `}
          >
            {ctaLink}
          </div>
        )}
      </div>
    </div>
  )
}

export default Quote

Quote.propTypes = {
  author: PropTypes.shape({
    name: PropTypes.string,
    job_title: PropTypes.string,
    profile_picture: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  cta: PropTypes.shape({
    text: PropTypes.string.isRequired,
    url: PropTypes.string,
    action: PropTypes.func,
  }),
  isSmall: PropTypes.bool,
}

Quote.defaultProps = {
  author: null,
  cta: {
    text: 'Go',
    direction: null,
  },
  isSmall: false,
}
