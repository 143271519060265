import React from 'react'

const TextImage = ({ data }) => {
  return (
    <p>
      <strong>TextImage Component</strong>
    </p>
  )
}

export default TextImage
