import React from 'react'
import scrollToElement  from 'scroll-to-element'
import css from './BackToTop.module.css'

const handleClick = (e) => {
  e.preventDefault();

  scrollToElement(document.body, {
    duration: 800,
  });
}

const BackToTop = () => (
  <button type="button" className={css.backToTop} onClick={(e) => handleClick(e)}>Back to top</button>
)

export default BackToTop
