import React, { Fragment } from 'react'
import GatsbyImage from 'gatsby-image'
import { useInView } from 'react-intersection-observer'
import get from 'lodash/get'
import { RichText, Elements } from 'prismic-reactjs'
import composeRefs from '@seznam/compose-react-refs'
import { BlockReveal, Underline, ArticleInfo, RightTransitionLink } from '@components'
import linkResolver from '@utils/linkResolver'
import '../../styles/global.css'
import useWindowWidth from '@hooks/useWindowWidth'
import useHover from '@hooks/useHover'
import css from './Card.module.css'

/**
 * @summary Card component
 * @property {object} props
 * @property {string} imageUrl - The background image for the card
 * @property {string} [type] - optional
 * @property {string} title - title of card
 * @property {string} url - url for article
 * @property {string} delay - delay, passed to reveal
 * @property {string} color - color bassed to reveal
 *
 * Needs to take into account 3 variations of images: Hero 1280 x 650, Portrait - 626 x 830, Card - 626 x 380
 *
 */


const propsWithUniqueKey = function(props, key) {
    return Object.assign(props || {}, { key })
}

const htmlSerializer = function(type, element, content, children, key) {
  let props = {}
  switch (type) {
    // Add a class to paragraph elements - blog articles currently use paragraph
    case Elements.paragraph:
      props = { className: 'paragraph-class' }
      return React.createElement('span', propsWithUniqueKey(props, key), children)
    // Add a class to heading elements - blog articles switching over to h1
    // Both included for backwards compatability
    case Elements.heading1:
      props = { className: 'paragraph-class' }
      return React.createElement('span', propsWithUniqueKey(props, key), children)
    // Return null to stick with the default behavior
    default:
      return null
  }
}

const CardText = ({ text, title, isHovered, height }) => {
  if (title && text) {
    return (
      <div style={{ height }}>
        <Underline isActive={isHovered} cutIn>
          <RichText render={title} linkResolver={linkResolver} htmlSerializer={htmlSerializer} />
        </Underline>
        <p className={css.card__text}>
          <RichText render={text} linkResolver={linkResolver} htmlSerializer={htmlSerializer} />
        </p>
      </div>
    )
  }

  const textToRender = text || title

  if (!textToRender) {
    return null
  }

  return (
    <div className={css.card__singletext}>
      <Underline isActive={isHovered} cutIn>
        <RichText
          render={textToRender}
          linkResolver={linkResolver}
          htmlSerializer={htmlSerializer}
        />
      </Underline>
    </div>
  )
}




const Card = ({
    article_type: articleType,
    images,
    url,
    text,
    title,
    delay = 0,
    color,
    swipe,
    flexBasis,
    author,
    published,
    groupSize, // if the card is in a group it needs to know the size
    useSvg, // many image sizes can be present but this flag overrides their use and forces the SVG image to be used.
    svgImage,
    sharpImageOverride,
    _meta,
    hasNumbering,
    cardNumber,
    textHeight = 'auto',
  }) => {
    const largeDeviceImage = get(images, 'largeDeviceImage.childImageSharp.fluid')
    const smallDeviceImage = get(images, 'smallDeviceImage.childImageSharp.fluid')
    const singlePrismicImage = get(images, 'landscape_image')
    const singleAspectRatioImage = get(
      images,
      'singleAspectRatioImage.childImageSharp.fluid',
      singlePrismicImage,
    )
  
    const [hoverRef, isHovered] = useHover()
  
    const getSharpImage = () => {
      let largeOrSmallImage
  
      if (sharpImageOverride) return sharpImageOverride
  
      if (groupSize === 1) largeOrSmallImage = largeDeviceImage
      else if (groupSize > 1) largeOrSmallImage = smallDeviceImage
      else if (useWindowWidth() >= 767) {
        largeOrSmallImage = largeDeviceImage
      } else {
        largeOrSmallImage = smallDeviceImage
      }
  
      if (largeOrSmallImage) return largeOrSmallImage
  
      return singleAspectRatioImage
    }
  
    const sharpImage = getSharpImage()
  
    const groupSizeClass = () => {
      if (groupSize === 2) return css.groupSize2
      if (groupSize === 3) return css.groupSize3
      if (groupSize >= 4) return css.groupSize4AndAbove
      return null
    }
  
    const [ref, inView] = useInView({
      triggerOnce: true,
    })
  
    const isEmpty = () => {
      return _meta && _meta.uid && _meta.uid.indexOf('empty') !== -1
    }
  
    const metaID = _meta && _meta.uid ? _meta.uid : null
  
    const cardLink = url || '#'
  
    const renderImage = () => {
      if (useSvg === true && svgImage) {
        // todo: need to size svg based on container size and ratio 16:9
        // w245 h139 - w844 h475 - 16w:9w
        return (
          <img
            src={svgImage.url}
            className={css.card__image}
            alt=""
            height={svgImage.dimensions.height}
            width={svgImage.dimensions.width}
          />
        )
      }
  
      // Childimage sharp object produced at build time
      if (typeof sharpImage === 'object' && Object.prototype.hasOwnProperty.call(sharpImage, 'src')) {
        return <GatsbyImage fluid={sharpImage} className={css.card__image} />
      }
  
      // used for prismic preview. Prismic hosted image
      if (typeof sharpImage === 'object' && Object.prototype.hasOwnProperty.call(sharpImage, 'url')) {
        return (
          <img
            src={sharpImage.url}
            className={css.card__image}
            data-status="prismic preview"
            loading="lazy"
            alt={sharpImage.alt}
          />
        )
      }
  
      if (sharpImage === null) {
        console.error(`Image not specified correctly for ${url} - ${metaID}`)
        // throw new Error(`Image not specified correctly for ${metaID}`)
      }
  
      return (
        <img
          src={images && images.image && images.image.url}
          className={css.card__image}
          alt=""
          loading="lazy"
        />
      )
    }
  
    return (
      <article
        ref={composeRefs(ref, hoverRef)}
        className={`${css.carditem} u-faux-block-link ${groupSize ? groupSizeClass() : ''} ${isEmpty() ? css.cardEmpty : ''}`}
        style={{ flexBasis: `${flexBasis}` }}
      >
        {!isEmpty() && (
          <Fragment>
            <div className={css.card}>
              {!swipe ? (
                <BlockReveal color={color} delay={delay}>
                  <div className={css.card__imagewrapper}>{renderImage()}</div>
                </BlockReveal>
              ) : null}
              <div className={`${css.card__title} smteal__txt mt-2`}>{articleType}</div>
              <div className={css.card__content}>
                <RightTransitionLink to={url} className={`${css.card__link} gradient-keyline-link`}>
                  {hasNumbering && <span className={css.card__index}>{`${cardNumber} /`}</span>}
                  <CardText height={textHeight} isHovered={isHovered} text={text} title={title} />
                </RightTransitionLink>
              </div>
            </div>
            {author && (
              <ArticleInfo
                author={author}
                date={published}
                className={css.card__article__info}
                groupSize={groupSize}
              />
            )}
          </Fragment>
        )}
      </article>
    )
  }

export default Card
