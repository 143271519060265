import React, { Fragment } from 'react'
import css from './LogoBoard.module.css'

import { Heading } from '..'

const LogoBoard = ({ data }) => {
  const logos = data.logos.map(({ logo }, i) => {
    const image = logo.localFile

    return <div key={i} className={css.logo} style={{ backgroundImage: `url('${logo.url}')` }} />
  })
  return (
    <Fragment>
      <Heading text={data.heading[0].text} />
      <div className={css.board}>{logos}</div>
    </Fragment>
  )
}

export default LogoBoard
