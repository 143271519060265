import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import get from 'lodash/get'
import {
  ContentSection,
  HomeIntro,
  Video,
  WrapperWithMargin,
  CaseStudyListBlock,
  ArticleSlider,
  MailingListSignup,
  Slideshow,
} from '@components'
import { getSeoProps } from '@data/seo'
import { SEO } from '..'
import ContentLoader from '../../components/ContentLoader/ContentLoader'
import TwoCol from '../../components/Copy/Two-col/Two-col'

const getVideoProps = data => ({
  url: get(data, 'videoUrl.url'),
  overlayTextList: data.videoOverlayTextList,
})

const getSlideshowProps = data => ({
  slides: get(data, 'slideshow_slides'),
})

const getHomeIntroProps = data => {
  const services = get(data, 'intro_service', [])
  const serviceLinks = services.map(x => ({
    id: uuidv4(),
    text: get(x, 'link_text[0].text'),
    url: `/services/service/${get(x, 'service._meta.uid')}`,
  }))
  return {
    id: uuidv4(),
    title: data.intro_heading,
    serviceLinks,
  }
}

const Home = ({ data: { prismic } }) => {
  const data = prismic.allHome_pages.edges[0].node

  const articleList = data.slider_articles.map(x => x.article)
  const caseStudyList = get(data, 'body[0]fields', [])
  const videoProps = getVideoProps(data)
  const seoProps = getSeoProps(data)
  const homeIntroProps = getHomeIntroProps(data)
  const mailingListProps = get(data, 'mailinglist')
  const slideshowProps = getSlideshowProps(data)

  const sectorListTitle = data.sector_list_title[0].text
  const sectorList = data.sector_list.map(x => x.sector)
  let mutSector = []
  if (!Object.values(sectorList).every(x => (x === null || x === ''))) {
    mutSector = sectorList.map(x => Object.assign({}, ...x, { title: x.name }))
  }

  const sectorConfig = {
    size: 3,
    useLogo: false,
    baseUrl: '/expertise',
    numbered: true
  }

  return (
    <>
      <ContentSection noMarginTop>
        <SEO {...seoProps} />
        <HomeIntro {...homeIntroProps} />
        <Video {...videoProps} />
        <Slideshow {...slideshowProps} />
        <TwoCol leftcol={data.text_column_one} rightcol={data.text_column_two} />
      </ContentSection>
      <ArticleSlider title={sectorListTitle} articles={mutSector} config={sectorConfig} />
      <ContentLoader content={data.content} />
      <WrapperWithMargin marginValue="11rem 0">
        <CaseStudyListBlock caseStudyList={caseStudyList} title="Great Work" />
      </WrapperWithMargin>
      <ArticleSlider title="Great Thinking" articles={articleList} />
      <MailingListSignup {...mailingListProps} />
    </>
  )
}

export default Home
