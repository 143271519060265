import React, { Fragment } from 'react'
import { SEO } from '..'
import { Underline, Image, UpTransitionLink } from '../../components'
import css from './PageNotFound.module.css'

const PageNotFound = ({ seo, sharpImage }) => (
  <Fragment>
    <SEO {...seo} />
    <Title />
    <Image {...{ sharpImage }} />
  </Fragment>
)

// this is hard coded because the underline component within
// I have not found a way to map prismic rich text to use this
const Title = () => (
  <h1 className={css.title}>
    <span className={css.regular}>Seems like you're lost?</span>
    <br />
    <UpTransitionLink to="/"><Underline cutIn>Let's get you back home</Underline></UpTransitionLink>
  </h1>
)

export default PageNotFound
