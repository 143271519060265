import React from 'react'
import css from './ImageTextBlock.module.css'
import { RichText } from '@components';

const ImageTextBlock = ({ image, text }) => {
  if (!image || !text) return null
  
  return (
    <section className={css.container}>
      <div>
        <img src={image.url} alt={image.alt || ''} loading="lazy" />
      </div>
      <RichText render={text} />
    </section>
  )
}

export default ImageTextBlock
