import classNames from 'classnames'
import React, { useEffect, useReducer } from 'react'
import { useSwipeable } from 'react-swipeable'
import get from 'lodash/get'
import css from './Slideshow.module.css'

const NEXT = 'next'
const PREV = 'prev'
const STOP_SLIDING = 'stopSliding'

const getOrder = (index, pos, numItems) => {
  return index - pos < 0 ? numItems - Math.abs(index - pos) : index - pos
}

const getInitialState = (numItems) => ({
  pos: numItems - 1,
  sliding: false,
  dir: NEXT
})

const adjustSlidesLength = (slides) => {
  // Duplicate the items to ensure that the infinite scroll animation
  // remains functional even when there are only two items
  if (slides.length < 3) return [...slides, ...slides]
  return slides
}

const Slideshow = ({ slides: originalSlides }) => {
  const slides = adjustSlidesLength(originalSlides)
  const numItems = slides.length

  const [state, dispatch] = useReducer(reducer, getInitialState(numItems))

  const slide = (dir) => {
    dispatch({ type: dir, numItems })
    setTimeout(() => {
      dispatch({ type: STOP_SLIDING })
    }, 50)
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => slide(NEXT),
    onSwipedRight: () => slide(PREV),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  })

  useEffect(() => {
    const timer = setTimeout(() => slide(NEXT), 6000)
    return () => clearTimeout(timer)
  }, [state.pos])

  if (!originalSlides || originalSlides.length < 2) return null

  const containerClasses = classNames(
    css.slideshow__container,
    css[state.dir],
    {[css.sliding]: state.sliding}
  )

  return (
    <section className={css.slideshow} {...handlers}>
      <div className={css.slideshow__wrapper}>
        <div className={containerClasses}>
          {slides.map((item, index) => {
            const imgSrc = get(item, 'imageSharp.childImageSharp.fixed.src')
            const altText = item.image.alt || ''
            return (
              <div
                key={index}
                className={css.slideshow__slide}
                style={{ order: getOrder(index, state.pos, numItems) }}
              >
                <img src={imgSrc} alt={altText} />
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

function reducer(state, action) {
  switch (action.type) {
    case PREV:
      return {
        ...state,
        dir: PREV,
        sliding: true,
        pos: state.pos === 0 ? action.numItems - 1 : state.pos - 1
      }
    case NEXT:
      return {
        ...state,
        dir: NEXT,
        sliding: true,
        pos: state.pos === action.numItems - 1 ? 0 : state.pos + 1
      }
    case STOP_SLIDING:
      return { ...state, sliding: false }
    default:
      return state
  }
}

export default Slideshow