import React from 'react'
import { RichText } from 'prismic-reactjs'
import { LayoutAware } from '../../components';
import css from './DoubleParagraph.module.css';

const DoubleParagraph = ({ title, paragraph_1, paragraph_2 }) => {
    return (
        <section className={`keyline`} >
            <LayoutAware>
                <div className={css.doubleParagraph}>
                    <div className={css.title}>
                        <RichText render={title} />
                    </div>
                    <div className={css.paragraphs}>
                        <RichText render={paragraph_1} />
                        <RichText render={paragraph_2} />
                    </div>
                </div>
            </LayoutAware>
        </section>
    );
}

export default DoubleParagraph
