import React from 'react'
import { Location } from '@reach/router'
import { SmallLabelLink } from '@components'
import css from './SmallLabel.module.css'

const SmallLabel = ({ text, breadcrumbLinks = true }) => {

  if(!text) return null
  return (
    <div className={css.smallLabel}>
      {!breadcrumbLinks && text.join(' / ')}
      {breadcrumbLinks && (
        <Location>
          {({ location }) => {
            return <SmallLabelLink {...{text, location}} />
          }}
        </Location>
      )}
    </div>
  )
}

export default SmallLabel