/**
 * Contains information about the website]
 * Think of this as a kind of global "settings" file
 */
const helpers = require('./helpers')

const { ucFirst, snakeToPascalCase } = helpers

const site = {
  generatedPageTypes: {
    BLOG_ARTICLE: 'blog_article',
    CASE_STUDY: 'case_study',
    GETTING_HERE: 'getting_here',
    JOB_LISTING: 'job_listing',
    SECTOR: 'sector',
    SERVICE: 'service',
    PARTNER: 'partner_page',
  },

  landingPageTypes: {
    BLOG: 'blog_page',
    CASE_STUDY: 'work_page',
    JOB_LISTING: 'careers_page',
    SERVICE: 'services_page',
    HOME: 'home_page',
    CONTACT: 'contact_page',
    NEWSLETTER: 'newsletter_page',
  },

  componentDocTypes: {
    LOGO_BOARD: 'logo-board',
    PERSON: 'person',
    TEXT_IMAGE: 'text-image',
  },

  maxDeviceWidths: {
    EXTRA_SMALL: 320,
    SMALL: 767,
    MEDIUM: 1023,
    LARGE: 1279,
    EXTRA_LARGE: 1599,
    RETINA: 1600,
    FOOTER_BREAK: 559,
  },

  colours: {
    slate: '#0e0e0e',
    cream: '#b8a18a',
    teal: '#0f7177',
    pink: '#ec9abe',
  },
  getPrimaryColorsArray() {
    return [this.colours.pink, this.colours.cream, this.colours.teal]
  },
  /**
   * @param {string} uid Prismic document unique identifier
   * @param {string} type Prismic document type
   */
  getGeneratePageSlugPath(pageType) {
    let path = ''
    if (pageType === this.generatedPageTypes.BLOG_ARTICLE) path = `/blog/articles`
    if (pageType === this.generatedPageTypes.CASE_STUDY) path = `/work/casestudy`
    if (pageType === this.generatedPageTypes.GETTING_HERE) path = `/getting-here`
    if (pageType === this.generatedPageTypes.SECTOR) path = `/expertise`
    // TODO: Delete job listing content in Prismic then this line
    if (pageType === this.generatedPageTypes.JOB_LISTING) path = `/careers/temp`
    if (pageType === this.generatedPageTypes.SERVICE) path = `/services/service`
    if (pageType === this.generatedPageTypes.PARTNER) path = `/partners`
    return path
  },

  getGenerateLandingPages(pageType) {
    let path = ''
    if (pageType === this.landingPageTypes.BLOG) path = `/blog`
    if (pageType === this.landingPageTypes.CASE_STUDY) path = `/work`
    if (pageType === this.landingPageTypes.JOB_LISTING) path = `/careers`
    if (pageType === this.landingPageTypes.SERVICE) path = `/services`
    if (pageType === this.landingPageTypes.CONTACT) path = `/contact`
    if (pageType === this.landingPageTypes.NEWSLETTER) path = `/newsletter`
    if (pageType === this.landingPageTypes.HOME) path = `/`
    return path
  },

  // If you build a custom type in Prismic and get the name wrong it neccessary to work around it here
  // (unless you want to build it from scratch again with a name that fits the `../templates/${templateName}/${templateName}.jsx` pattern )
  // todo: better template mapping
  workAroundPrismicType(type, templateName) {
    let returnTemplate = templateName
    if (type === this.generatedPageTypes.PARTNER) returnTemplate = `Partner`
    return returnTemplate
  },

  getCreatePages() {
    const pages = []
    for (const pageType in this.generatedPageTypes) {
      const type = this.generatedPageTypes[pageType]
      const slugPath = this.getGeneratePageSlugPath(type)
      let templateName = snakeToPascalCase(type)
      templateName = this.workAroundPrismicType(type, templateName)
      pages.push({
        type: ucFirst(type),
        match: `${slugPath}/:uid`,
        path: slugPath,
        component: require.resolve(`../templates/${templateName}/${templateName}.jsx`),
      })
    }

    return pages
  },
}

module.exports = site
