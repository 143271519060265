import React from 'react'
import classnames from 'classnames'
import { Quote } from '@components'
import css from './QuoteOverImage.module.css'

const QuoteOverImage = ({ quote, quotePosition, quoteLink }) => {
  if (!quote || !quote.image) {
    return null
  }

  return (
    <div
      className={classnames(css.quote, { [`${css.quoteTop}`]: quotePosition === 'top' })}
      style={{ backgroundImage: `url(${quote.image.url})` }}
    >
      <div className={css.quote__image}>
        <img src={quote.image.url} alt={quote.image.url} />
      </div>
      <div className={css.quote__text}>
        <Quote {...quote} isSmall />
      </div>
    </div>
  )
}

export default QuoteOverImage
