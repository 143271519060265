import React, { useRef, useState } from 'react'
import CloseSVG from '@images/svg/close.svg'
import RotateSVG from '@images/svg/mobile-rotate.svg'
import SoundOnSVG from '@images/svg/sound_on.svg'
import SoundOffSVG from '@images/svg/sound_off.svg'
import css from './Takeover.module.css'
import SocialLinks from './SocialLinks/SocialLinks'
import AnimatedText from './AnimatedText/AnimatedText'
import Underline from '../Underline/Underline'
import VideoEmbed from '../VideoEmbed/VideoEmbed'

const TakeoverState = {
  IDLE: 'IDLE',
  VIDEO: 'VIDEO',
  LOADING: 'LOADING',
  ERROR: 'ERROR',
}

const isVisibleEl = el => !!el && el.offsetParent === null

/**
 * @summary Takeover component
 */
const Takeover = ({ setViewed, setClosed }) => {
  const [view, setView] = useState(TakeoverState.IDLE)
  const [sound, setSound] = useState(true)
  const firstFocusable = useRef(null)
  const lastFocusableStart = useRef(null)
  const lastFocusableVideo = useRef(null)

  const handleClose = () => {
    setViewed()
    setClosed()
  }

  const handleWorkClick = e => {
    setView(TakeoverState.VIDEO)
    e.preventDefault()
    setViewed()
    firstFocusable.current.focus()
  }

  const handleKeydownFirstFocusable = e => {
    if (e.keyCode === 9 && e.shiftKey) {
      if (isVisibleEl(lastFocusableStart.current)) {
        lastFocusableStart.current.focus()
      } else {
        lastFocusableVideo.current.focus()
      }
      e.preventDefault()
      return false
    }
    return true
  }

  const handleKeydownLastFocusable = e => {
    if (e.keyCode === 9 && !e.shiftKey) {
      firstFocusable.current.focus()
      e.preventDefault()
      return false
    }
    return true
  }

  const isVideo = view === TakeoverState.VIDEO
  const isStart = view === TakeoverState.IDLE

  return (
    <div
      className={css.takeover}
      role="dialog"
      aria-modal
      aria-labelledby="takeover_title"
      aria-describedby="takeover_desc"
    >
      <section className={css.content} aria-live="polite">
        <header className={css.header_default}>
          <div id="takeover_title" className={isVideo ? css.logo_light : css.logo_dark}>
            Great State {isVideo && 'Showreel Video'}
          </div>
          <button
            ref={firstFocusable}
            tabIndex={0}
            className={isVideo ? css.closeButton_light : css.closeButton_dark}
            type="button"
            onClick={handleClose}
            onKeyDown={handleKeydownFirstFocusable}
          >
            <span>Close</span>
            <CloseSVG />
          </button>
        </header>

        {isVideo && (
          <div className={css.full}>
            <div className={css.video_center}>
              <VideoEmbed
                videoId="905118378"
                overrideStyle={css.video_wrapper}
                urlParameters={{
                  background: 1,
                  muted: sound ? 0 : 1,
                }}
                htmlAttributes={{
                  'aria-label': 'GreatState product showreel video',
                  height: '100%',
                  width: '100%',
                }}
              />
              <div role="presentation" className={css.video_aspect}>
                <RotateSVG />
                <span>Rotate device</span>
              </div>
              <button
                ref={lastFocusableVideo}
                type="button"
                className={css.video_sound}
                onClick={() => setSound(!sound)}
                onKeyDown={handleKeydownLastFocusable}
              >
                {sound ? <SoundOffSVG /> : <SoundOnSVG />}
                <span className={css.sr}>Video sound {sound ? 'off' : 'on'}</span>
              </button>
            </div>
          </div>
        )}

        {isStart && (
          <>
            <div id="takeover_desc" className={css.banner}>
              <AnimatedText onClick={handleWorkClick} />
            </div>
            <ul className={css.nav}>
              <li>
                <strong>New Business</strong>
                <a href="mailto:hello@greatstate.co">
                  <Underline text="hello@greatstate.co" color="transparent-to-purple" />
                </a>
              </li>
              <li>
                <SocialLinks />
              </li>
              <li>
                <strong>Work with us</strong>
                <a
                  ref={lastFocusableStart}
                  href="mailto:careers@greatstate.co"
                  onKeyDown={handleKeydownLastFocusable}
                >
                  <Underline text="careers@greatstate.co" color="transparent-to-purple" />
                </a>
              </li>
            </ul>
          </>
        )}
      </section>
    </div>
  )
}

export default Takeover
