import React, { Fragment } from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { removeLeadingSlash } from '@utils/helpers'
import site from '@utils/site'

const SmallLabelLink = ({ text, location }) => {
  const pathArr = removeLeadingSlash(location.pathname).split('/')
  const isExpertise = pathArr[0] === 'expertise';

  return (
    <Fragment>
      {text.map((str, i) => {
        if(i === text.length-1)
          return str
        return (
          <Fragment key={`SmallLabelLink-${i}`}>
            <AniLink
              cover
              duration={1}
              bg={site.colours.slate}
              direction="right"
              to={ isExpertise ? '' : `/${pathArr[i]}` }
            >
              {str}
            </AniLink>
            &nbsp;/&nbsp;
          </Fragment>
          )
      })}
    </Fragment>
  )
}

export default SmallLabelLink