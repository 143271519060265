import React from 'react'
import css from './LoadMore.module.css'

const LoadMore = ({ handleClick, isHidden }) => (
  <button type="button" className={css.loadMore} onClick={e => handleClick(e)}>
    Load more
  </button>
)

export default LoadMore
