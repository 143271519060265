import React, { useState } from 'react'
import emailValidator from 'email-validator'
import FormSuccess from './FormSuccess'
import FormSignup from './FormSignup'

const FormWithPost = props => {
  const { success, showSuccess = false } = props
  const intialStatus = { result: 'initial', msg: '', field: null }
  const successStatus = { result: 'success', msg: 'Thank you for subscribing!', field: null }

  const url = `${process.env.GATSBY_MAILCHIMP_ENDPOINT}`

  const [email, setEmail] = useState('')
  const [terms, setTerms] = useState(false)
  const [status, setStatus] = useState(showSuccess ? successStatus : intialStatus)

  const { result, field } = status
  const error = result === 'error'

  const handleEmailChange = e => {
    setEmail(e.target.value)
    if (error && field === 'email') {
      setStatus(intialStatus)
    }
  }

  const handleTermsChange = e => {
    setTerms(e.target.checked)
    if (e.target.checked && error && field === 'terms') {
      setStatus(intialStatus)
    }
  }

  const handleReset = e => {
    setStatus(intialStatus)
    setEmail('')
  }

  const handleSubmit = e => {
    e.preventDefault()
    const emailValid = emailValidator.validate(email)
    if (terms && emailValid) {
      e.target.submit()
    } else if (emailValid) {
      setStatus({
        result: 'error',
        msg: 'Please agree to the privacy terms',
        field: 'terms',
      })
    } else {
      setStatus({
        result: 'error',
        msg: 'The email you entered is not valid.',
        field: 'email',
      })
    }
  }

  switch (result) {
    case 'success':
      return <FormSuccess handleReset={handleReset} text={success} />

    default:
      return (
        <FormSignup
          handleSubmit={handleSubmit}
          handleEmailChange={handleEmailChange}
          handleTermsChange={handleTermsChange}
          email={email}
          terms={terms}
          status={status}
          action={url}
          {...props}
        />
      )
  }
}

export default FormWithPost
