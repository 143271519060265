import React, { Fragment } from 'react'
import get from 'lodash/get'
import { getSeoProps } from '@data/seo'
import { prismicHTML } from '@utils/helpers'
import { Banner, Cycle, Underline, MailingListSignup, RightTransitionLink } from '@components'
import { SEO } from '@templates'
import useCopyTracking from '@hooks/useCopyTracking'
import css from './Contact.module.css'

const Contact = ({ data: { prismic } }) => {
  const data = prismic.allContact_pages.edges[0].node
  const seoProps = getSeoProps(data)

  const [helloEmailRef] = useCopyTracking()
  const [careersEmailRef] = useCopyTracking()

  const mailingListProps = get(data, 'mailinglist')

  const bristolOfficeDetails = data.office_one;

  return (
    <Fragment>
      <SEO {...seoProps} />
      <h1 className={css.title}>Contact</h1>
      <div className={css.offices}>
        <div className={css.office}>
          {bristolOfficeDetails.images && (
            <div className={css.images}>
              <Cycle duration={10000}>
                {bristolOfficeDetails.images.map((image, n) => (
                  <img
                    src={get(image, 'imageSharp.childImageSharp.fluid.src', image.image.url)}
                    alt={image.image.alt || ''}
                    key={n}
                  />
                ))}
              </Cycle>
            </div>
          )}
          <div>
            <h2>{bristolOfficeDetails.office_name[0].text}</h2>
            <div
              className={css.officeAddress}
              dangerouslySetInnerHTML={{ __html: prismicHTML(bristolOfficeDetails.address) }}
            />
            <p>
              <a href={`mailto:${bristolOfficeDetails.email[0].text}`}>
                {bristolOfficeDetails.email[0].text}
              </a>
            </p>
            <p>
              <a href={`tel:${bristolOfficeDetails.telephone_number[0].text}`}>
                {bristolOfficeDetails.telephone_number_display[0].text}
              </a>
            </p>
            <div className={css.officeLink}>
              <a
                href={bristolOfficeDetails.google_map_link.url}
                target="_blank"
                rel="noopener noreferrer"
                title={`View Great State ${bristolOfficeDetails.office_name[0].text} Office on Google Maps`}
              >
                <Underline text="Google map" color="pink" />
              </a>
            </div>

            {bristolOfficeDetails.directions_link && 
              <div className={css.officeLink}>
                <RightTransitionLink
                  title={`Getting to Great State ${bristolOfficeDetails.office_name[0].text} Office`}
                  to={`/getting-here/${bristolOfficeDetails.directions_link.meta.uid}`}
                >
                  <Underline text="Getting here" color="pink" />
                </RightTransitionLink>
              </div>
            }
          </div>
        </div>
      </div>

      <MailingListSignup {...mailingListProps} />

      <Banner title="Interested in working with us?">
        <p>
          If you&apos;re a brand with relevance at the top of your agenda and want to know more
          about our services, please email{' '}
          <a href="mailto:hello@greatstate.co" ref={helloEmailRef}>
            {' '}
            <Underline text="hello@greatstate.co" color="pink" />
          </a>
        </p>

        <p>
          If you&apos;re excited about helping organisations with their biggest challenges and would
          like to join our growing team, please email{' '}
          <a href="mailto:careers@greatstate.co" ref={careersEmailRef}>
            <Underline text="careers@greatstate.co" color="pink" />
          </a>
        </p>
      </Banner>
    </Fragment>
  )
}

export default Contact
