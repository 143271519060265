import React, { useState, useEffect } from 'react'
import css from './FloatingLabelInput.module.css'

const FloatingLabelInput = props => {
  const { id, label, onFocus, onBlur, onChange, className = '', value, ...other } = props
  const [active, setActive] = useState(value.length > 0)

  useEffect(() => {
    setActive(value.length !== 0)
  }, [value])

  const handleOnFocus = e => {
    setActive(true)
    if (onFocus) {
      onFocus(e)
    }
  }

  const handleOnBlur = e => {
    setActive(e.target.value.length !== 0)
    if (onBlur) {
      onBlur(e)
    }
  }

  return (
    <div className={`${css.floatingLabelInput} ${className} ${active ? css.isActive : ''}`}>
      <label htmlFor={id} className={css.floatingLabelInput__label}>
        {label}
      </label>
      <input
        id={id}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        onChange={onChange}
        className={css.floatingLabelInput__input}
        value={value}
        {...other}
      />
    </div>
  )
}

export default FloatingLabelInput
