import React, { useState, useEffect, Fragment } from 'react'
import ReactDOM from 'react-dom'
import { Link } from 'gatsby'
import classnames from 'classnames'
import css from './StickyLink.module.css'
import { BackLink, SocialShareLinkList } from '@components'

export default class StickyLink extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      visible: true,
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, { passive: true })
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    // arbitrary value chosen so that sticky links
    // don't get too close to bottom of container
    const bottomPadding = 260

    const isStickyLinkInsideContainer =
      document.getElementById('stickyLink').getBoundingClientRect().bottom + bottomPadding <
      // @ts-ignore
      // eslint-disable-next-line react/no-find-dom-node
      ReactDOM.findDOMNode(this).getBoundingClientRect().bottom

    this.setState({
      visible: isStickyLinkInsideContainer,
    })
  }

  render() {
    const { visible } = this.state
    const { text, link } = this.props;

    return (
      <div onScroll={this.handleScroll} className={css.stickycontainer}>
        <div
          id="stickyLink"
          // eslint-disable-next-line no-return-assign
          ref={node => (this.node = node)}
          className={classnames(css.stickylinks, {
            [`${css.isHidden}`]: !visible,
          })}
        >
          <BackLink to={link} text={text} />

          <div className={css.stickylinks__sociallinks}>
            <SocialShareLinkList />
          </div>
        </div>
      </div>
    )
  }
}
