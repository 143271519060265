import React from 'react'
import css from './SocialIconLink.module.css'

/**
 * @param {object} props Object containg props passed down to component
 * @param {string} props.platform The social media platform you want to link to; accepted values: ['twitter', 'instagram', 'linkedin']
 */
const SocialIconLink = ({ platform }) => {
  const social = {
    twitter: {
      name: 'Twitter',
      url: 'http://twitter.com/@WeAreGreatState',
    },
    instagram: {
      name: 'Instagram',
      url: 'https://www.instagram.com/wearegreatstate/',
    },
    linkedin: {
      name: 'LinkedIn',
      url: 'https://www.linkedin.com/company/greatstate/',
    },
  }

  const data = social[platform]
  return (
    <a href={data.url} className={css.socialIconLink} data-platform={data.name.toLowerCase()}>
      {data.name}
    </a>
  )
}

export default SocialIconLink
