import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSwipeable } from 'react-swipeable'
import get from 'lodash/get'
import { RichText } from 'prismic-reactjs'
import { Controls, Image } from '@components'
import linkResolver from '@utils/linkResolver'
import css from './Benefits.module.css'

/**
 * @summary Benefits component
 * @property {string} title
 * @property {object} benefits
 * @property {string} benefits.title
 * @property {string} benefits.image
 * @property {string} benefits.image.url
 * @property {object} benefits.text
 *
 */

const Benefits = ({ benefits, title }) => {
  const [position, setPosition] = useState(0)
  const limit = benefits.length
  const prev = () => {
    if (position === 0) return
    setPosition(position - 1)
  }
  const next = () => {
    if (position - 1 === limit) return
    setPosition(position + 1)
  }
  const handlers = useSwipeable({
    onSwipedLeft: () => next(),
    onSwipedRight: () => prev(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  })
  const formatNumber = number => {
    return number.toString().length === 1 ? `0${number}` : number
  }
  return (
    <section className={`keyline ${css.benefits}`}>
      <h2>{title}</h2>
      <Controls position={position} limit={limit} prev={prev} next={next} showPages />
      <div
        {...handlers}
        className={css.benefits__wrapper}
        style={{ transform: `translateX(-${position * 100}%)` }}
      >
        {benefits.map((benefit, i) => {
          const singleImage = get(benefit, 'image')
          const imgSrc = get(benefit, 'image.url', singleImage)
          const gatsbyImg = get(benefit, 'imageSharp.childImageSharp.fixed', singleImage)
          const text = get(benefit, 'text', '')

          return (
            <div
              className={css.benefit}
              key={i}
              data-current={i === position}
              style={{ backgroundImage: `url(${imgSrc})` }}
            >
              <div className={css.benefit__image}>
                <Image fixedsharpImage={gatsbyImg} image={singleImage} />
              </div>
              <div className={css.benefit__text}>
                <div className={css.benefit__index}>{`${formatNumber(i + 1)}/`}</div>
                <h3 className={css.benefit__title}>{benefit.title}</h3>
                <RichText render={text} linkResolver={linkResolver} className={css.benefit__text} />
              </div>
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default Benefits

const benefit = PropTypes.shape({
  title: PropTypes.string,
  text: PropTypes.array,
  image: PropTypes.shape({
    url: PropTypes.string,
  }),
})

Benefits.propTypes = {
  benefits: PropTypes.arrayOf(benefit).isRequired,
  title: PropTypes.string.isRequired,
}
