import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSwipeable } from 'react-swipeable'
import { useInView } from 'react-intersection-observer'
import get from 'lodash/get'
import { Controls, Quote } from '@components'
import css from './Quotes.module.css'

/**
 * @summary Quotes component
 * @property {string} title
 * @property {object} quotes
 * @property {string} quotes.image
 * @property {string} quotes.image.url
 * @property {string} quotes.text
 *
 */

const Quotes = ({ quotes, title }) => {
  const [position, setPosition] = useState(0)
  const limit = quotes.length
  const prev = () => {
    if (position === 0) return
    setPosition(position - 1)
  }
  const next = () => {
    if (position - 1 === limit) return
    setPosition(position + 1)
  }
  const handlers = useSwipeable({
    onSwipedLeft: () => next(),
    onSwipedRight: () => prev(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  })

  const [ref, inView, entry] = useInView({
    threshold: 1,
    triggerOnce: true,
  })
  return (
    <section className={`${title ? 'keyline' : ''} ${css.quotes}`}>
      {title && <h2>{title}</h2>}
      {quotes.length > 1 && (
        <Controls position={position} limit={limit} prev={prev} next={next} showPages />
      )}
      <div
        {...handlers}
        className={css.quotes__wrapper}
        style={{ transform: `translateX(-${position * 100}%)` }}
        ref={ref}
      >
        {inView &&
          quotes.map((quote, i) => {
            const imgSrc = get(quote, 'imageSharp.childImageSharp.fixed.src', quote.image.url)

            const text = get(quote, 'text', '')
            const author = get(quote, 'author', '')
            return (
              <div
                className={css.quote}
                key={i}
                data-current={i === position}
                style={{ backgroundImage: `url(${imgSrc})` }}
              >
                <div className={css.quote__image}>
                  <img src={imgSrc} alt="" />
                </div>
                <div className={css.quote__text}>
                  <Quote text={text} author={author} isSmall />
                </div>
              </div>
            )
          })}
      </div>
    </section>
  )
}

export default Quotes

const quote = PropTypes.shape({
  title: PropTypes.string,
  text: PropTypes.array,
  image: PropTypes.shape({
    url: PropTypes.string,
  }),
})

Quotes.propTypes = {
  quotes: PropTypes.arrayOf(quote).isRequired,
  title: PropTypes.string,
}
