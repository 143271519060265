/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { isBrowser } from '@utils/helpers'
import { StaticQuery, graphql } from 'gatsby'

const SEO = ({
  canonical,
  description,
  lang,
  meta,
  keywords,
  title,
  data,
  imageSrc,
  published,
  articleUrl,
}) => {
  const metaDescription = description || data.site.siteMetadata.description
  const metaImage = imageSrc || data.site.siteMetadata.metaImage
  const metaSiteUrl = data.site.siteMetadata.siteUrl
  const metaSiteTitle = data.site.siteMetadata.title
  const { buildTime } = data.site

  const browserCheck = isBrowser()

  const canonicalUrl = articleUrl ? `${metaSiteUrl}${articleUrl}` : metaSiteUrl

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${data.site.siteMetadata.title}`}
      link={canonical ? [{ rel: 'canonical', key: canonicalUrl, href: canonicalUrl }] : []}
      meta={[
        {
          property: `fb:app_id`,
          content: `184880859001361`,
        },

        {
          name: `google-site-verification`,
          content: process.env.GOOGLE_VERIFY_ID,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:url`,
          content: canonicalUrl,
        },
        {
          property: `og:site_name`,
          content: metaSiteTitle,
        },
        {
          property: `article:published_time`,
          content: published || buildTime,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: `${metaSiteUrl}${metaImage}`,
        },

        {
          property: `og:type`,
          content: `article`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:site`,
          content: `https://www.greatstate.co/`,
        },
        {
          name: `twitter:creator`,
          content: `@WeAreGreatState`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: `${metaSiteUrl}${metaImage}`,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : [],
        )
        .concat(meta)}
    />
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          buildTime
          siteMetadata {
            title
            description
            metaImage
            author
            lang
            siteUrl
          }
        }
      }
    `}
    render={data => <SEO data={data} {...props} />}
  />
)

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  canonical: 'https://www.greatstate.co/',
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  canonical: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}
