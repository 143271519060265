import React from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { Location } from '@reach/router'
import { withPrefix } from 'gatsby'
import css from './SubMenu.module.css'

const SubMenu = ({ items }) => {
  if (!items) {
    return null
  }

  const handleChange = () => {
    if (typeof window !== "undefined") {
      const currentUrl = window.location.pathname.split("/").pop()
      const subMenu = document.querySelectorAll('[data-component="SubMenu"]');
      if(subMenu[0]) {
        switch(currentUrl) {
          case "higher-education":
            subMenu[0].scrollLeft= 0;
            break;
          case "government-and-defence":
            subMenu[0].scrollLeft= 250;
            break;
          case "automotive":
            subMenu[0].scrollLeft= 450;
            break;
          default:
            subMenu[0].scrollLeft= 0;
        }
      }

    }
      
  }

  const SubMenuOption = ({ text, url, currentUrl }) => (
    <div className={css.subMenuOption}>
      {/* todo: do we need a 'cover' transition here? cover is erroring here */}
      <AniLink activeClassName={css.subMenuOptionActive} to={url}>
        {text}
      </AniLink>
    </div>
  )

  return (
    <Location>
      {({ location }) => (
        <div className={css.subMenu} 
             data-component="SubMenu"
             role="tablist"
             tabIndex={0}
             onClick={handleChange()} 
             onKeyDown={handleChange()}>
          {items.map((subMenuItem, i) => {
            return (
              <SubMenuOption
                key={`submenu--${subMenuItem.name}`}
                text={subMenuItem.name}
                url={withPrefix(subMenuItem.link)}
                currentUrl={location.pathname}
              />
            )
          })}
        </div>
      )}
    </Location>
  )
}

export default SubMenu
